import Society from "./configuration/society";
import Country from "./configuration/country";
import System from "./configuration/System";
import Login from "./login/Login";
import Dashboard from "./home/Dashboard";
import Error404 from "./error/error404";
import TypeConcept from "./configuration/TypeConcept";
import Transaction from "./security/transaction";
import UserRole from "./security/UserRole";
import LeafTransaction from "./security/userTransaction/LeafTransaction";
import UserSociety from "./security/UserSociety";
// eslint-disable-next-line import/no-anonymous-default-export
export default {
    Country,
    Society,
    Login,
    Dashboard,
    Error404,
    TypeConcept,
    Transaction,
    UserRole,
    LeafTransaction,
    UserSociety,
    System
}