//#region IMPORTS
import React, { Fragment, useState, useEffect, useRef } from "react";
import { ToastProvider, useToasts } from "react-toast-notifications";
import { Container, Row, Col } from "react-bootstrap";
import { FormGroup, ButtonGroup, Alert, Form } from "react-bootstrap";
import Card from "components/Card/Card.jsx";
import LabelTitle from "../../components/Label/labelTitle";
import Button from "../../components/CustomButton/CustomButton";
import language from "../../config/es";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import DataTable from "react-data-table-component";
//redux
import { findAll, saveSystem, deleteSystem } from "../../redux/system.duck";
import { useDispatch, useSelector } from "react-redux";
import {  es_vacio } from "../../config/validate";
//Session user
import SessionConfig from "../../config/session_config";
//#endregion IMPORTS

const System = () => {
  //#region VARIABLES
  const ref = useRef();
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const [selectedRows, setSelectedRows] = useState([]);
  const [data, setData] = useState({
    id: 0,
    systems: [],
    username: "admin",
    society: "BO81",
    name: { value: "", error: "" },
    description: { value: "", error: "" },
    address: { value: "", error: "" },
    code: { value: "", error: "" },
    email: { value: "", error: "" },
    errors: [],
    columns: [],
    pendingData: false,
    enable: true,
    showDelete: false,
    disableUpdate: false,
    auth: JSON.parse(sessionStorage.getItem("user")),
  });
  data.systems = useSelector((store) => store.systemReducer.systems.data);
  //#region LIFECYCLE

  useEffect(() => {
    data.pendingData = true;
    initTable();
    initData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    ref.current.onRefreshCurrentSession();
  }, [data]);
  const initData = async () => {
    await loadSystems();
  };
  const loadSystems = async () => {
    updateState(true, "pendingData");
    try {
      let res = await dispatch(findAll());
      if (res.status === 200) {
        data.systems = res.data;
        data.pendingData = false;
      } else {
        switch (res.response.status) {
          case 403:
            viewNotification(res.response.data.error, "error");
            data.pendingData = false;
            data.systems = [];
            data.enable = false;
            break;
          default:
            viewNotification(language.messages.msmErrorExist, "error");
            data.pendingData = false;
            data.systems = [];
            break;
        }
      }
    } catch (err) {
      viewNotification(language.messages.msmErrorExist, "error");
      data.pendingData = false;
      data.cEntities = [];
    }
    updateState(false, "pendingData");
  };
  const initTable = () => {
    data.columns = [
      {
        name: "",
        cell: (row) => (
          <button
            className="btn-fill btn btn-xs  btn-primary"
            type="button"
            onClick={() => {
              handleButtonEdit(row);
            }}
            id={row.id}
          >
            <i className="fa fa-pencil"></i>
          </button>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
        width: "30px",
      },
      {
        name: language.messages.system.code,
        sortable: true,
        wrap: true,
        right: true,
        width: "60px",
        cell: (row) => (
          <div className="small text-uppercase text-primary">{row.code}</div>
        ),
      },
      {
        name: language.messages.system.name,
        sortable: true,
        wrap: true,
        width: "220px",
        cell: (row) => (
          <span>
            <div className="small text-uppercase">{row.name}</div>
            <div className="small text-uppercase text-primary">{row.address}</div>
          </span>
        ),
      },
      {
        name: language.messages.system.description,
        sortable: true,
        wrap: true,
        width: "300px",
        cell: (row) => (
          <div className="small text-uppercase">{row.description}</div>
        ),
      },
    ];
    updateState(data.columns, "columns");
  };

  //#endregion LIFECYCLE

  //#region HANDLES

  const refreshState = () => {
    setData({
      ...data,
    });
  };
  const updateState = (value, id) => {
    setData({
      ...data,
      [id]: value,
    });
  };
  const handleDelete = () => {
    disableData();
  };
  const handleSelectRow = (state) => {
    setSelectedRows(state.selectedRows);
  };
  const handleButtonEdit = (row) => {
    data.pendingData = false;
    clearError();
    data.id = row.id;
    data.name.value = row.name;
    data.description.value = row.description;
    data.address.value = row.address;
    data.code.value = row.code;
    data.email.value = row.email;
    data.disableUpdate = true;
    refreshState();
  };
  const onChange = (event) => {
    switch (event.target.name) {
      case "name":
        data.name.value = event.target.value;
        data.name.error = "";
        break;
      case "description":
        data.description.value = event.target.value;
        data.description.error = "";
        break;
      case "address":
        data.address.value = event.target.value;
        data.address.error = "";
        break;
      case "code":
        data.code.value = event.target.value;
        data.code.error = "";
        break;
      case "email":
        data.email.value = event.target.value;
        data.email.error = "";
        break;
      default:
        break;
    }
    refreshState();
  };

  //#endregion HANDLES

  //#region FUNCTIONS
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const contextActions = React.useMemo(() => {
    return (
      <Button
        key="delete"
        onClick={handleDelete}
        className="btn btn-danger-up"
        type="button"
        disabled={!data.enable}
      >
        {" "}
        <i className="fa fa-trash"></i>
        {language.messages.btnDisable}
      </Button>
    );
  });
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
  }));
  const LinearIndeterminate = () => {
    const classes = useStyles();
    return (
      <div className={classes.root}>
        <LinearProgress />
      </div>
    );
  };
  const paginationOptions = {
    rowsPerPageText: language.messages.rowsPerPageText,
    rangeSeparatorText: language.messages.rangeSeparatorText,
    selectAllRowsItem: true,
    selectAllRowsItemText: language.messages.selectAllRowsItemText,
  };
  const viewNotification = (msm, action) => {
    addToast(msm, {
      appearance: action,
      autoDismiss: true,
    });
  };
  const clearError = () => {
    data.errors = [];
    data.name.error = "";
    data.description.error = "";
    data.address.error = "";
    data.code.error = "";
    data.email.error = "";
  };
  const clearForm = () => {
    data.id = 0;
    data.name = { value: "", label: "" };
    data.description = { value: "", label: "" };
    data.address = { value: "", label: "" };
    data.code = { value: "", label: "" };
    data.email = { value: "", label: "" };
    data.disableUpdate = false;
  };
  const validate = () => {
    clearError();

    if (es_vacio(data.name.value)) {
      data.errors.push("name.error");
      data.name.error = language.messages.warning_required_field;
    }
    if (es_vacio(data.description.value)) {
      data.errors.push("description.error");
      data.description.error = language.messages.warning_required_field;
    }
    if (es_vacio(data.address.value)) {
      data.errors.push("address.error");
      data.address.error = language.messages.warning_required_field;
    }
    if (es_vacio(data.code.value)) {
      data.errors.push("code.error");
      data.code.error = language.messages.warning_required_field;
    }
    refreshState();
  };
  const cleaningForm = () => {
    clearError();
    clearForm();
    refreshState();
    viewNotification(language.messages.successCleanData, "success");
  };
  const reloadData = () => {
    data.pendingData = true;
    updateState(false, "enable");
    loadSystems();
  };
  const disableData = async () => {
    if (selectedRows.length > 0) {
      data.pendingData = true;
      data.enable = false;
      updateState(false, "showDelete");
      selectedRows.forEach((e) => {
        e.enabled = 0;
      });
      let res = await dispatch(
        deleteSystem(selectedRows, data.auth.id.user.username)
      );
      if (res.status === 200) {
        viewNotification(language.messages.msmDeleteAll, "success");
        data.systems = data.systems.filter((x) => !selectedRows.includes(x));
        data.enable = true;
        data.showDelete = true;
        data.pendingData = false;
        clearForm();
        reloadData();
      } else {
        switch (res.response.status) {
          case 403:
            viewNotification(res.response.data.error, "error");
            data.enable = false;
            break;
          default:
            viewNotification(language.messages.msmErrorExist, "error");
            data.enable = true;
            break;
        }
      }
      data.showDelete = true;
      data.pendingData = false;
      reloadData();
    } else viewNotification(language.messages.msmEmptyDataDisabled, "error");
  };
  const saveData = async () => {
    validate();
    if (data.errors.length === 0) {
      data.pendingData = true;
      updateState(false, "enable");
      let json = {
        id: data.id,
        name: data.name.value,
        description: data.description.value,
        address: data.address.value,
        code: data.code.value,
        email: data.email.value,
        status: 1,
        societyId: data.society,
        userAdmin: data.username,
      };

      let res = await dispatch(saveSystem(json, data.auth.id.user.username));
      if (res.status === 200) {
        switch (res.data) {
          case "OK":
            viewNotification(
              language.messages.msmRegister.replace("{0}", data.name.value),
              "success"
            );
            loadSystems();
            clearError();
            clearForm();
            refreshState();
            break;
          case "EXIST":
            viewNotification(
              language.messages.msmErrorExistSystemName,
              "error"
            );
            data.pendingData = false;
            break;
          default:
            break;
        }
      } else {
        switch (res.response.status) {
          case 500:
            viewNotification(language.messages.msmErrorExist, "error");
            data.pendingData = false;
            break;
          case 403:
            viewNotification(res.response.data.error, "error");
            data.pendingData = false;
            break;
          default:
            viewNotification(language.messages.msmErrorExist, "error");
            data.pendingData = false;
            break;
        }
      }
      updateState(true, "enable");
    } else {
      viewNotification(language.messages.msmErrorForm, "error");
    }
  };

  //#endregion FUNCTIONS

  return (
    <Fragment>
      <SessionConfig ref={ref} />
      <br />
      <Container fluid={true}>
        <Row>
          <Col sm={12} md={5} lg={5} xl={3}>
            <Card
              icono=" fa fa-database"
              title={language.messages.system.titleForm}
              content={
                <form autoComplete="off">
                  <Alert className="alert-primary">
                    <strong className="text-danger small">
                      {language.messages.labelSymbol}
                    </strong>
                    <span className="small">
                      {" "}
                      {language.messages.labelSymbolRequired}
                    </span>
                  </Alert>
                  <FormGroup>
                    <LabelTitle
                      text={language.messages.labelSystemName}
                      requerid={true}
                    />
                    <Form.Control
                      size="sm"
                      type="text"
                      name="name"
                      id="name"
                      value={data.name.value}
                      onChange={onChange}
                    />
                    <span className="small text-danger">{data.name.error}</span>
                  </FormGroup>
                  <FormGroup>
                    <LabelTitle
                      text={language.messages.labelDescription}
                      requerid={true}
                    />
                    <Form.Control
                      size="sm"
                      type="text"
                      name="description"
                      id="description"
                      value={data.description.value}
                      onChange={onChange}
                    />
                    <span className="small text-danger">
                      {data.description.error}
                    </span>
                  </FormGroup>
                  <FormGroup>
                    <LabelTitle
                      text={language.messages.labelAddress}
                      requerid={true}
                    />
                    <Form.Control
                      size="sm"
                      type="text"
                      name="address"
                      id="address"
                      value={data.address.value}
                      onChange={onChange}
                    />
                    <span className="small text-danger">
                      {data.address.error}
                    </span>
                  </FormGroup>
                  <FormGroup>
                    <LabelTitle
                      text={language.messages.labelCode}
                      requerid={true}
                    />
                    <Form.Control
                      disabled={data.disableUpdate === true}
                      maxLength={5}
                      size="sm"
                      type="text"
                      name="code"
                      id="code"
                      value={data.code.value}
                      onChange={onChange}
                    />
                    <span className="small text-danger">{data.code.error}</span>
                  </FormGroup>
                  <FormGroup>
                    <LabelTitle
                      text={language.messages.labelEmail}
                      requerid={false}
                    />
                    <Form.Control
                      size="sm"
                      type="text"
                      name="email"
                      id="email"
                      value={data.email.value}
                      onChange={onChange}
                    />
                    <span className="small text-danger">
                      {data.email.error}
                    </span>
                  </FormGroup>
                  <Row>
                    <Col>
                      <ButtonGroup className="d-flex">
                        <Button
                          disabled={!data.enable}
                          fill
                          font_small
                          type="button"
                          onClick={saveData}
                        >
                          <i className="fa fa-save"></i>{" "}
                          {data.id === 0
                            ? language.messages.btnSave
                            : language.messages.btnEdit}
                        </Button>
                        <Button
                          disabled={!data.enable}
                          info
                          font_small
                          fill
                          type="button"
                          onClick={cleaningForm}
                        >
                          <i className="fa fa-magnet"></i>{" "}
                          {language.messages.btnClear}
                        </Button>
                        <Button
                          disabled={!data.enable}
                          warning
                          font_small
                          fill
                          type="button"
                          onClick={reloadData}
                        >
                          <i className="fa fa-repeat"></i>{" "}
                          {language.messages.btnReload}
                        </Button>
                      </ButtonGroup>
                    </Col>{" "}
                  </Row>
                </form>
              }
            />
          </Col>
          <Col sm={12} md={7} lg={7} xl={9}>
            <Card
              classTitle=" text-uppercase text-capitalize"
              icono="fa fa-list"
              title={language.messages.gridSystemTitle}
              content={
                <div className="fresh-datatables">
                  <DataTable
                    noHeader={selectedRows.length === 0}
                    data={data.systems}
                    columns={data.columns}
                    fixedHeader
                    fixedHeaderScrollHeight="400px"
                    selectableRows
                    dense
                    onSelectedRowsChange={handleSelectRow}
                    pointerOnHover={true}
                    highlightOnHover
                    pagination
                    paginationComponentOptions={paginationOptions}
                    progressPending={data.pendingData}
                    progressComponent={<LinearIndeterminate />}
                    contextActions={contextActions}
                    persistTableHead
                    clearSelectedRows={data.showDelete}
                  />
                </div>
              }
            />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
const App = () => (
  <ToastProvider>
    <System />
  </ToastProvider>
);
export default App;
