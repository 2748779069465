//#region IMPORTS
import service from '../api/system.service';
import language from '../config/es';
//#endregion IMPORTS

//#region CONSTANTS

const initialData = {
    systems: [],
    systemAdd:null,
    noPermitted: ''
}
//#endregion CONSTANTS

//#region TYPES

const LOAD_SYSTEMS = 'LOAD_SYSTEMS';
const LIST_SYSTEM_ERROR = 'LIST_SYSTEM_ERROR';
const ADD_SYSTEM = 'ADD_SYSTEM';
const DELETE_SYSTEM= 'DELETE_SYSTEM';
//#endregion TYPES

//#region REDUCER

export default function systemReducer(state = initialData, action) {
    switch (action.type) {
        case LOAD_SYSTEMS:
            return { ...state, systems: action.payload };
        case ADD_SYSTEM:
            return { ...state, systemAdd: action.payload };
        case LIST_SYSTEM_ERROR:
            return { ...state, noPermitted: action.payload };
        default:
            return { ...state };
    }
}
//#endregion REDUCER

//#region ACTIONS

export const findAll = () => async (dispatch) => {
    const response = await service.getAllSystems().then((r) => { return r });
    dispatch({
        type: LOAD_SYSTEMS,
        payload:response
    });
    return response;
}
//control=[disabled is 0][enabled is 1]
export const findAllSystem = () => async (dispatch) => {
    const res = await service.getAllSystems().then((r) => { return r});
    let data = [];
    if (res != null) {
        if (res.status === 200) {
            res.data.forEach((element) => {
                data.push({
                    value: element.id,
                    label:'['+element.address?.toUpperCase()+'] '+ element.name.toUpperCase()+' | '+element.code,
                    code:element.code,
                    provider:element.address?.toLowerCase()
                });
            });
        } else {
            switch (res.response.status) {
                case 403:
                    dispatch({
                        type: LIST_SYSTEM_ERROR,
                        payload: res.response.data
                    });
                    break;
                default:
                    dispatch({
                        type: LIST_SYSTEM_ERROR,
                        payload: language.messages.msmErrorExist
                    });
                    break;
                }
        }
    }
    dispatch({
        type: LOAD_SYSTEMS,
        payload: data
    });
}
export const saveSystem=(systemData, username)=>async(dispatch)=>{
    const response = await service.saveSystem(systemData, username).then((r) => { return r });
    dispatch({
        type: ADD_SYSTEM,
        payload:response
    });
    return response;
}

export const deleteSystem=(lsSystems, username)=>async(dispatch)=>{
    const response = await service.disableSystem(lsSystems, username).then((r) => { return r });

    dispatch({
        type: DELETE_SYSTEM,
        payload:response
    });
    return response;
}
//#endregion ACTIONS
