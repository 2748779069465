import config from "../config/application";
import { getService, postBodyService } from "./base.service";
import permission from "./permissions.service";
import dictionary from "../config/dictionary";

const getAll = 'find_all';
const getAllRoles = 'find_all_roles';
const getAllRolesBySystem = 'all_roles_by_system';
const create = 'create';
const readById = 'read';
const findByUserUrl = 'searchUser';
const deleteById = 'delete';
const updateUserRol = 'update';
const findByRol = 'find_by_rol';
const findPaginate = 'find_by_filter';
const countPaginate = 'count_by_filter';


class UserRoleService {
    async getAllUserRolesByFilter(username, filter) {
        let valid = await permission.validPermission(
            process.env.REACT_APP_TRANSACTION_USER_ROLE,
            username,
            process.env.REACT_APP_SYSTEM_CODE, dictionary.configApp.action.list);

        if (valid.response === undefined && valid.data.allow === true) {
            const url = `${config.data.security.userRole}${findPaginate}`;
            return postBodyService(url, filter);
        } else {
            return valid;
        }
    }
    getCountByFilter(filter) {
        const url = `${config.data.security.userRole}${countPaginate}`;
        return postBodyService(url, filter);
    }
    async findDataByRol(username, role) {
        let valid = await permission.validPermission(
            process.env.REACT_APP_TRANSACTION_USER_ROLE,
            username,
            process.env.REACT_APP_SYSTEM_CODE, dictionary.configApp.action.list);
        if (valid.response === undefined && valid.data.allow === true) {
            const url = `${config.data.security.userRole}${findByRol}/${role}`;
            return getService(url);
        } else {
            return valid;
        }
    }
    async getAllUserRoles(username) {
        let valid = await permission.validPermission(
            process.env.REACT_APP_TRANSACTION_USER_ROLE,
            username,
            process.env.REACT_APP_SYSTEM_CODE, dictionary.configApp.action.list);
        if (valid.response === undefined && valid.data.allow === true) {
            const url = `${config.data.security.userRole}${getAll}`;
            return getService(url);
        } else {
            return valid;
        }
    }
    async getAllRoles(username) {
        let valid = await permission.validPermission(
            process.env.REACT_APP_TRANSACTION_USER_ROLE,
            username,
            process.env.REACT_APP_SYSTEM_CODE, dictionary.configApp.action.list);
        if (valid.response === undefined && valid.data.allow === true) {
            const url = `${config.data.security.userRole}${getAllRoles}`;
            return getService(url);
        } else {
            return valid;
        }
    }
    async getAllRolesBySystem(id, username) {
        let valid = await permission.validPermission(
            process.env.REACT_APP_TRANSACTION_USER_ROLE,
            username,
            process.env.REACT_APP_SYSTEM_CODE, dictionary.configApp.action.list);
        if (valid.response === undefined && valid.data.allow === true) {
            const url = `${config.data.security.userRole}${getAllRolesBySystem}/${id}`;
            return getService(url);
        } else {
            return valid;
        }
    }
    async saveUserRole(user_role, jsonBody, username) {
        let valid = await permission.validPermission(
            process.env.REACT_APP_TRANSACTION_USER_ROLE,
            username,
            process.env.REACT_APP_SYSTEM_CODE, dictionary.configApp.action.add);
        if (valid.response === undefined && valid.data.allow === true) {
            let url = `${config.data.security.userRole}${create}/${user_role}`;
            return postBodyService(url, jsonBody);
        } else {
            return valid;
        }
    }
    async updateUserRole(json, is_new, username) {
        let valid = await permission.validPermission(
            process.env.REACT_APP_TRANSACTION_USER_ROLE,
            username,
            process.env.REACT_APP_SYSTEM_CODE, dictionary.configApp.action.edit);
        if (valid.response === undefined && valid.data.allow === true) {
            const url = `${config.data.security.userRole}${updateUserRol}/${is_new}`;
            return postBodyService(url, json);
        } else {
            return valid;
        }
    }
    readUserRoleById(id) {
        const url = `${config.data.security.userRole}${readById}?id=${id}`;
        return getService(url);
    }
    async disableUserRole(lsUserRole, username) {
        let valid = await permission.validPermission(
            process.env.REACT_APP_TRANSACTION_USER_ROLE,
            username,
            process.env.REACT_APP_SYSTEM_CODE, dictionary.configApp.action.delete);
        if (valid.response === undefined && valid.data.allow === true) {
            const url = `${config.data.security.userRole}${deleteById}`;
            return postBodyService(url, lsUserRole);
        } else {
            return valid;
        }
    }
    findByUser(user) {
        const url = `${config.data.security.userRole}${findByUserUrl}`;
        return postBodyService(url, user);
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new UserRoleService();