import React, { Fragment, useState, useEffect, useRef } from 'react';
import { ToastProvider, useToasts } from 'react-toast-notifications';
import { Container, Row, Col } from 'react-bootstrap';
import language from '../../config/es';
import Card from '../../components/Card/Card';
import LabelTitle from '../../components/Label/labelTitle';
import SocietyList from '../../components/Societies/SocietyList';
import Select from 'react-select';
import LabelChargeData from '../../components/Label/labelChargeData';
import { es_vacio } from '../../config/validate';
import LinearIndeterminate from '../../components/LinearIndeterminate/LinearIndeterminate';
import Button from '../../components/CustomButton/CustomButton';
import DataTable from 'react-data-table-component';
import { FormGroup, ButtonGroup, Form } from 'react-bootstrap';
//redux
import { useDispatch, useSelector } from 'react-redux';
import {
  findTransactionByType,
  saveTransaction,
  updateTransaction,
  deleteTransaction,
} from '../../redux/transaction.duck';
import { findAllSystem } from '../../redux/system.duck';
//Session user
import SessionConfig from '../../config/session_config';

const Transaction = () => {
  const ref = useRef();
  const { addToast } = useToasts();
  const dispatch = useDispatch();

  //propiedades ---------------------------------------------------
  const [societies, setsocieties] = useState([]);
  const [selectSociety, setselectSociety] = useState([]);
  const [clearSociety, setClearSociety] = useState(false);
  const [visibleSocieties, setvisibleSocieties] = useState(false);
  const [type, setDataType] = useState({ value: '', label: '', error: '' });
  const [system, setSystem] = useState({
    value: 0,
    label: '',
    error: '',
    code: '',
  });
  const [data, setData] = useState({
    showloadingSystem: false,
    pendingData: false,
    enable: true,
    name: { value: '', error: '' },
    code: { value: '', error: '' },
    route: { value: '', error: '' },
    dataRows: [],
    errors: [],
    columns: [],
    types: [
      { label: 'Rol', value: 0 },
      { label: 'Carpeta', value: 1 },
      { label: 'Transacción', value: 2 },
    ],
    systems: [],
    selectedRows: [],
    id: null,
    showDelete: false,
    auth: JSON.parse(sessionStorage.getItem('user')),
    errorMsm: '',
    response: '',
  });

  data.systems = useSelector((store) => store.systemReducer.systems);
  data.dataRows = useSelector((store) => store.transactionReducer.transactions);
  data.errorMsm = useSelector(
    (store) => store.transactionReducer.errorTransaction
  );
  data.response = useSelector(
    (store) => store.transactionReducer.transactionAdd
  );

  //ciclo de vida ---------------------------------------------------
  useEffect(() => {
    reloadAfterUpdate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.response]);
  useEffect(() => {
    messageError();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.errorMsm]);
  useEffect(() => {
    if (data.dataRows !== undefined) {
      if (data.dataRows.code !== undefined) {
        viewNotification(data.dataRows.message, 'error');
        data.dataRows = [];
      }
    } else {
      viewNotification(language.messages.transaction.errorLoad, 'error');
    }
    updateState(false, 'pendingData');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.dataRows]);
  useEffect(() => {
    initColumns();
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    ref.current.onRefreshCurrentSession();
  }, [data]);
  //hadler ---------------------------------------------------
  //#region handler
  const handleButtonEdit = async (row) => {
    data.id = row.id;
    data.name.value = row.name;
    data.code.value = row.code;
    data.route.value = row.route;

    if (type.value === 0) {
      //recargando componente de sociedades
      await setvisibleSocieties(false);
      await setsocieties(row.societies);
      await setvisibleSocieties(true);
    }

    data.pendingData = false;
    refreshState();
  };

  const updateState = (value, id) => {
    setData({
      ...data,
      [id]: value,
    });
  };
  const refreshState = () => {
    setData({
      ...data,
    });
  };
  const handleSelectRow = (state) => {
    updateState(state.selectedRows, 'selectedRows');
  };
  const onChange = (event) => {
    switch (event.target.name) {
      case 'name':
        data.name.value = event.target.value;
        data.name.error = '';
        break;
      case 'code':
        data.code.value = event.target.value;
        data.code.error = '';
        break;
      case 'route':
        data.route.value = event.target.value;
        data.route.error = '';
        break;
      default:
        break;
    }
    refreshState();
  };
  const handleDelete = () => {
    disableData();
  };
  //#endregion
  //function ---------------------------------------------------
  const messageError = () => {
    if (data.errorMsm !== '') {
      viewNotification(data.errorMsm.error, 'error');
      data.enable = true;
    }
  };
  const reloadAfterUpdate = async () => {
    if (data.response !== '') {
      updateState(true, 'pendingData');
      await reloadData();

      viewNotification(
        data.response.delete !== undefined
          ? language.messages.msmDeleteDataSuccess
          : language.messages.msmRegister.replace('{0}', data.name.value),
        'success'
      );
      clearForm();
      updateState(false, 'pendingData');
    }
  };
  const viewNotification = (msm, action) => {
    addToast(msm, {
      appearance: action,
      autoDismiss: true,
    });
  };
  const initColumns = () => {
    data.columns = [
      {
        name: language.messages.lblFunctions,
        cell: (row) => (
          <button
            className="btn-fill btn btn-xs  btn-primary"
            type="button"
            onClick={() => {
              handleButtonEdit(row);
            }}
            id={row.id}
          >
            <i className="fa fa-pencil"></i>
          </button>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
        width: '60px',
      },
      {
        name: language.messages.lblCode,
        sortable: false,
        wrap: true,
        width: '100px',
        cell: (row) => (
          <span>
            <div className="text-primary text-uppercase ">{row.code}</div>
          </span>
        ),
      },
      {
        name: language.messages.lblName,
        sortable: false,
        wrap: true,
        width: '200px',
        cell: (row) => (
          <span>
            <div className=" text-uppercase small">{row.name}</div>
          </span>
        ),
      },
      {
        name: language.messages.transaction.route,
        sortable: false,
        wrap: true,
        width: '300px',
        cell: (row) => (
          <span>
            <div>{row.route}</div>
          </span>
        ),
      },
    ];
  };
  const updateSelect = async (value, id) => {
    switch (id) {
      case 'system':
        system.error = '';
        system.label = value.label;
        system.value = value.value;
        system.code = value.code;
        setSystem(system);
        await loadTransactions();
        break;
      case 'type':
        type.error = '';
        type.label = value.label;
        type.value = value.value;
        setDataType(type);
        setvisibleSocieties(type.value === 0);
        await loadTransactions();
        break;
      default:
        break;
    }
    refreshState();
  };
  const loadData = async () => {
    await loadSystems();
  };
  const loadSystems = async () => {
    await dispatch(findAllSystem());
    data.showloadingSystem = true;
    refreshState();
  };

  const clearError = async () => {
    data.errors = [];
    system.error = '';
    type.error = '';
    data.route.error = '';
    data.name.error = '';
    data.code.error = '';
  };
  const clearForm = () => {
    data.name = { value: '', error: '' };
    data.code = { value: '', error: '' };
    data.route = { value: '', error: '' };
    data.id = null;
    setClearSociety(true);
    refreshState();
  };
  const validate = () => {
    clearError();

    if (system.value === 0) {
      data.errors.push('system.error');
      system.error = language.messages.error_no_selected;
    }

    if (es_vacio(type.value)) {
      data.errors.push('type.error');
      type.error = language.messages.error_no_selected;
    }

    if (es_vacio(data.name.value)) {
      data.errors.push('name.error');
      data.name.error = language.messages.warning_required_field;
    }

    refreshState();
  };
  const clonate = async () => {
    validate();
    if (data.errors.length === 0) {
      setClearSociety(false);
      updateState(false, 'enable');
      let json = {
        id: null,
        name: data.name.value,
        code: data.code.value,
        route: '',
        status: 1,
        type: type.value,
        system: { id: system.value },
        societyId: '',
        userAdmin: data.auth.id.user.username,
        authUser: data.auth.id.user.userId,
      };

      await dispatch(saveTransaction(json, selectSociety));
    }
  };
  const saveData = async () => {
    validate();
    if (data.errors.length === 0) {
      setClearSociety(false);
      updateState(false, 'enable');
      let json = {
        id: data.id,
        name: data.name.value,
        code: data.code.value,
        route: data.route.value,
        status: 1,
        type: type.value,
        system: { id: system.value },
        societyId: '',
        userAdmin: data.auth.id.user.username,
        authUser: data.auth.id.user.userId,
        societies: selectSociety,
      };

      if (data.id === null) {
        await dispatch(saveTransaction(json, selectSociety));
      } else {
        await dispatch(updateTransaction(json));
      }
    }
  };
  const cleaningForm = async () => {
    await setClearSociety(false);
    clearError();
    clearForm();

    viewNotification(language.messages.success_clean_data, 'success');
  };
  const reloadDataEnable = async () => {
    await loadTransactions();
    refreshState();
  };

  const paginationOptions = {
    rowsPerPageText: language.messages.rowsPerPageText,
    rangeSeparatorText: language.messages.rangeSeparatorText,
    selectAllRowsItem: true,
    selectAllRowsItemText: language.messages.selectAllRowsItemText,
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const contextActions = React.useMemo(() => {
    return (
      <Button
        key="delete"
        onClick={handleDelete}
        className="btn btn-danger-up"
        type="button"
        disabled={!data.enable}
      >
        {' '}
        <i className="fa fa-trash"></i>
        {language.messages.btnDisable}
      </Button>
    );
  });
  const initStateDelete = (value) => {
    data.enable = value;
    data.showDelete = value;
    refreshState();
  };
  const disableData = async () => {
    if (data.selectedRows.length > 0) {
      data.selectedRows.forEach((element) => {
        element.state = 0;
        element.authUser = data.auth.id.user.userId;
      });
      data.pendingData = true;
      initStateDelete(false);

      await dispatch(
        deleteTransaction(data.selectedRows, data.auth.id.user.username)
      );

      initStateDelete(true);
    }
  };
  const reloadData = async () => {
    data.enable = false;
    await loadTransactions();
    viewNotification(language.messages.success_reload_data, 'success');
    data.enable = true;
    refreshState();
  };
  const loadTransactions = async () => {
    updateState(true, 'pendingData');
    if (!es_vacio(type.value) && !es_vacio(system.code)) {
      await dispatch(
        findTransactionByType(
          data.auth.id.user.username,
          type.value,
          system.code
        )
      );
    }
  };
  const selectedSociety = (e) => {
    setselectSociety(e);
  };
  //render ---------------------------------------------------
  return (
    <Fragment>
    <SessionConfig ref={ref}/>
      <br />
      <Container fluid={true}>
        <Row>
          <Col sm={12} md={5} lg={5} xl={4}>
            <Card
              icono=" fa fa-database"
              title={language.messages.transaction.title}
              content={
                <Form autoComplete="off">
                  <Row>
                    <Col md={12}>
                      <Row>
                        <Col md={5}>
                          <LabelTitle
                            text={language.messages.labelSystem}
                            requerid={true}
                          />
                        </Col>

                        <LabelChargeData showloading={data.showloadingSystem} />
                      </Row>
                      <FormGroup>
                        <Select
                          isDisabled={data.id !== null}
                          placeholder={language.messages.lblSingleSelect}
                          name="system"
                          options={data.systems}
                          className="text-uppercase small text-dark"
                          value={{
                            value: system.value,
                            label: system.label,
                          }}
                          onChange={(value) => {
                            updateSelect(value, 'system');
                          }}
                        />
                        <span className="small text-danger">
                          {system.error}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Row>
                        <Col md={12}>
                          <LabelTitle
                            text={language.messages.transaction.concept}
                            requerid={true}
                          />
                        </Col>
                        <LabelChargeData showloading={data.showloadingSystem} />
                      </Row>
                      <Row>
                        <Col
                          md={12}
                          className={data.showloadingSystem ? '' : 'noDisplay'}
                        >
                          <FormGroup>
                            <Select
                              isDisabled={data.id !== null}
                              placeholder={language.messages.lblSingleSelect}
                              name="type"
                              options={data.types}
                              className="text-uppercase small text-dark"
                              value={{
                                value: type.value,
                                label: type.label,
                              }}
                              onChange={(value) => {
                                updateSelect(value, 'type');
                              }}
                            />
                            <span className="small text-danger">
                              {type.error}
                            </span>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={12} sm={12}>
                      <FormGroup>
                        <LabelTitle
                          text={language.messages.lblName}
                          requerid={true}
                        />
                        <Form.Control
                          size="sm"
                          type="text"
                          name="name"
                          id="name"
                          value={data.name.value}
                          onChange={onChange}
                        />
                        <span className="small text-danger">
                          {data.name.error}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <SocietyList
                    clear={clearSociety}
                    visible={visibleSocieties}
                    societies={societies}
                    onSelectSociety={selectedSociety}
                  />
                  <Row>
                    <Col md={12} sm={12}>
                      <FormGroup
                        className={type.value === 0 ? 'noDisplay' : ''}
                      >
                        <LabelTitle
                          text={language.messages.transaction.route}
                          requerid={false}
                        />
                        <Form.Control
                          size="sm"
                          type="text"
                          name="route"
                          id="route"
                          value={data.route.value}
                          onChange={onChange}
                        />
                        <span className="small text-danger">
                          {data.route.error}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <LabelChargeData
                    label={language.messages.msmRegisterDataLoading}
                    showloading={data.enable}
                  />
                  <Row>
                    <Col>
                      <ButtonGroup className="d-flex">
                        <Button
                          disabled={!data.enable}
                          primary
                          fill
                          type="button"
                          font_small
                          onClick={saveData}
                        >
                          <i className="fa fa-save"></i>{' '}
                          {data.id === null
                            ? language.messages.btnSave
                            : language.messages.btnEdit}
                        </Button>
                        <Button
                          disabled={!data.enable}
                          info
                          fill
                          type="button"
                          font_small
                          onClick={cleaningForm}
                        >
                          <i className="fa fa-magnet"></i>{' '}
                          {language.messages.btnClear}
                        </Button>
                        {data.id !== null && type.value === 0 ? (
                          <Button
                            disabled={!data.enable}
                            success
                            fill
                            font_small
                            type="button"
                            onClick={clonate}
                          >
                            <i className="fa fa-save"></i>{' '}
                            {language.messages.transaction.clon}
                          </Button>
                        ) : (
                          <Button
                            disabled={!data.enable}
                            warning
                            fill
                            font_small
                            type="button"
                            onClick={reloadDataEnable}
                          >
                            <i className="fa fa-refresh"></i>{' '}
                            {language.messages.btnReload}
                          </Button>
                        )}
                      </ButtonGroup>
                    </Col>
                  </Row>
                </Form>
              }
            />
          </Col>
          <Col sm={12} md={7} lg={7} xl={8}>
            <Card
              icono=" fa fa-list"
              title={language.messages.transaction.table}
              content={
                <div className="fresh-datatables">
                  <DataTable
                    title={
                      system.label === '' || type.label === ''
                        ? ''
                        : system.label + ' - ' + type.label.toUpperCase()
                    }
                    noHeader={system.label === '' || type.label === ''}
                    data={data.dataRows}
                    columns={data.columns}
                    fixedHeader
                    fixedHeaderScrollHeight="500px"
                    dense
                    selectableRows
                    onSelectedRowsChange={handleSelectRow}
                    highlightOnHover
                    pagination
                    paginationComponentOptions={paginationOptions}
                    progressPending={data.pendingData}
                    progressComponent={<LinearIndeterminate />}
                    persistTableHead
                    contextActions={contextActions}
                    clearSelectedRows={data.showDelete}
                  />
                </div>
              }
            />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
const App = () => (
  <ToastProvider>
    <Transaction />
  </ToastProvider>
);
export default App;
