import { ToastProvider, useToasts } from 'react-toast-notifications';
import React, { Fragment, useEffect, useState, useRef } from 'react';
import {
  Alert,
  ButtonGroup,
  Col,
  Form,
  FormGroup,
  Row,
  FormControl,
  Container,
} from 'react-bootstrap';
import language from '../../config/es';
import LabelTitle from '../../components/Label/labelTitle';
import Button from '../../components/CustomButton/CustomButton';
import Card from '../../components/Card/Card';
import DataTable from 'react-data-table-component';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Radio, RadioGroup } from 'react-radio-group';
import Select from 'react-select';
import {
  findTypeByConcept,
  findAllConcepts,
  createType,
  deleteType,
  updateType,
  createConcept,
} from '../../redux/type.duck';
import { useDispatch, useSelector } from 'react-redux';

import { es_vacio } from '../../config/validate';
import LabelChargeData from '../../components/Label/labelChargeData';
//Session user
import SessionConfig from '../../config/session_config';

const TypeConcept = () => {
  const ref = useRef();
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const [data, setData] = useState({
    id: 0,
    types: [],
    responseCreate: [],
    responseEdit: [],
    rolId: 0,
    idMenuEdit: 0,
    transactionEdit: [],
    responseDelete: [],
    newNameTransaction: '',
    editTransaction: false,
    addTransaction: true,
    editType: true,
    enable: true,
    showloading: false,
    transactionsNotAssigned: [],
    tranNotAssigned: { value: 0, label: '' },
    transactions: [],
    columnsTransaction: [],
    params: [],
    columns: [],
    selectedRows: [],
    showDelete: false,
    pendingData: false,
    nameError: '',
    valueError: '',
    nombreTipo: '',
    codigoTipo: '',
    concept: { value: '', error: '' },
    conceptData: { value: '', error: '' },
    name: { value: '', error: '' },
    code: { value: '', error: '' },
    value: { value: '', error: '' },
    errors: [],
    username: 'admin',
    disableUpdate: false,
    auth: JSON.parse(sessionStorage.getItem('user')),
    error: '',
  });
  data.types = useSelector((store) => store.typeReducer.types);
  data.error = useSelector((store) => store.typeReducer.errorType);
  //ciclo de vida ---------------------------------------------------
  useEffect(() => {
    messageError();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.error]);
  useEffect(() => {
    initTable();
    loadConcepts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    ref.current.onRefreshCurrentSession();
  }, [data]);

  const messageError = () => {
    if (data.error !== '') {
      viewNotification(data.error.error, 'error');
    }
  };
  const loadRolData = async () => {
    updateState(true, 'pendingData');
    await dispatch(findTypeByConcept(data.concept.value));
    data.pendingData = false;
    refreshState();
  };
  const initTable = () => {
    data.columns = [
      {
        name: language.messages.type.name,
        sortable: false,
        wrap: true,
        width: '20%',
        cell: (row) => <div className="small text-uppercase">{row.name}</div>,
      },
      {
        name: language.messages.type.code,
        sortable: false,
        wrap: true,
        width: '15%',
        cell: (row) => (
          <div className="text-primary text-uppercase">{row.code}</div>
        ),
      },
      {
        name: language.messages.type.value,
        sortable: false,
        wrap: true,
        width: '40%',
        cell: (row) => (
          <div className="text-muted small text-uppercase">{row.value}</div>
        ),
      },
      {
        name: language.messages.lblFunctions,
        cell: (row) => (
          <button
            className="btn-fill btn btn-xs  btn-primary"
            type="button"
            onClick={() => {
              handleButtonEdit(row);
            }}
            id={row.Id}
          >
            <i className="fa fa-pencil"></i>
          </button>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
        width: '10px',
      },
    ];
    updateState(data.columns, 'columns');
  };

  const loadConcepts = async () => {
    data.transactionsNotAssigned = await dispatch(
      findAllConcepts(data.auth.id.user.username)
    );
    data.tranNotAssigned = { value: 0, label: '' };
    refreshState();
  };

  const handleButtonEdit = (row) => {
    clearError();
    data.editTransaction = false;
    data.addTransaction = true;
    data.editType = false;
    data.id = row.id;
    data.tranNotAssigned.value = row.concept.id;
    data.tranNotAssigned.label = row.concept.name;
    data.concept.value = row.concept.id;
    data.name.value = row.name;
    data.code.value = row.code;
    data.value.value =
      row.value === undefined || row.value === null ? '' : row.value;
    data.disableUpdate = true;
    refreshState();
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
  }));
  const LinearIndeterminate = () => {
    const classes = useStyles();
    return (
      <div className={classes.root}>
        <LinearProgress />
      </div>
    );
  };

  const handleOnChange = (e) => {
    data.concept = { value: '', label: '' };
    if (e === 'Crear') {
      data.editTransaction = true;
      data.addTransaction = false;
    } else {
      data.editTransaction = false;
      data.addTransaction = true;
    }
    data.types = [];
    data.tranNotAssigned = { value: 0, label: '' };
    updateState(false, 'pendingData');
    handleSelectChange(data.tranNotAssigned);
  };

  const handleSelectChange = (value) => {
    data.tranNotAssigned = value;
    data.concept.value = data.tranNotAssigned.value;
    data.concept.error = '';
    loadRolData();
  };

  const updateState = (value, id) => {
    setData({
      ...data,
      [id]: value,
    });
  };

  const paginationOptions = {
    rowsPerPageText: language.messages.rowsPerPageText,
    rangeSeparatorText: language.messages.rangeSeparatorText,
    selectAllRowsItem: true,
    selectAllRowsItemText: language.messages.selectAllRowsItemText,
  };

  const handleSelectRow = (state) => {
    updateState(state.selectedRows, 'selectedRows');
  };

  const refreshState = () => {
    setData({
      ...data,
    });
  };
  const saveData = () => {
    if (data.editTransaction) {
      saveConcept();
    } else {
      saveDatatype();
    }
  };
  const saveConcept = async () => {
    //conceptData
    validateConcept();
    if (data.errors.length === 0) {
      data.enable = false;
      refreshState();
      let json = {
        name: data.conceptData.value,
        status: 1,
        userAdmin: data.username,
      };
      let response = await dispatch(
        createConcept(json, data.auth.id.user.username)
      );
      if (response.error === undefined) {
        updateState(true, 'pendingData');
        await loadConcepts();
        viewNotification(
          language.messages.msmRegister.replace('{0}', data.conceptData.value),
          'success'
        );
        clear();
      }
      data.pendingData = false;
      data.enable = true;
    } else {
      viewNotification(language.messages.msmErrorForm, 'error');
    }
    refreshState();
  };
  const saveDatatype = async () => {
    validate();
    if (data.errors.length === 0) {
      data.pendingData = true;
      data.enable = false;
      refreshState();

      let json = {
        id: data.id === 0 ? null : data.id,
        concept: { id: data.concept.value },
        name: data.name.value,
        code: data.code.value,
        value: data.value.value,
        status: 1,
        userAdmin: data.username,
      };
      let response = '';

      if (data.id === 0) {
        response = await dispatch(createType(json, data.auth.id.user.username));
      } else {
        response = await dispatch(updateType(json, data.auth.id.user.username));
      }
      if (response.error === undefined) {
        updateState(true, 'pendingData');
        await reloadData();

        viewNotification(
          language.messages.msmRegister.replace('{0}', data.name.value),
          'success'
        );
        clear();
      }

      data.pendingData = false;
      data.enable = true;
    } else {
      viewNotification(language.messages.msmErrorForm, 'error');
    }
    refreshState();
  };
  const validateConcept = () => {
    data.errors = [];
    data.conceptData.error = '';
    if (es_vacio(data.conceptData.value)) {
      data.errors.push('conceptData.error');
      data.conceptData.error = language.messages.warning_required_field;
    }
    refreshState();
  };
  const validate = () => {
    clearError();
    if (es_vacio(data.name.value)) {
      data.errors.push('name.error');
      data.name.error = language.messages.warning_required_field;
    }
    if (es_vacio(data.code.value)) {
      data.errors.push('code.error');
      data.code.error = language.messages.warning_required_field;
    }

    if (es_vacio(data.concept.value) || data.concept.value === 0) {
      data.errors.push('concept.error');
      data.concept.error = language.messages.warning_required_field;
    }

    refreshState();
  };
  const viewNotification = (msm, action) => {
    addToast(msm, {
      appearance: action,
      autoDismiss: true,
    });
  };
  const reloadData = () => {
    data.pendingData = true;
    refreshState();
    loadRolData();
  };

  const clear = () => {
    data.editType = true;
    data.id = 0;
    data.name = { value: '', label: '' };
    data.code = { value: '', label: '' };
    data.value = { value: '', label: '' };
    data.conceptData = { value: '', error: '' };
    data.disableUpdate = false;
    refreshState();
  };

  const clearError = () => {
    data.errors = [];
    data.name.error = '';
    data.code.error = '';
    data.concept.error = '';
    data.conceptData.error = '';
  };
  const onChange = (event) => {
    switch (event.target.name) {
      case 'concept':
        data.conceptData.value = event.target.value;
        data.conceptData.error = '';
        break;
      case 'name':
        data.name.value = event.target.value;
        data.name.error = '';
        break;
      case 'code':
        data.code.value = event.target.value;
        data.code.error = '';
        break;
      case 'value':
        data.value.value = event.target.value;
        data.value.error = '';
        break;
      default:
        break;
    }
    refreshState();
  };

  const handleDelete = () => {
    disableData();
  };
  const disableData = async () => {
    if (data.selectedRows.length > 0) {
      let lsDelete = [];
      data.selectedRows.forEach((element) => {
        let item = { id: 0 };
        item.id = element.id;
        lsDelete.push(item);
      });
      initStateDelete(false);

      let response = await dispatch(
        deleteType(lsDelete, data.auth.id.user.username)
      );
      if (response.code !== undefined) {
        viewNotification(response.message, 'error');
        initStateDelete(true);
      } else {
        viewNotification(language.messages.msmDisableAll, 'success');
        initStateDelete(true);
        reloadData();
      }
    }
  };
  const initStateDelete = (value) => {
    data.enable = value;
    data.showDelete = value;
    data.pendingData = !value;
    refreshState();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const contextActions = React.useMemo(() => {
    return (
      <Button
        key="delete"
        onClick={handleDelete}
        className="btn btn-danger-up"
        type="button"
        disabled={!data.enable}
      >
        {' '}
        <i className="fa fa-trash"></i>
        Dar de Baja
      </Button>
    );
  });

  return (
    <Fragment>
    <SessionConfig ref={ref}/>
      <br />
      <Container fluid={true}>
        <Row>
          <Col md={4}>
            <Card
              icono=" fa fa-database"
              title={language.messages.type.title}
              content={
                <form autoComplete="off">
                  <Alert className="alert-primary">
                    <strong className="text-danger small">
                      {language.messages.labelSymbol}
                    </strong>
                    <span className="small ">
                      {' '}
                      {language.messages.labelSymbolRequired}
                    </span>
                  </Alert>
                  {data.editType && (
                    <FormGroup>
                      <RadioGroup
                        name="fruits"
                        className="small "
                        onChange={(e) => handleOnChange(e)}
                      >
                        <div className="radio-button-background">
                          <Radio value="Crear" className="radio-button ml-1" />{' '}
                          {language.messages.type.createConcept}
                        </div>
                        <div className="radio-button-background">
                          <Radio
                            value="Seleccionar"
                            className="radio-button ml-1"
                            checked={!data.editTransaction}
                          />{' '}
                          {language.messages.type.selectConcept}
                        </div>
                      </RadioGroup>
                    </FormGroup>
                  )}
                  {data.editTransaction && (
                    <FormGroup>
                      <LabelTitle
                        text={language.messages.type.nameConcept}
                        requerid={true}
                      />
                      <FormControl
                        onChange={onChange}
                        type="text"
                        placeholder="Concepto"
                        value={data.conceptData.value}
                        name="concept"
                        id="concept"
                        className="small"
                      />
                      <span className="small text-danger">
                        {data.conceptData.error}
                      </span>
                    </FormGroup>
                  )}
                  {data.addTransaction && (
                    <FormGroup>
                      <Select
                        className="text-uppercase small"
                        isDisabled={data.disableUpdate === true}
                        placeholder={language.messages.lblSingleSelect}
                        name="classType"
                        options={data.transactionsNotAssigned}
                        value={{
                          value: data.tranNotAssigned.value,
                          label: data.tranNotAssigned.label,
                        }}
                        onChange={(value) => {
                          handleSelectChange(value);
                        }}
                      />
                      <span className="small text-danger">
                        {data.concept.error}
                      </span>
                    </FormGroup>
                  )}
                  {data.addTransaction && (
                    <FormGroup>
                      <LabelTitle
                        text={language.messages.labelTypeName}
                        requerid={true}
                      />
                      <Form.Control
                        size="sm"
                        type="text"
                        name="name"
                        id="name"
                        value={data.name.value}
                        onChange={onChange}
                      />
                      <span className="small text-danger">
                        {data.name.error}
                      </span>
                    </FormGroup>
                  )}
                  {data.addTransaction && (
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <LabelTitle
                            text={language.messages.labelCode}
                            requerid={true}
                          />
                          <Form.Control
                            size="sm"
                            type="text"
                            name="code"
                            id="code"
                            value={data.code.value}
                            onChange={onChange}
                          />
                          <span className="small text-danger">
                            {data.code.error}
                          </span>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <LabelTitle
                            text={language.messages.type.value}
                            requerid={false}
                          />
                          <Form.Control
                            size="sm"
                            type="text"
                            name="value"
                            id="value"
                            value={data.value.value}
                            onChange={onChange}
                          />
                          <span className="small text-danger">
                            {data.value.error}
                          </span>
                        </FormGroup>
                      </Col>
                    </Row>
                  )}
                  <LabelChargeData
                    label={language.messages.msmRegisterDataLoading}
                    showloading={data.enable}
                  />
                  <ButtonGroup className="d-flex">
                    <Button
                      disabled={!data.enable}
                      fill
                      font_small
                      type="button"
                      onClick={saveData}
                    >
                      <i className="fa fa-save"></i>{' '}
                      {data.id === 0
                        ? language.messages.btnSave
                        : language.messages.btnEdit}
                    </Button>
                    <Button
                      disabled={!data.enable}
                      info
                      fill
                      font_small
                      type="button"
                      onClick={clear}
                    >
                      <i className="fa fa-magnet"></i>{' '}
                      {language.messages.btnClear}
                    </Button>
                    <Button
                      disabled={!data.enable}
                      warning
                      fill
                      font_small
                      type="button"
                      onClick={reloadData}
                    >
                      <i className="fa fa-repeat"></i>{' '}
                      {language.messages.btnReload}
                    </Button>
                  </ButtonGroup>
                </form>
              }
            />
          </Col>
          <Col md={8}>
            <Card
              classTitle=" text-uppercase text-capitalize"
              icono="fa fa-list"
              title={language.messages.type.titleGrid}
              content={
                <div className="fresh-datatables">
                  <DataTable
                    noHeader={data.tranNotAssigned.value === 0}
                    title={
                      data.types.length > 0
                        ? language.messages.type.concept +
                          ' ' +
                          data.tranNotAssigned.label
                        : ''
                    }
                    data={data.types}
                    columns={data.columns}
                    fixedHeader
                    fixedHeaderScrollHeight="400px"
                    selectableRows
                    dense
                    onSelectedRowsChange={handleSelectRow}
                    pointerOnHover={true}
                    highlightOnHover
                    pagination
                    paginationComponentOptions={paginationOptions}
                    progressPending={data.pendingData}
                    progressComponent={<LinearIndeterminate />}
                    contextActions={contextActions}
                    persistTableHead
                    clearSelectedRows={data.showDelete}
                  />
                </div>
              }
            />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
const App = () => (
  <ToastProvider>
    <TypeConcept />
  </ToastProvider>
);
export default App;
