import {getService,postBodyService} from "./base.service";
import config from "../config/application";
import permission from '../api/permissions.service';
import dictionary from '../config/dictionary';

//url
const list = 'list';
const add = 'add';
const update = 'update';
const deleteUrl = 'delete';
const roleAddUrl = 'roleAdd';
const roleDeleteUrl = 'rolDeleteByMenu';
const findBYTypeUrl = 'find';
const societiesUrl='findSocieties';

class TransactionService {
    findSocietiesByRol(role){
        const url = `${config.data.security.transaction.url}${societiesUrl}/${role}`;
        return getService(url);
    }
    async getTransactionsByType(username,type,system) {
        let valid = await permission.validPermission(
            process.env.REACT_APP_TRANSACTION_TRANSACTION,
            username,
            process.env.REACT_APP_SYSTEM_CODE, dictionary.configApp.action.list);
        if (valid.response === undefined && valid.data.allow === true) {
            const url = `${config.data.security.transaction.url}${findBYTypeUrl}/${system}/${type}`;
            return getService(url);
        } else {
            return valid;
        }
    }
    async getTransactions(username) {
        let valid = await permission.validPermission(
            process.env.REACT_APP_TRANSACTION_TRANSACTION,
            username,
            process.env.REACT_APP_SYSTEM_CODE, dictionary.configApp.action.list);
        if (valid.response === undefined && valid.data.allow === true) {
            const url = `${config.data.security.transaction.url}${list}`;
            return getService(url);
        } else {
            return valid;
        }
    }
    async saveTransaction(transaction){
        let valid = await permission.validPermission(
            process.env.REACT_APP_TRANSACTION_TRANSACTION,
            transaction.userAdmin,
            process.env.REACT_APP_SYSTEM_CODE, dictionary.configApp.action.add);
        if (valid.response === undefined && valid.data.allow === true) {
            const url = `${config.data.security.transaction.url}${add}`;
            return postBodyService(url,transaction);
        } else {
            return valid;
        }
    }
    async updateTransaction(transaction){
        let valid = await permission.validPermission(
            process.env.REACT_APP_TRANSACTION_TRANSACTION,
            transaction.userAdmin,
            process.env.REACT_APP_SYSTEM_CODE, dictionary.configApp.action.edit);
        if (valid.response === undefined && valid.data.allow === true) {
            const url = `${config.data.security.transaction.url}${update}`;
            return postBodyService(url,transaction);
        } else {
            return valid;
        }
    }
    async deleteTransaction(lstransaction,username){
        let valid = await permission.validPermission(
            process.env.REACT_APP_TRANSACTION_TRANSACTION,
            username,
            process.env.REACT_APP_SYSTEM_CODE, dictionary.configApp.action.delete);
            
        if (valid.response === undefined && valid.data.allow === true) {
            const url = `${config.data.security.transaction.url}${deleteUrl}`;
            return postBodyService(url,lstransaction);
        } else {
            return valid;
        }
    }
    saveRole(role){
        const url = `${config.data.security.transaction.url}${roleAddUrl}`;
        return postBodyService(url,role);
    }
    deleteRolByMenu(role){
        const url = `${config.data.security.transaction.url}${roleDeleteUrl}`;
        return postBodyService(url,role);
    }
}
// eslint-disable-next-line import/no-anonymous-default-export
export default new TransactionService(); 