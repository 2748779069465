import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import cx from 'classnames';
import PropTypes from 'prop-types';

class CustomButton extends Component {
  render() {
    const {
      info,
      warning,
      danger,
      primary,
      secundary,
      fill,
      simple,
      pullRight,
      round,
      block,
      success,
      font_small,
      ...rest
    } = this.props;

    const btnClasses = cx({
      'btn-fill': fill,
      'btn-simple': simple,
      'pull-right': pullRight,
      'btn-block': block,
      'btn-round': round,
      'btn-info': info,
      'btn-warning': warning,
      'btn-danger': danger,
      'btn-primary': primary,
      'btn-secundary': secundary,
      'btn-success': success,
      'small-font': font_small
    });

    return <Button data-testid="Button" className={btnClasses} {...rest}  />;
  }
}

CustomButton.propTypes = {
  fill: PropTypes.bool,
  simple: PropTypes.bool,
  pullRight: PropTypes.bool,
  block: PropTypes.bool,
  round: PropTypes.bool,
};

export default CustomButton;