import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import PerfectScrollbar from 'perfect-scrollbar';
import AdminNavbar from 'components/Navbars/AdminNavbar';
import TopMenu from '../components/Menu/TopMenu';
import FooterDash from '../components/Footer/FooterDash';
import dashRoutes from 'routes/dash.jsx';

class Dash extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _notificationSystem: null,
      user: sessionStorage.getItem('username'),
    };
  }
  componentDidMount() {}

  // function that creates perfect scroll bar for windows users (it creates a scrollbar that looks like the one from apple devices)
  isMac() {
    let bool = false;
    if (
      navigator.platform.toUpperCase().indexOf('MAC') >= 0 ||
      navigator.platform.toUpperCase().indexOf('IPAD') >= 0
    ) {
      bool = true;
    }
    return bool;
  }
  componentDidUpdate(e) {
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      setTimeout(() => {
        PerfectScrollbar.update(this.refs.mainPanel);
      }, 350);
    }
    if (e.history.action === 'PUSH') {
      this.refs.mainPanel.scrollTop = 0;
    }
  }
  componentWillMount() {
    const user = sessionStorage.getItem('username');
    if (user === null || user === undefined) {
      window.location.href = '/login';
    } else {
      if (document.documentElement.className.indexOf('nav-open') !== -1) {
        document.documentElement.classList.toggle('nav-open');
      }
    }
  }
  render() {
    return (
      <div className={this.state.user === null ? 'noDisplay' : 'wrapper'}>
        <TopMenu
          {...this.props}
          image={this.state.image}
          color={this.state.color}
          hasImage={this.state.hasImage}
        />
        <div id="main-panel" className="main-panel" ref="mainPanel">
          <div className="content-container">
            <AdminNavbar {...this.props} brandText="SECURITY" />
            <Switch>
              {dashRoutes.map((prop, key) => {
                if (prop.collapse) {
                  return prop.views.map((prop, key) => {
                    if (prop.name === 'Notifications') {
                      return (
                        <Route
                          path={prop.path}
                          key={key}
                          render={(routeProps) => (
                            <prop.component
                              {...routeProps}
                              handleClick={this.handleNotificationClick}
                            />
                          )}
                        />
                      );
                    } else {
                      return (
                        <Route
                          path={prop.path}
                          component={prop.component}
                          key={key}
                        />
                      );
                    }
                  });
                } else {
                  if (prop.redirect)
                    return (
                      <Redirect from={prop.path} to={prop.pathTo} key={key} />
                    );
                  else
                    return (
                      <Route
                        path={prop.path}
                        component={prop.component}
                        key={key}
                      />
                    );
                }
              })}
            </Switch>
          </div>{' '}
          <FooterDash fluid />
        </div>
      </div>
    );
  }
}

export default Dash;
