import view from "../views";
var pagesRoutes = [
  
  { component: view.Login, path: "/login", exact: true, name: "Login" },
  { component: view.Signup, path: "/signup", exact: true, name: "Registro" },
  { component: view.ResetPassword, path: "/reset", exact: true, name: "Reset" },
  { component: view.Login, path: "/", exact: true, name: "Login" },
  { component: view.Error404, path: "*", exact: false, name: "Error404" },

];
export default pagesRoutes;
