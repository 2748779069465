import React, { useState } from 'react';
import { Navbar } from 'react-bootstrap';
import AdminNavbarLinks from './AdminNavbarLinks.jsx';
import language from '../../config/es';
import logo from '../../assets/img/logo_auth.png';
import { Fragment } from 'react';

const Header = () => {
  const [user] = useState(JSON.parse(sessionStorage.getItem("user")))
  const [data, setData] = useState({
    sidebarExists: true,
  });
  //handlers ---------------------------------------------------
  const refreshState = () => {
    setData({
      ...data,
    });
  };
  //funciones ---------------------------------------------------
  const mobileSidebarToggle = (e) => {
    if (data.sidebarExists === false) {
      data.sidebarExists = true;
      refreshState();
    }
    e.preventDefault();
    document.documentElement.classList.toggle('nav-open');
    let node = document.createElement('div');
    node.id = 'bodyClick';
    node.onclick = function () {
      this.parentElement.removeChild(this);
      document.documentElement.classList.toggle('nav-open');
    };
    document.body.appendChild(node);
  };
  return (
    <Fragment>
      <Navbar
        bg="header"
        variant="dark"
        expand="sm"
        className="justify-content-between "
      >
        <Navbar.Brand className="navbar-title headerTitle">
          <img src={logo} alt="react-logo" className="header-logo" />
        </Navbar.Brand>
        <Navbar.Brand className="headerTitle">
          {language.messages.title_system}
          <div className="userTitle">{user?.id.user.name}</div>
          <div className="userTitle">{user?.id.role.name}</div>
          <Navbar.Toggle onClick={mobileSidebarToggle} />
        </Navbar.Brand>
        <Navbar.Collapse className="justify-content-end">
          <Navbar.Text>
            <AdminNavbarLinks />
          </Navbar.Text>
        </Navbar.Collapse>
      </Navbar>
    </Fragment>
  );
};
export default Header;
