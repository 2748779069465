import React, { Fragment, useEffect, useRef } from 'react';
//Session user
import SessionConfig from '../../config/session_config';

const Dashboard = () => {
  const ref = useRef();
  useEffect(() => {
    ref.current.onRefreshCurrentSession();
  }, []);
  return (
    <Fragment>
    <SessionConfig ref={ref}/>
    </Fragment>
  );
};
export default Dashboard;
