import { getService, postBodyService } from "./base.service";
import config from "../config/application";
import permission from '../api/permissions.service';
import dictionary from '../config/dictionary';

//url
const list = 'find_all';
const add = 'add';
const update = 'update';
const deleteUrl = 'delete';

class CountryService {
    getCountries() {
        const url = `${config.data.configuration.country.url}${list}`;
        return getService(url);
    }
    async getRawCountries(username) {
        let valid = await permission.validPermission(
            process.env.REACT_APP_TRANSACTION_COUNTRY,
            username,
            process.env.REACT_APP_SYSTEM_CODE, dictionary.configApp.action.list);
            
        if (valid.response === undefined && valid.data.allow === true) {
            const url = `${config.data.configuration.country.url}${list}`;
            return getService(url);
        } else {
            return valid;
        }
    }
    async saveCountry(country, username) {
        let valid = await permission.validPermission(
            process.env.REACT_APP_TRANSACTION_COUNTRY,
            username,
            process.env.REACT_APP_SYSTEM_CODE, dictionary.configApp.action.add);
        if (valid.response === undefined && valid.data.allow === true) {
            const url = `${config.data.configuration.country.url}${add}`;
            return postBodyService(url, country);
        } else {
            return valid;
        }
    }
    async updateCountry(country, username) {
        let valid = await permission.validPermission(
            process.env.REACT_APP_TRANSACTION_COUNTRY,
            username,
            process.env.REACT_APP_SYSTEM_CODE, dictionary.configApp.action.edit);
        if (valid.response === undefined && valid.data.allow === true) {
            const url = `${config.data.configuration.country.url}${update}`;
            return postBodyService(url, country);
        } else {
            return valid;
        }
    }
    async deleteCountry(lsCountries, username) {
        let valid = await permission.validPermission(
            process.env.REACT_APP_TRANSACTION_COUNTRY,
            username,
            process.env.REACT_APP_SYSTEM_CODE, dictionary.configApp.action.delete);
        if (valid.response === undefined && valid.data.allow === true) {
            const url = `${config.data.configuration.country.url}${deleteUrl}`;
            return postBodyService(url, lsCountries);
        } else {
            return valid;
        }
    }

}
// eslint-disable-next-line import/no-anonymous-default-export
export default new CountryService();