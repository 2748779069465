import React from "react";
import ReactDOM from "react-dom";

import { BrowserRouter, Route, Switch } from "react-router-dom";

import { Provider } from "react-redux";
import { store } from "./redux/store/store";

import './assets/css/bootstrap4.min.css';
import './assets/css/page.css';
import "./assets/css/animate.min.css";
import "./assets/sass/light-bootstrap-dashboard.scss?v=1.3.2";
import "./assets/sass/lbd/_datetime-pickers.scss";
import 'font-awesome/css/font-awesome.min.css';
import "./assets/css/pe-icon-7-stroke.css";

import routes from "./routes/app";

ReactDOM.render(
  <Provider store={store}>
      <BrowserRouter>
        <Switch>
          {routes.map((item) => (
            <Route
              key={item.path}
              component={item.component}
              path={item.path}
              exact={item.exact}
              render={(props) => <item {...props} />}
            />
          ))}
        </Switch>
      </BrowserRouter>
    
  </Provider>,
  document.getElementById("root")
);
