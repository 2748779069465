import service from '../api/society.service';
import serviceTransaction from '../api/transaction.service';
//constants
const dataSociety = {
    societies: [],
    societiesRoles: [],
    backup: [],
    societyAdd: null,
    responseSociety: null,
    errorSociety: ''
}
//types
const LIST_SOCIETY = 'LIST_SOCIETY';
const LIST_SOCIETY_ROLE = 'LIST_SOCIETY_ROLE';
const ERROR_SOCIETY = 'ERROR_SOCIETY';
const ADD_SOCIETY = 'ADD_SOCIETY';
const UPDATE_SOCIETY = 'UPDATE_SOCIETY';
const DELETE_SOCIETY = 'DELETE_SOCIETY';
const SEARCH_SOCIETY = 'SEARCH_SOCIETY';

//reducer
export default function societyReducer(state = dataSociety, action) {
    switch (action.type) {
        case LIST_SOCIETY:
            return { ...state, societies: action.payload, backup: action.payload };
        case ADD_SOCIETY:
            return { ...state, societyAdd: action.payload };
        case ERROR_SOCIETY:
            return { ...state, errorSociety: action.payload };
        case SEARCH_SOCIETY:
            return { ...state, societies: action.payload };
        case LIST_SOCIETY_ROLE:
            return { ...state, societiesRoles: action.payload };
        default:
            return { ...state };
    }
}

export const searchSociety = (filter, societies) => (dispatch) => {
    let ls = [];
    let society = societies.find((item) => item.code === filter.toUpperCase());
    let societyByName = societies.filter((item) => item.name.toUpperCase().search(filter.toUpperCase()) >= 0);

    if (society !== undefined) {
        ls.push(society);
    }
    dispatch({
        type: SEARCH_SOCIETY,
        payload: filter === '' ? societies : ls.length === 0 ? societyByName : ls
    });
}
export const findSocieties = () => async (dispatch) => {
    const response = await service.getSocieties().then((r) => {
        return r.data !== undefined ? r.data : r.response.data;
    });
    let data = [];

    if (response.code !== undefined) {
        dispatch({
            type: ERROR_SOCIETY,
            payload: response
        });
        dispatch({
            type: LIST_SOCIETY,
            payload: []
        });
    } else {
        response.forEach((element) => {
            data.push({
                value: element.id,
                label: element.name.toUpperCase() + ' | ' + element.code,
            });
        });
        dispatch({
            type: ERROR_SOCIETY,
            payload: ''
        });
        dispatch({
            type: LIST_SOCIETY,
            payload: data
        });
    }
}

export const findByCountry = (country) => async (dispatch) => {
    const response = await service.findByCountry(country).then((r) => {
        return r.data !== undefined ? r.data : r.response.data;
    });
    let data = [];

    if (response.code !== undefined) {
        dispatch({
            type: ERROR_SOCIETY,
            payload: response
        });
        dispatch({
            type: LIST_SOCIETY,
            payload: []
        });
    } else {
        response.forEach((element) => {
            data.push({
                value: element.id,
                label: element.name.toUpperCase() + ' | ' + element.code,
            });
        });
        dispatch({
            type: ERROR_SOCIETY,
            payload: ''
        });
        dispatch({
            type: LIST_SOCIETY,
            payload: data
        });
    }
}
export const findSocietiesByRole = (role) => async (dispatch) => {
    const response = await serviceTransaction.findSocietiesByRol(role).then((r) => {
        return r.data !== undefined ? r.data : r.response.data;
    });
    if (response.code !== undefined) {
        dispatch({
            type: ERROR_SOCIETY,
            payload: response
        });
        dispatch({
            type: LIST_SOCIETY_ROLE,
            payload: []
        });
    } else {

        dispatch({
            type: ERROR_SOCIETY,
            payload: ''
        });
        dispatch({
            type: LIST_SOCIETY_ROLE,
            payload: response
        });
    }


}

export const findSocietiesByIdByCountryId = (country, username) => async (dispatch) => {
    const response = await service.findSocietiesByCountryId(country, username).then((r) => {
        return r.data !== undefined ? r.data : r.response.data;
    });
    if (response.code !== undefined) {
        dispatch({
            type: ERROR_SOCIETY,
            payload: response
        });
        dispatch({
            type: LIST_SOCIETY,
            payload: []
        });
    } else {

        dispatch({
            type: ERROR_SOCIETY,
            payload: ''
        });
        dispatch({
            type: LIST_SOCIETY,
            payload: response
        });
    }


}

export const findAll = () => async (dispatch) => {
    const response = await service.getSocieties().then((r) => {
        return r.data !== undefined ? r.data : r.response.data;
    });

    if (response.code !== undefined) {
        dispatch({
            type: ERROR_SOCIETY,
            payload: response
        });
        dispatch({
            type: LIST_SOCIETY,
            payload: []
        });
    } else {
        dispatch({
            type: ERROR_SOCIETY,
            payload: ''
        });
        dispatch({
            type: LIST_SOCIETY,
            payload: response
        });
    }

    return response;
}

export const saveSociety = (societyData, username) => async (dispatch) => {
    const response = await service.saveSociety(societyData, username).then((r) => {
        return r.data !== undefined ? r.data : r.response.data
    });

    if (response.error !== undefined) {
        dispatch({
            type: ERROR_SOCIETY,
            payload: response
        });
        dispatch({
            type: ADD_SOCIETY,
            payload: ''
        });
    } else {
        dispatch({
            type: ERROR_SOCIETY,
            payload: ''
        });
        dispatch({
            type: ADD_SOCIETY,
            payload: response
        });
    }

    return response;
}
export const updateSociety = (societyData, username) => async (dispatch) => {
    const response = await service.updateSociety(societyData, username).then((r) => {
        return r.data !== undefined ? r.data : r.response.data
    });
    if (response.error !== undefined) {
        dispatch({
            type: ERROR_SOCIETY,
            payload: response
        });
        dispatch({
            type: UPDATE_SOCIETY,
            payload: ''
        });
    } else {
        dispatch({
            type: ERROR_SOCIETY,
            payload: ''
        });
        dispatch({
            type: UPDATE_SOCIETY,
            payload: response
        });
    }


    return response;
}

export const deleteSociety = (societies, username) => async (dispatch) => {
    const response = await service.deleteSociety(societies, username).then((r) => {
        return r.data !== undefined ? r.data : r.response.data
    });

    if (response.error !== undefined) {
        dispatch({
            type: ERROR_SOCIETY,
            payload: response
        });
        dispatch({
            type: DELETE_SOCIETY,
            payload: ''
        });
    } else {
        dispatch({
            type: ERROR_SOCIETY,
            payload: ''
        });
        dispatch({
            type: DELETE_SOCIETY,
            payload: response
        });
    }

    return response;
}