import service from '../api/rol.service';
import language from '../config/es';
//constants
const dataRol = {
    roles: [],
    menus: [],
    assigned: [],
    transactionsNotAssigned: [],
    transactionsNotAssignedFolder: [],
    deleteRol: [],
    deleteUserTransaction: [],
    updateRol: [],
    createRol: [],
    responseRol: null,
    noPermitted: ''
}
//types
const LIST_ROL = 'LIST_ROL'
const LIST_MENUS = 'LIST_MENUS';
const LIST_TRANSACTIONS_NOT_ASSIGNED = 'LIST_TRANSACTIONS_NOT_ASSIGNED';
const LIST_TRANSACTIONS_NOT_ASSIGNED_FOLDER = 'LIST_TRANSACTIONS_NOT_ASSIGNED_FOLDER';
const LIST_USER_TRANSACTIONS = 'LIST_USER_TRANSACTIONS';
const DELETE_ROL = 'DELETE_ROL'
const DELETE_USER_TRANSACTION = 'DELETE_USER_TRANSACTION'
const UPDATE_ROL = 'UPDATE_ROL'
const CREATE_ROL = 'CREATE_ROL'
const LIST_ERROR = 'LIST_ERROR';

//reducer
export default function rolReducer(state = dataRol, action) {
    switch (action.type) {
        case LIST_ROL:
            return { ...state, roles: action.payload };
        case LIST_MENUS:
            return { ...state, menus: action.payload };
        case LIST_TRANSACTIONS_NOT_ASSIGNED:
            return { ...state, transactionsNotAssigned: action.payload };
        case LIST_TRANSACTIONS_NOT_ASSIGNED_FOLDER:
            return { ...state, transactionsNotAssignedFolder: action.payload };
        case LIST_USER_TRANSACTIONS:
            return { ...state, assigned: action.payload };
        case DELETE_ROL:
            return { ...state, deleteRol: action.payload };
        case DELETE_USER_TRANSACTION:
            return { ...state, deleteUserTransaction: action.payload };
        case UPDATE_ROL:
            return { ...state, updateRol: action.payload };
        case CREATE_ROL:
            return { ...state, createRol: action.payload };
        default:
            return { ...state };
        case LIST_ERROR:
            return { ...state, noPermitted: action.payload };
    }
}
export const findTransaction = (rol) => async (dispatch) => {
    const response = await service.findNotAssignedUserRoleMenu(rol).then((r) => { return r.data });
    dispatch({
        type: LIST_MENUS,
        payload: response
    });
    return response;
}
export const findTreeListBySystem = (system, username) => async (dispatch) => {
    const response = await service.getTreeListBySystem(system, username).then((r) => { return r });
    if (response.data === undefined) {
        return 403;
    } else {
        dispatch({
            type: LIST_MENUS,
            payload: response
        });
        return response.data;
    }
}
export const findTreeList = (role, user_id) => async (dispatch) => {
    const response = await service.getTreeList(role, user_id).then((r) => { return r });
    if (response.data === undefined) {
        return 403;
    } else {
        dispatch({
            type: LIST_MENUS,
            payload: response
        });
        return response.data;
    }
}
export const findAllByUserId = (user, system, auth) => async (dispatch) => {
    const response = await service.findAllByUserId(user, system, auth).then((r) => { return r });

    if (response.data === undefined) {
        return 403;
    } else {
        dispatch({
            type: LIST_MENUS,
            payload: response
        });
        return response.data;
    }
}

export const findNotAssignedUserRoleMenu = (roleUser, username) => async (dispatch) => {
    let data = [];
    const response = await service.findNotAssignedUserRoleMenu(roleUser, username).then((r) => { return r.data });
    if (response != null) {
        response.forEach((element) => {
            data.push({
                value: element.transaction.transactionId,
                label: element.transaction.name.toUpperCase()
            });
        });
    } else {
        dispatch({
            type: LIST_ERROR,
            payload: language.messages.error.noAccess
        });
    }
    dispatch({
        type: LIST_USER_TRANSACTIONS,
        payload: data
    });
}

export const findTransactionsNotAssigned = (rol, level, auth) => async (dispatch) => {
    let data = [];
    const response = await service.findTransactionsNotAssigned(rol, level, auth).then((r) => { return r.data });
    response.forEach((element) => {
        data.push({
            value: element.id,
            label: element.name
        });
    });
    dispatch({
        type: LIST_TRANSACTIONS_NOT_ASSIGNED,
        payload: data
    });
    return data;
}

export const findTransactionsNotAssignedFolder = (rol, level, auth) => async (dispatch) => {
    let data = [];
    const response = await service.findTransactionsNotAssignedFolder(rol, level, auth).then((r) => { return r.data });
    response.forEach((element) => {
        data.push({
            value: element.id,
            label: element.name
        });
    });
    dispatch({
        type: LIST_TRANSACTIONS_NOT_ASSIGNED_FOLDER,
        payload: data
    });
    return data;
}

export const deleteTransaction = (menu_id, auth) => async (dispatch) => {
    const response = await service.deleteTransaction(menu_id, auth).then((r) => { return r.data });
    dispatch({
        type: DELETE_ROL,
        payload: response
    });
    return response;
}

export const deleteUserTransaction = (id, auth) => async (dispatch) => {
    const response = await service.deleteUserTransaction(id, auth).then((r) => { return r });
    dispatch({
        type: DELETE_USER_TRANSACTION,
        payload: response
    });
    return response;
}

export const updateTransaction = (json, auth) => async (dispatch) => {
    const response = await service.getUpdateRol(json, auth).then((r) => { return r.data });
    dispatch({
        type: UPDATE_ROL,
        payload: response
    });
    return response;
}

export const createTransaction = (id, auth) => async (dispatch) => {
    const response = await service.getCreateRol(id, auth).then((r) => { return r.data });
    dispatch({
        type: CREATE_ROL,
        payload: response
    });
    return response;
}