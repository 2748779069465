import service from '../api/login.service';
import publicIp from 'public-ip';


const dataLogin = {
    user: null,
    responseUser: null,
    errorLogin: '',
}
//types
const USER_LOGIN = 'USER_LOGIN'
const LOGIN_ERROR = 'LOGIN_ERROR'
//reducer
export default function loginReducer(state = dataLogin, action) {
    switch (action.type) {
        case USER_LOGIN:
            return { ...state, user: action.payload };
        default:
            return { ...state };
    }
}

export const logInUserData = () => async (dispatch) => {
    const response = await service.login();

    const ip = await publicIp.v4();
     
    let jsonLogin = {
        cognitoId: response.username,
        username: response.username,
        email: response.username,
        sessionIp: ip,
        name: response.signInUserSession.idToken.payload.given_name + ' ' + response.signInUserSession.idToken.payload.family_name,
        codeSystem: process.env.REACT_APP_SYSTEM_CODE,
        registerLog: true,
    };
    await service.registerUser(jsonLogin);
    const loginData = await service.loginAD(jsonLogin).then((r) => {
        return r.data !== undefined ? r.data : r.response.data;
    });

    if (loginData.error === undefined) {
        window.sessionStorage.removeItem('errorSession');

        sessionStorage.setItem('user', JSON.stringify(loginData.rol));
        sessionStorage.setItem('menu', JSON.stringify(loginData.menu));
    } else {
        sessionStorage.setItem('errorSession', JSON.stringify(loginData));
        dispatch({
            type: LOGIN_ERROR,
            payload: loginData
        });
    }

    dispatch({
        type: USER_LOGIN,
        payload: response
    });
    return response;
}
export const logout = (jsonLogout) => async (dispatch) => {

    const response = await service.logout(jsonLogout).then((r) => {
        return r.data !== undefined ? r.data : r.response.data;
    });

    if (response.error !== undefined) {
        dispatch({
            type: LOGIN_ERROR,
            payload: response
        });

    } else {
        dispatch({
            type: LOGIN_ERROR,
            payload: ''
        });

    }

}