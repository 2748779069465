import axios from 'axios';
import { getToken } from './token.service'
import language from '../config/es';

//constantes
const headers = {
    'Content-Type': 'application/json',
    Authorization: ''
};

// eslint-disable-next-line no-unused-vars
export function getService(url) {
    return new Promise(async (resolve) => {
        let token = await getToken();
        if (token !== null && token !== undefined) {
            headers.Authorization = 'Bearer ' + token.access_token;

            axios
                .get(url, {
                    headers: headers,
                    method: 'get'
                }).then(res => {
                    resolve(res);
                }).catch(error => {
                    resolve(error);
                });
        }
    });

}
export const postService = (url, body) => {
    return new Promise(async (resolve) => {
        let token = await getToken();
        if (token !== null && token !== undefined) {
            headers.Authorization = 'Bearer ' + token.access_token;
            axios({
                method: 'post',
                url: url,
                data: body,
                headers: headers
            }).then(res => {
                resolve(res);
            }).catch(error => {
                resolve(error);
            });
        }
    })
}

export const postBodyService = (url, json) => {
    return new Promise(async (resolve) => {
        let token = await getToken();

        if (token !== null && token !== undefined) {
            headers.Authorization = token.token_type + ' ' + token.access_token;
            axios({
                method: 'post',
                url: url,
                data: JSON.stringify(json),
                mode: 'no-cors',
                crossDomain: true,
                headers: headers
            }).then(res => {
                resolve(res);
            }).catch(error => {
                resolve(error);
            });
        } else {
            resolve({
                response: { data: { error: language.messages.error.noToken } }
            });
        }
    });
}

export const putBodyService = (url, json) => {
    return new Promise(async (resolve) => {
        let token = await getToken();
        if (token !== null && token !== undefined) {
            headers.Authorization = token.token_type + ' ' + token.access_token;
            axios({
                method: 'put',
                url: url,
                data: JSON.stringify(json),
                mode: 'no-cors',
                crossDomain: true,
                headers: headers
            }).then(res => {
                resolve(res);
            }).catch(error => {
                resolve(error);
            });
        } else {
            resolve({
                response: { data: { error: language.messages.error.noToken } }
            });
        }
    });
}