import { Auth } from 'aws-amplify';
import { postBodyService, putBodyService, getService } from "./oauth.service";
import config from "../config/application";

//url
const loginUrl = 'login';
const logoutUrl = 'logout';
const registerUrl = 'register_user';
const updateUserUrl = 'update_user';
const userUrl = '/user/';

class LoginService {
    login = async () => {

        let user = await Auth.currentAuthenticatedUser();

        if (user === undefined) {
            return null;
        } else {

            sessionStorage.setItem('username', user.getUsername());
            sessionStorage.setItem('name', user.getUsername());

            return user;
        }

    }

    loginAD = (jsonLogin) => {
        const url = `${config.data.security.login.url}/${loginUrl}`;
        return postBodyService(url, jsonLogin);
    }
    logout = (jsonLogout) => {
        const url = `${config.data.security.login.url}/${logoutUrl}`;
        return postBodyService(url, jsonLogout);
    }
    registerUser = (jsonLogin) => {
        const url = `${config.data.security.login.url}/${registerUrl}`;
        return postBodyService(url, jsonLogin);
    }
    updateUser = (jsonLogin) => {
        const url = `${config.data.security.login.url}/${updateUserUrl}`;
        return putBodyService(url, jsonLogin);
    }

    getUser = (username) => {
        const url = `${config.data.security.login.url}${userUrl}${username}`;
        return getService(url);
    }

}
// eslint-disable-next-line import/no-anonymous-default-export
export default new LoginService();