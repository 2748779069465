import axios from 'axios';
import { Auth } from 'aws-amplify';
//constantes
const headers = {
    'Content-Type': 'application/json',
    Authorization: ''
};
const getToken = async(verifySession) => {
    if(verifySession) {
        await Auth.currentSession()
        .then(data => {
            //console.log('get current session token in services');
            headers.Authorization = data.idToken.jwtToken;
        })
        .catch((error) => {
          console.log(error);
          logoutSessionAD();
        });
    } else { headers.Authorization = (await Auth.currentSession()).accessToken.jwtToken; }    
}
const logoutSessionAD = () => {
    try {
        Auth.signOut();
        removeSession();
    } catch(error) {
        console.log(error);
        removeSession();
    }
}
const removeSession = () => {
    window.sessionStorage.removeItem('username');
    window.sessionStorage.removeItem('name');
    window.sessionStorage.removeItem('user');
    window.sessionStorage.removeItem('menu');
    window.sessionStorage.removeItem('token');
    window.sessionStorage.removeItem('node');
    window.sessionStorage.removeItem('menuId');
}
// eslint-disable-next-line no-unused-vars
export  function getService(url) {
    return new Promise(async(resolve) => {
        await getToken(true);
        
        axios
            .get(url, {
                headers: headers,
                method: 'get'
            }).then(res => {
                resolve(res);
            }).catch(error => {
                resolve(error);
            });
    });

}
export const postService = (url, body) => {
    return new Promise(async (resolve) => {
        await getToken(true);
        axios({
            method: 'post',
            url: url,
            data: body,
            headers: headers
        }).then(res => {
            resolve(res);
        }).catch(error => {
            console.warn('error', error)
        });
    })
}

export const postBodyService = (url, json) => {
    return new Promise(async (resolve) => {
        await getToken(true);
        axios({
            method: 'post',
            url: url,
            data: JSON.stringify(json),
            mode: 'no-cors',
            crossDomain: true,
            headers: headers
        }).then(res => {
            resolve(res);
        }).catch(error => {
            console.log("post error", error)
            resolve(error);
        });
    });
}

export const postBodyServiceWithOutVerify = (url, json) => {
    return new Promise(async (resolve) => {
        await getToken(false);
        axios({
            method: 'post',
            url: url,
            data: JSON.stringify(json),
            mode: 'no-cors',
            crossDomain: true,
            headers: headers
        }).then(res => {
            resolve(res);
        }).catch(error => {
            console.log("post error", error)
            resolve(error);
        });
    });
}