//#region IMPORTS

import React, { Fragment, useState, useEffect, useRef } from "react";
import { ToastProvider, useToasts } from "react-toast-notifications";
import { Row, Col, Container, Form } from "react-bootstrap";
import { FormGroup, ButtonGroup, Alert, InputGroup, Tabs, Tab } from "react-bootstrap";
import Card from "components/Card/Card.jsx";
import LabelTitle from "../../components/Label/labelTitle";
import LabelChargeData from "../../components/Label/labelChargeData";
import Select from "react-select";
import Button from "../../components/CustomButton/CustomButton";
import language from "../../config/es";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import DataTable from "react-data-table-component";
import { loadMessages } from "devextreme/localization";
//redux
import { useDispatch, useSelector } from "react-redux";
import {
  findTreeList,
  findAllByUserId,
  deleteUserTransaction,
  findNotAssignedUserRoleMenu,
} from "../../redux/rol.duck";

import {
  findAllRolesBySystem,
  saveUserRole,
  updateUserRole,
  deleteUserRole,
  findRolUserByPaginate,
  countByPaginate,
  updateUser,
  getUsetByUsername,
} from "../../redux/userRole.duck";
import { findSocietiesByRole } from "../../redux/society.duck";
import { findAllSystem } from "../../redux/system.duck";
import { es_vacio, is_email } from "../../config/validate";
//DevExpress
import "./security.css";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import { Switch } from "devextreme-react/switch";
import { CheckBox } from "devextreme-react/check-box";
import { TreeList, Selection, Column } from "devextreme-react/tree-list";
import DataSource from "devextreme/data/data_source";
import {
  DataGrid,
  Editing,
  Scrolling,
  Lookup,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
//Session user
import SessionConfig from "../../config/session_config";
//#endregion IMPORTS

const UserRole = () => {
  //#region VARIABLES
  const ref = useRef();
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  let [loadingRole, saveLodingRole] = useState(true);
  const [pendingData, savePendingData] = useState(false);
  const [enable, saveEnable] = useState(true);
  const [enableProvider, saveEnableProvider] = useState(true);
  let [societies] = useState(true);
  let [filter, setFilter] = useState("");
  let [provider, setProvider] = useState("");
  const [data, setData] = useState({
    transactions: [],
    userRoles: [],
    ds: [],
    userTransactions: [],
    lsUserTransactions: [],
    users: [],
    roles: [],
    society: "BO81",
    user: { value: "", label: "", error: "" },
    system: { value: 0, label: "", error: "" },
    role: { value: 0, label: "", error: "", auth: "" },
    loadingUser: false,
    loadingSystem: false,
    loadingSocieties: true,
    systems: [],
    errors: [],
    columns: [],
    selectedRows: [],
    statusSystem: false,
    enableImport: false,
    showDelete: false,
    auth: JSON.parse(sessionStorage.getItem("user")),
    msmError: "",
    response: "",
    page: 0,
    size: 10,
    totalRows: 0,
  });

  const [dataUser, setDataUser] = useState({
    name: "",
    email: "",
    status: 1,
    username: "",
    userId: "",
    emailError: "",
    userError:"",
    errors: [],
  });
  const [keyDefault, setkeyDefault] = useState("Asignación");
  societies = useSelector((store) => store.societyReducer.societiesRoles);
  data.users = useSelector((store) => store.userSocietyReducer.users);
  data.systems = useSelector((store) => store.systemReducer.systems);
  data.roles = useSelector((store) => store.userRoleReducer.roles);
  data.msmError = useSelector((store) => store.userRoleReducer.errorUserRole);
  data.response = useSelector((store) => store.userRoleReducer.userRoleAdd);
  data.userRoles = useSelector((store) => store.userRoleReducer.userRoles);
  data.totalRows = useSelector((store) => store.userRoleReducer.totalUserRol);

  const [transaction, setTransaction] = useState({
    detailUsername: "",
    detailRoleName: "",
    selected: true,
    selectedSingle: false,
    assigned: [],
    assign: { value: 0, label: "", error: "", auth: "" },
    selectedData: { roleId: 0, userId: 0, roleUser: "", system: 0 },
    allowValue: { create: 0, read: 0, update: 0, delete: 0 },
    errors: [],
    loadingAssigned: false,
    enableAllow: false,
    enable: false,
    selectedRowKeys: [],
    selectedRowsData: [],
    visibleDetail: false,
    visible: false,
    loading: language.messages.msmLoading,
    focusedRowKey: 0,
  });
  transaction.assigned = useSelector((store) => store.rolReducer.assigned);
  transaction.assign.auth = useSelector(
    (store) => store.rolReducer.noPermitted
  );

  const updateTransaction = (value, id) => {
    setTransaction({
      ...transaction,
      [id]: value,
    });
  };
  const ALLOW_OPTIONS = [
    {
      Value: 1,
      Text: language.messages.userRol.yes,
    },
    {
      Value: 0,
      Text: language.messages.userRol.not,
    },
  ];
  //#endregion VARIABLES

  //#region LIFECYCLE
  useEffect(() => {
    messageError();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.msmError]);
  useEffect(() => {
    responseAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.response]);
  useEffect(() => {
    initTable();
    initData();
    loadMessages({
      en: {
        "dxDataGrid-editingDeleteRow": language.messages.userRol.remove,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    saveLodingRole(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.roles]);
  useEffect(() => {
    loadValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.userRoles]);
  useEffect(() => {
    loadUserRoles();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.role]);
  useEffect(() => {
    ref.current.onRefreshCurrentSession();
  }, [data]);
  const loadValues = async () => {
    await clearForm();
    savePendingData(false);
    saveLodingRole(true);
    saveEnable(true);
    clearError();
  };
  const loadSocieties = async () => {
    data.loadingSocieties = false;
    await dispatch(findSocietiesByRole(data.role.value));
    updateState(true, "loadingSocieties");
  };
  const messageError = () => {
    if (data.msmError !== "") {
      viewNotification(data.msmError.error, "error");
      if (pendingData) {
        savePendingData(false);
        saveEnable(true);
      }
    }
  };
  const responseAction = () => {
    if (data.response !== "" && data.response !== null) {
      viewNotification(
        language.messages.msmRegister.replace("{0}", data.user.value),
        "success"
      );
      cleaningForm();
      loadUserRoles();
      savePendingData(false);
      saveEnable(true);
    }
  };

  const initData = async () => {
    await loadTree(0);
    await loadSystems();
  };
  const initDataUserTransaction = async (user, roleUser, system) => {
    await loadUserTransaction(user, system);
    await loadAssignedRoleTransactions(roleUser);
  };
  const loadSystems = async () => {
    await dispatch(findAllSystem());
    data.loadingSystem = true;
    refreshState();
  };
  const loadTree = async (role) => {
    data.ds = new DataSource({
      key: "id",
      load: async function () {
        try {
          let response = await dispatch(
            findTreeList(role, data.auth.id.user.username)
          );
          if (response === 403) {
            viewNotification(
              language.messages.msmRestrictedAccessView,
              "error"
            );
            return null;
          } else {
            updateState(response, "transactions");
            return response;
          }
        } catch (e) {
          throw viewNotification(language.messages.msmErrorExist, "warning");
        }
      },
    });
  };
  const loadUserTransaction = async (user, system) => {
    data.userTransactions = new CustomStore({
      key: "id",
      load: async function () {
        try {
          let response = await dispatch(
            findAllByUserId(user, system, data.auth.id.user.username)
          );
          if (response === 403) {
            viewNotification(
              language.messages.msmRestrictedAccessView,
              "error"
            );
            return null;
          } else {
            data.lsUserTransactions = response;
            return response;
          }
        } catch (e) {
          throw viewNotification(language.messages.msmErrorExist, "warning");
        }
      },
      update: async (key, values) => {
        let allowCreate = values.allowCreate;
        let allowRead = values.allowView;
        let allowUpdate = values.allowUpdate;
        let allowDelete = values.allowDelete;

        if (allowCreate !== undefined) {
          await updateUserTransaction(key, "create", allowCreate);
        } else if (allowRead !== undefined) {
          await updateUserTransaction(key, "read", allowRead);
        } else if (allowUpdate !== undefined) {
          await updateUserTransaction(key, "update", allowUpdate);
        } else if (allowDelete !== undefined) {
          await updateUserTransaction(key, "delete", allowDelete);
        }
      },
      remove: async (key) => {
        viewNotification(language.messages.msmDeleteDataLoading, "success");
        transaction.enable = false;
        refreshTransaction();
        let res = await dispatch(
          deleteUserTransaction(key, data.auth.id.user.username)
        );
        if (res.status === 200) {
          if (res.data === true) {
            viewNotification(language.messages.msmDeleteDataSuccess, "success");
            reloadTransaction();
          } else {
            viewNotification(res.data, "error");
            reloadTransaction();
          }
        } else viewNotification(language.messages.msmErrorExist, "error");
        transaction.enable = true;
      },
    });
  };
  const updateUserTransaction = async (key, option, value) => {
    viewNotification(language.messages.msmRegisterDataLoading, "success");
    transaction.enable = false;
    let json;
    data.lsUserTransactions.forEach((element) => {
      if (element.id === key) {
        switch (option) {
          case "create":
            element.allowCreate = value;
            break;
          case "read":
            element.allowView = value;
            break;
          case "update":
            element.allowUpdate = value;
            break;
          case "delete":
            element.allowDelete = value;
            break;
          default:
            break;
        }
        json = {
          id: key,
          transaction: { id: element.transaction.transactionId },
          user: { id: element.user.userId },
          allowView: element.allowView,
          allowCreate: element.allowCreate,
          allowUpdate: element.allowUpdate,
          allowDelete: element.allowDelete,
          status: element.status,
          societyId: element.societyId,
          userAdmin: data.auth.id.user.username,
        };
      }
    });
    let res = await dispatch(
      updateUserRole(json, 0, data.auth.id.user.username)
    );
    if (res.status === 200) {
      viewNotification(language.messages.msmRegisterDataSuccess, "success");
    } else {
      switch (res.response.status) {
        case 403:
          viewNotification(res.response.data.error, "error");
          break;
        default:
          viewNotification(language.messages.msmErrorExist, "error");
          break;
      }
    }
    transaction.enable = true;
    reloadTransaction();
  };
  const loadUserRoles = async () => {
    savePendingData(true);
    try {
      let filterJson = {
        rol: data.role.value,
        length: data.size,
        page: data.page,
        username: filter,
      };
      await dispatch(countByPaginate(filterJson));
      await dispatch(
        findRolUserByPaginate(data.auth.id.user.username, filterJson)
      );
    } catch (err) {
      viewNotification(language.messages.msmErrorExist, "error");
      savePendingData(false);
      data.userRoles = [];
    }
    savePendingData(false);
  };

  const reloadUserRoles = async () => {
    filter = "";
    setFilter("");
    loadUserRoles();
  };
  const loadRoles = async (system) => {
    await dispatch(findAllRolesBySystem(system, data.auth.id.user.username));
  };
  const loadAssignedRoleTransactions = async (roleUser) => {
    await dispatch(
      findNotAssignedUserRoleMenu(roleUser, data.auth.id.user.username)
    );
    transaction.loadingAssigned = true;
    refreshTransaction();
  };

  const initTable = () => {
    data.columns = [
      {
        name: language.messages.lblFunctions,
        width: "60px",
        cell: (row) => (
          <button
            className="btn-fill btn btn-xs  btn-primary"
            type="button"
            onClick={() => {
              handleButtonEdit(row);
            }}
            id={row.id}
          >
            <i className="fa fa-pencil"></i>
          </button>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
      {
        name: language.messages.userRol.user,
        sortable: false,
        wrap: true,
        width: "350px",
        cell: (row) => (
          <span>
            <div>{row.user.username}</div>
          </span>
        ),
      },
    ];
    updateState(data.columns, "columns");
  };

  //#endregion LIFECYCLE

  //#region HANDLES

  const refreshState = () => {
    setData({
      ...data,
    });
  };
  const refreshTransaction = () => {
    setTransaction({
      ...transaction,
    });
  };
  const updateState = (value, id) => {
    setData({
      ...data,
      [id]: value,
    });
  };
  const handleDelete = () => {
    disableData();
  };
  const handleSelectRow = (state) => {
    updateState(state.selectedRows, "selectedRows");
  };
  const handleButtonEdit = async (row) => {
    const res = await dispatch(getUsetByUsername(row.user.username));
    setDataUser(res);
    viewNotification(language.messages.msmDataLoading, "success");
    clearFormDetail();
    transaction.visibleDetail = true;
    transaction.enable = true;
    data.userTransactions = [];
    data.lsUserTransactions = [];
    transaction.selectedData.userId = row.user.userId;
    transaction.selectedData.roleId = row.role.roleId;
    transaction.selectedData.roleUser = row.role.roleId + "-" + row.user.userId;
    transaction.selectedData.system = row.role.system;
    initDataUserTransaction(
      transaction.selectedData.userId,
      transaction.selectedData.roleUser,
      transaction.selectedData.system
    );
    transaction.detailUsername = row.user.username;
    transaction.detailRoleName = row.role.name;
    refreshState();
  };
  const changeUser = async (event) => {
    switch (event.target.name) {
      case "username":
        data.user.value = event.target.value;
        data.user.error = "";
        break;

      default:
        break;
    }
    refreshState();
  };

  const onChangeUser = (event) => {
    switch (event.target.name) {
      case "name":
        setDataUser({ ...dataUser, name: event.target.value });
        break;
      case "email":
        setDataUser({ ...dataUser, email: event.target.value });
        break;
      case "status":
        setDataUser({ ...dataUser, status: event.target.checked ? 1 : 0 });
        break;
      default:
        break;
    }
  };

  const updateSelect = async (value, id) => {
    switch (id) {
      case "system":
        await setProvider((value.provider + "_").toLowerCase());

        data.system = value;
        data.role = { value: 0, label: "", error: "" };
        saveLodingRole(false);
        await loadRoles(data.system.value);

        break;
      case "user":
        data.user = value;
        break;
      case "role":
        data.role = value;
        loadSocieties(data.role.value);
        if (transaction.selectedSingle === true) {
          loadTree(data.role.value);
          transaction.visible = true;
        } else if (transaction.selected === false) {
          transaction.visible = false;
        }
        break;
      default:
        break;
    }
    refreshState();
  };
  const updateSelectTransaction = (data, id) => {
    switch (id) {
      case "assign":
        transaction.assign = data;
        transaction.enableAllow = true;
        break;
      case "allowCreate":
        transaction.allowValue.create = data.value === true ? 1 : 0;
        break;
      case "allowRead":
        transaction.allowValue.read = data.value === true ? 1 : 0;
        break;
      case "allowUpdate":
        transaction.allowValue.update = data.value === true ? 1 : 0;
        break;
      case "allowDelete":
        transaction.allowValue.delete = data.value === true ? 1 : 0;
        break;
      default:
        break;
    }
    refreshTransaction();
  };
  const onSelectionChanged = (e) => {
    var rowIndex = e.currentSelectedRowKeys[0];
    data.transactions.forEach((element) => {
      if (element.id === rowIndex) {
        if (element.level === "2") {
          updateTransaction(e.selectedRowKeys, "selectedRowKeys");
        } else {
          viewNotification(language.messages.msmErrorOnlyTransactions, "error");
        }
      }
    });
  };
  const onSelectedChanged = (e) => {
    transaction.selectedSingle = false;
    transaction.visible = false;
    data.statusSystem = false;
    updateTransaction(true, "selected");
  };
  const onSelectedChangedSingle = (e) => {
    if (!es_vacio(data.role.label)) {
      data.statusSystem = true;
      transaction.selected = false;
      loadTree(data.role.value);
      transaction.visible = true;
      updateTransaction(e.value, "selectedSingle");
    }
  };
  //#endregion HANDLES

  //#region FUNCTIONS

  const saveData = async () => {
    validate();
    if (data.errors.length === 0) {
      if (
        transaction.selectedSingle !== false ||
        transaction.selected !== false
      ) {
        savePendingData(true);
        transaction.visible = false;
        saveEnable(false);

        let json = {
          id: {
            user: { id: enableProvider ? provider + data.user.value : data.user.value },
            role: { id: data.role.value, system: { id: data.system.value } },
          },
          transactionId: 0,
          status: 1,
          societyId: data.society,
          userAdmin: data.auth.id.user.username,
        };
        if (transaction.selectedSingle === true) {
          let user_role =
            json.id.user.id +
            "-" +
            json.id.role.id +
            "-" +
            json.societyId +
            "-" +
            "admin";
          let jsonBody = [];
          transaction.selectedRowKeys.forEach((row) => {
            data.transactions.forEach((element) => {
              if (element.id === row) {
                let transaction = {
                  id: null,
                  transaction: { id: element.transaction },
                };
                jsonBody.push(transaction);
              }
            });
          });
          await dispatch(
            saveUserRole(user_role, jsonBody, data.auth.id.user.username)
          );
        } else if (transaction.selected === true) {
          await dispatch(saveUserRole(0, json, data.auth.id.user.username));
        }
        saveEnable(true);
        savePendingData(false);
      } else
        viewNotification(
          language.messages.msmEmptySelectedTransactionsForm,
          "warning"
        );
    } else viewNotification(language.messages.msmErrorForm, "error");
  };
  const disableData = async () => {
    let deleteData = true;
    if (data.selectedRows.length > 0) {
      data.selectedRows.forEach((element) => {
        if (element.user.userId !== data.auth.id.user.userId) {
          element.status = 0;
          element.userId = element.user.userId;
          element.roleId = element.role.roleId;
          element.systemId = element.role.system;

          element.transactionId = 0;
        } else deleteData = false;
      });
      if (deleteData) {
        savePendingData(true);
        saveEnable(false);
        updateState(false, "showDelete");
        data.selectedRows.forEach((e) => {
          e.enabled = 0;
        });
        let res = await dispatch(
          deleteUserRole(data.selectedRows, data.auth.id.user.username)
        );
        if (res.status === 200) {
          viewNotification(language.messages.msmDeleteAll, "success");

          loadUserRoles();
        } else {
          switch (res.response.status) {
            case 403:
              viewNotification(res.response.data.error, "error");
              savePendingData(false);
              cleaningForm();
              break;
            default:
              viewNotification(language.messages.msmErrorExist, "error");
              savePendingData(false);
              cleaningForm();
              break;
          }
        }
        saveEnable(true);
        await updateState(true, "showDelete");
      } else {
        viewNotification(language.messages.msmCurrentUserDisabled, "error");
        savePendingData(false);
      }
    } else {
      viewNotification(language.messages.msmEmptyDataDisabled, "error");
    }
  };
  const onChangeFilter = async (event) => {
    if (event.target !== null) {
      if (es_vacio(event.target.value)) {
        reloadUserRoles();
      } else {
        setFilter(String(event.target.value).trim());
      }
    }
  };
  const onUpdateUser = async () => {
    validateDataUser();
    if (dataUser.errors.length === 0) {
      const response = await dispatch(updateUser({ ...dataUser, state: dataUser.status }));
      if (response === null) {
        viewNotification(language.messages.msmErrorExist, "error");
      } else {
        viewNotification(language.messages.msmRegisterDataSuccess, "success");
      }
    } else viewNotification(language.messages.msmErrorForm, "error");
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const contextHeader = React.useMemo(() => {
    return (
      <Row>
        <Col md={8}>
          <Form.Control
            size="sm"
            type="text"
            name="search"
            value={filter}
            placeholder={language.messages.society.filter}
            onChange={onChangeFilter}
          />
        </Col>
        <Col>
          <Button info fill type="button" font_small onClick={loadUserRoles}>
            <i className="fa fa-search"></i>
          </Button>
        </Col>
      </Row>
    );
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const contextActions = React.useMemo(() => {
    return (
      <Button
        key="delete"
        onClick={handleDelete}
        className="btn btn-danger-up small-font "
        type="button"
        font_small
        disabled={!enable}
      >
        {" "}
        <i className="fa fa-trash"></i>
        {language.messages.userRol.deleteAssign}
      </Button>
    );
  });
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
  }));
  const LinearIndeterminate = () => {
    const classes = useStyles();

    return (
      <div className={classes.root}>
        <LinearProgress />
      </div>
    );
  };
  const paginationOptions = {
    rowsPerPageText: language.messages.rowsPerPageText,
    rangeSeparatorText: language.messages.rangeSeparatorText,
    selectAllRowsItem: true,
    selectAllRowsItemText: language.messages.selectAllRowsItemText,
  };
  const viewNotification = (msm, action) => {
    addToast(msm, {
      appearance: action,
      autoDismiss: true,
    });
  };
  const clearError = () => {
    data.user.error = "";
    data.role.error = "";
    transaction.assign.error = "";
    data.errors = [];
    transaction.errors = [];
    dataUser.errors = [];
    dataUser.emailError = "";
    dataUser.userError = "";
  };
  const clearForm = () => {
    data.user = { value: "", label: "", error: "" };
    transaction.selectedSingle = false;
    transaction.selected = true;
    updateTransaction([], "selectedRowKeys");
    updateState(data.user, "user");
  };
  const clearFormDetail = () => {
    transaction.detailUsername = "";
    transaction.detailRoleName = "";
  };
  const validate = () => {
    clearError();

    if (es_vacio(data.role.label)) {
      data.errors.push("role.error");
      data.role.error = language.messages.warning_required_field;
    }

    if (es_vacio(data.system.label)) {
      data.errors.push("system.error");
      data.system.error = language.messages.warning_required_field;
    }

    if (es_vacio(data.user.value)) {
      data.errors.push("user.error");
      data.user.error = language.messages.warning_required_field;
    }
    if (!is_email(data.user.value) && enableProvider) {
      data.errors.push("user.error");
      data.user.error = language.messages.warningOnlyEmail;
    }
    refreshState();
  };

  const validateDataUser = () => {
    clearError();
    if (es_vacio(dataUser.name)) {
      dataUser.errors.push("user.error");
      dataUser.userError = language.messages.warning_required_field;
    }
    if (!is_email(dataUser.email)) {
      dataUser.errors.push("email.error");
      dataUser.emailError = language.messages.warningOnlyEmail;
    }
    refreshState();
  };
  const validateTransaction = () => {
    clearError();
    if (es_vacio(transaction.assign.label)) {
      transaction.errors.push("assign.error");
      transaction.assign.error = language.messages.warning_required_field;
    }
    refreshTransaction();
  };
  const cleaningForm = () => {
    transaction.visible = false;
    data.statusSystem = false;
    clearError();
    clearForm();
    refreshState();
    viewNotification(language.messages.successCleanData, "success");
  };
  const clearFormAssigned = () => {
    transaction.enableAllow = false;
    transaction.allowValue = { create: 0, read: 0, update: 0, delete: 0 };
    transaction.assign = { value: 0, label: "", error: "", auth: "" };
  };
  const reloadData = () => {
    savePendingData(true);
    transaction.visible = false;
    clearForm();

    loadUserRoles();
  };
  const closeTransaction = () => {
    transaction.visibleDetail = false;
    transaction.enable = false;
    dataUser.username = "";
    setkeyDefault("Asignación")
    reloadData();
  };
  const reloadTransaction = () => {
    clearFormAssigned();
    transaction.loadingAssigned = false;
    refreshTransaction();
    initDataUserTransaction(
      transaction.selectedData.userId,
      transaction.selectedData.roleUser,
      transaction.selectedData.system
    );
  };
  const saveAssign = async () => {
    transaction.enable = false;
    validateTransaction();
    if (transaction.errors.length === 0) {
      let json = {
        id: 0,
        transaction: { id: transaction.assign.value },
        user: { id: transaction.selectedData.userId },
        allowView: transaction.allowValue.read,
        allowCreate: transaction.allowValue.create,
        allowUpdate: transaction.allowValue.update,
        allowDelete: transaction.allowValue.delete,
        status: 1,
        societyId: data.auth.id.user.societyId,
        userAdmin: data.auth.id.user.username,
      };
      viewNotification(language.messages.msmRegisterDataLoading, "success");
      let res = await dispatch(
        updateUserRole(json, 1, data.auth.id.user.username)
      );
      if (res.status === 200) {
        viewNotification(language.messages.msmRegisterDataSuccess, "success");
      } else {
        switch (res.response.status) {
          case 403:
            viewNotification(res.response.data.error, "error");
            break;
          default:
            viewNotification(language.messages.msmErrorExist, "error");
            break;
        }
      }
      reloadTransaction();
    } else viewNotification(language.messages.msmErrorForm, "error");
    transaction.enable = true;
    refreshTransaction();
  };

  const handlePerRowsChange = async (perPage, page) => {
    data.size = perPage;
    data.page = parseInt(page) - 1;
    if (data.page < 0) {
      data.page = 0;
    }

    savePendingData(true);
    updateState(data.size, "size");
    updateState(data.page, "page");

    loadUserRoles();
  };
  const handlePageChange = async (page) => {
    data.page = parseInt(page) - 1;
    if (data.page < 0) {
      data.page = 0;
    }
    savePendingData(true);
    updateState(data.page, "page");
    loadUserRoles();
  };
  //#endregion FUNCTIONS

  return (
    <Fragment>
      <SessionConfig ref={ref} />
      <br />
      <Container fluid={true}>
        <Row>
          <Col sm={12} md={5} lg={5} xl={4}>
            <Card
              icono=" fa fa-cogs"
              title={language.messages.userRol.title}
              content={
                <Tabs
                  activeKey={keyDefault}
                  onSelect={(k) => setkeyDefault(k)}
                  id="uncontrolled-tab-example"
                  className="mb-3"
                >
                  <Tab eventKey="Asignación" title="Asignación">
                    <Fragment>
                      {
                        transaction.visibleDetail ? (
                          //#region asignacion de transacciones por usuario
                          <div>
                            <form autoComplete="off">
                              <Alert className="alert-primary">
                                <strong className="text-danger small">
                                  {language.messages.labelSymbol}
                                </strong>
                                <span className="small">
                                  {" "}
                                  {language.messages.labelSymbolRequired}
                                </span>
                              </Alert>
                              <FormGroup>
                                <LabelTitle
                                  text={
                                    language.messages.labelNotAssignedTransactions
                                  }
                                  requerid={true}
                                />
                                <Select
                                  placeholder={language.messages.lblSingleSelect}
                                  name="assigned"
                                  options={transaction.assigned}
                                  className="text-uppercase small text-primary"
                                  value={{
                                    value: transaction.assign.value,
                                    label: transaction.assign.label,
                                  }}
                                  onChange={(value) => {
                                    updateSelectTransaction(value, "assign");
                                  }}
                                />
                                <LabelChargeData
                                  showloading={transaction.loadingAssigned}
                                />
                                <span className="small text-danger">
                                  {transaction.assign.error}
                                </span>
                                <div className="small text-danger">
                                  {transaction.assign.auth}
                                </div>
                                {transaction.enableAllow ? (
                                  <div>
                                    <div className="dx-field">
                                      <div className="dx-field-label">
                                        {language.messages.lbAllowCreate}
                                      </div>
                                      <div className="dx-field-value">
                                        <Switch
                                          defaultValue={false}
                                          onValueChanged={(value) => {
                                            updateSelectTransaction(
                                              value,
                                              "allowCreate"
                                            );
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className="dx-field">
                                      <div className="dx-field-label">
                                        {language.messages.lbAllowRead}
                                      </div>
                                      <div className="dx-field-value">
                                        <Switch
                                          defaultValue={false}
                                          onValueChanged={(value) => {
                                            updateSelectTransaction(
                                              value,
                                              "allowRead"
                                            );
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className="dx-field">
                                      <div className="dx-field-label">
                                        {language.messages.lbAllowUpdate}
                                      </div>
                                      <div className="dx-field-value">
                                        <Switch
                                          defaultValue={false}
                                          onValueChanged={(value) => {
                                            updateSelectTransaction(
                                              value,
                                              "allowUpdate"
                                            );
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className="dx-field">
                                      <div className="dx-field-label">
                                        {language.messages.lbAllowDelete}
                                      </div>
                                      <div className="dx-field-value">
                                        <Switch
                                          defaultValue={false}
                                          onValueChanged={(value) => {
                                            updateSelectTransaction(
                                              value,
                                              "allowDelete"
                                            );
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                              </FormGroup>
                              <Row>
                                <Col md={12} xl={8}>
                                  <ButtonGroup className="d-flex">
                                    <Button
                                      disabled={!transaction.enable}
                                      fill
                                      type="button"
                                      font_small
                                      onClick={saveAssign}
                                    >
                                      <i className="fa fa-save"></i>{" "}
                                      {language.messages.btnAssign}
                                    </Button>
                                    <Button
                                      disabled={!transaction.enable}
                                      info
                                      fill
                                      font_small
                                      type="button"
                                      onClick={reloadTransaction}
                                    >
                                      <i className="fa fa-repeat"></i>{" "}
                                      {language.messages.btnRestart}
                                    </Button>
                                    <Button
                                      disabled={!transaction.enable}
                                      danger
                                      font_small
                                      fill
                                      type="button"
                                      onClick={closeTransaction}
                                    >
                                      <i className="fa fa-close"></i>{" "}
                                      {language.messages.btnClose}
                                    </Button>
                                  </ButtonGroup>
                                </Col>
                              </Row>
                            </form>
                          </div>
                        ) : (
                          //#endregion
                          //#region formulario inicial
                          <div>
                            <form autoComplete="off">
                              <Alert className="alert-primary">
                                <strong className="text-danger small">
                                  {language.messages.labelSymbol}
                                </strong>
                                <span className="small">
                                  {" "}
                                  {language.messages.labelSymbolRequired}
                                </span>
                              </Alert>
                              <FormGroup>
                                <LabelTitle
                                  text={language.messages.labelSystem}
                                  requerid={true}
                                />
                                <Select
                                  isDisabled={data.statusSystem}
                                  placeholder={language.messages.lblSingleSelect}
                                  name="system"
                                  options={data.systems}
                                  className="text-uppercase small"
                                  value={{
                                    value: data.system.value,
                                    label: data.system.label,
                                  }}
                                  onChange={(value) => {
                                    updateSelect(value, "system");
                                  }}
                                />
                                <LabelChargeData showloading={data.loadingSystem} />
                                <span className="small text-danger">
                                  {data.system.error}
                                </span>
                              </FormGroup>
                              <FormGroup>
                                <LabelTitle
                                  text={language.messages.labelRoles}
                                  requerid={true}
                                />
                                <Select
                                  isDisabled={
                                    data.statusSystem || es_vacio(data.system.label)
                                  }
                                  placeholder={language.messages.lblSingleSelect}
                                  name="role"
                                  options={data.roles}
                                  className="text-uppercase small text-primary"
                                  value={{
                                    value: data.role.value,
                                    label: data.role.label,
                                  }}
                                  onChange={(value) => {
                                    updateSelect(value, "role");
                                  }}
                                />
                                <LabelChargeData showloading={loadingRole} />
                                <span className="small text-danger">
                                  {data.role.error}
                                </span>
                                <LabelChargeData
                                  showloading={data.loadingSocieties}
                                />
                                {societies.length > 0 ? (
                                  <Row>
                                    <Col className="text-primary small">
                                      {language.messages.society.title_society}:
                                    </Col>
                                  </Row>
                                ) : (
                                  <span></span>
                                )}
                                {societies.map(function (object, i) {
                                  return (
                                    <div className="badge badge-secundary" key={i}>
                                      {object.code}&nbsp;
                                    </div>
                                  );
                                })}
                              </FormGroup>
                              <FormGroup>
                                <LabelTitle
                                  text={language.messages.userRol.searchUser}
                                  requerid={true}
                                />
                                <InputGroup className="mb-3">
                                  <InputGroup.Text id="basic-addon1" className="small">
                                    {provider}
                                  </InputGroup.Text>
                                  <Form.Control
                                    size="sm"
                                    type="text"
                                    name="username"
                                    id="username"
                                    value={data.user.value || ""}
                                    onChange={changeUser}
                                  />

                                </InputGroup>
                                <span className="small text-danger">
                                  {data.user.error}
                                </span>
                                <CheckBox
                                  className="text-primary"
                                  value={enableProvider}
                                  text={language.messages.userRol.msmProvider}
                                  onValueChanged={(e) => {
                                    saveEnableProvider(e.value)
                                  }}
                                />

                              </FormGroup>

                              <div className="options">
                                <div className="caption">
                                  {language.messages.lbSelectedRoleType}
                                </div>
                                <div className="option">
                                  <CheckBox
                                    value={transaction.selected}
                                    text={language.messages.lbAll}
                                    onValueChanged={onSelectedChanged}
                                  />
                                  &nbsp;&nbsp;
                                  <CheckBox
                                    disabled={es_vacio(data.role.label)}
                                    value={transaction.selectedSingle}
                                    text={language.messages.lbMultipleSelect}
                                    onValueChanged={onSelectedChangedSingle}
                                  />
                                </div>
                              </div>
                              <Row>
                                <Col>
                                  <ButtonGroup className="d-flex">
                                    <Button
                                      disabled={!enable}
                                      fill
                                      type="button"
                                      font_small
                                      onClick={saveData}
                                    >
                                      <i className="fa fa-save"></i>{" "}
                                      {language.messages.btnSave}
                                    </Button>
                                    <Button
                                      disabled={!enable}
                                      info
                                      fill
                                      type="button"
                                      font_small
                                      onClick={cleaningForm}
                                    >
                                      <i className="fa fa-magnet"></i>{" "}
                                      {language.messages.btnClear}
                                    </Button>
                                    <Button
                                      disabled={!enable}
                                      warning
                                      fill
                                      type="button"
                                      font_small
                                      onClick={reloadData}
                                    >
                                      <i className="fa fa-repeat"></i>{" "}
                                      {language.messages.btnReload}
                                    </Button>
                                  </ButtonGroup>
                                </Col>
                              </Row>
                            </form>
                          </div>
                        )
                        //#endregion
                      }
                    </Fragment>
                  </Tab>
                  {transaction.visibleDetail && <Tab eventKey="Usuario" title="Usuario">
                    <Fragment>
                      <FormGroup>
                        <LabelTitle
                          text={
                            language.messages.lblName
                          }
                        />
                        <InputGroup className="mb-3">
                          <Form.Control
                            size="sm"
                            type="text"
                            name="name"
                            id="name"
                            value={dataUser.name || ""}
                            onChange={onChangeUser}
                          />
                          
                        </InputGroup>
                        <div className="small text-danger">
                          {dataUser.userError}
                        </div>
                        <LabelTitle
                          text={
                            language.messages.login.label_email
                          }
                        />
                        <InputGroup>
                          <Form.Control
                            size="sm"
                            type="text"
                            name="email"
                            id="email"
                            value={dataUser.email || ""}
                            onChange={onChangeUser}
                          />
                        </InputGroup>
                        <span className="small text-danger">
                          {dataUser.emailError}
                        </span>
                        <Form.Check
                          type="switch"
                          id="status"
                          name="status"
                          className="text-primary"
                          checked={dataUser.status === 1 || dataUser.status === '1' ? true : false}
                          label="Estado"
                          onChange={onChangeUser}
                        />
                      </FormGroup>
                      <Row>
                        <Col>
                          <ButtonGroup className="d-flex">
                            <Button
                              disabled={!enable}
                              fill
                              type="button"
                              font_small
                              onClick={onUpdateUser}
                            >
                              <i className="fa fa-save"></i>{" "}
                              {language.messages.btnSave}
                            </Button>
                            <Button
                              disabled={!transaction.enable}
                              danger
                              font_small
                              fill
                              type="button"
                              onClick={closeTransaction}
                            >
                              <i className="fa fa-close"></i>{" "}
                              {language.messages.btnClose}
                            </Button>
                          </ButtonGroup>
                        </Col>
                      </Row>
                    </Fragment>
                  </Tab>}
                </Tabs>
              }
            />
          </Col>
          <Col sm={12} md={7} lg={7} xl={5}>
            {transaction.visibleDetail ? (
              <Card
                classTitle=" text-uppercase text-capitalize"
                title={language.messages.userRol.titleTransacciones}
                content={
                  <div className="fresh-datatables">
                    <h5 className="small text-uppercase">
                      <span className="text-primary">
                        {language.messages.userRol.user}:{" "}
                      </span>
                      {transaction.detailUsername}
                    </h5>
                    <h5>{transaction.detailRoleName}</h5>
                    <DataGrid
                      id="grid"
                      dataSource={data.userTransactions}
                      showRowLines={true}
                      rowAlternationEnabled={true}
                      showBorders={true}
                    >
                      <Editing
                        refreshMode="full"
                        mode="cell"
                        allowDeleting={true}
                        allowUpdating={true}
                      />
                      <Scrolling mode="virtual" />
                      <Column
                        className="small"
                        dataField="transaction.name"
                        defaultSortOrder="asc"
                        caption={language.messages.userRol.transaction}
                        width="200px"
                      ></Column>
                      <Column
                        dataField="allowView"
                        caption={language.messages.userRol.view}
                        dataType="number"
                        width="70px"
                      >
                        <Lookup
                          dataSource={ALLOW_OPTIONS}
                          valueExpr="Value"
                          displayExpr="Text"
                        />
                      </Column>
                      <Column
                        dataField="allowCreate"
                        caption={language.messages.userRol.add}
                        dataType="number"
                        width="70px"
                      >
                        <Lookup
                          dataSource={ALLOW_OPTIONS}
                          valueExpr="Value"
                          displayExpr="Text"
                        />
                      </Column>
                      <Column
                        dataField="allowUpdate"
                        caption={language.messages.userRol.update}
                        dataType="number"
                        width="80px"
                      >
                        <Lookup
                          dataSource={ALLOW_OPTIONS}
                          valueExpr="Value"
                          displayExpr="Text"
                        />
                      </Column>
                      <Column
                        dataField="allowDelete"
                        caption={language.messages.userRol.delete}
                        dataType="number"
                        width="70px"
                      >
                        <Lookup
                          dataSource={ALLOW_OPTIONS}
                          valueExpr="Value"
                          displayExpr="Text"
                        />
                      </Column>
                    </DataGrid>
                  </div>
                }
              />
            ) : transaction.visible ? (
              <TreeList
                className="small"
                id="treeList"
                dataSource={data.ds}
                showBorders={true}
                keyExpr="id"
                parentIdExpr="parentId"
                hasItemsExpr="hasItems"
                selectedRowKeys={transaction.selectedRowKeys}
                showRowLines={true}
                columnAutoWidth={true}
                wordWrapEnabled={true}
                onSelectionChanged={onSelectionChanged}
              >
                <Selection mode="multiple" />
                <Column
                  dataField="name"
                  caption={language.messages.userRol.rol}
                />
              </TreeList>
            ) : (
              <Card
                classTitle=" text-uppercase text-capitalize"
                icono="fa fa-list"
                title={language.messages.userRol.titleTable}
                content={
                  <div className="fresh-datatables">
                    <DataTable
                      title={
                        <span>
                          <div className="small">{data.system.label}</div>
                          <div className="small-font-60  text-primary">
                            {data.role.label}
                          </div>
                        </span>
                      }
                      subHeader={true}
                      noHeader={data.role.label === ""}
                      data={data.userRoles}
                      columns={data.columns}
                      fixedHeader
                      fixedHeaderScrollHeight="400px"
                      selectableRows
                      dense
                      onSelectedRowsChange={handleSelectRow}
                      highlightOnHover
                      pagination
                      paginationServer
                      paginationComponentOptions={paginationOptions}
                      progressPending={pendingData}
                      progressComponent={<LinearIndeterminate />}
                      contextActions={contextActions}
                      persistTableHead
                      clearSelectedRows={data.showDelete}
                      onChangeRowsPerPage={handlePerRowsChange}
                      onChangePage={handlePageChange}
                      paginationTotalRows={data.totalRows}
                      subHeaderComponent={contextHeader}
                    />
                  </div>
                }
              />
            )}
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
const App = () => (
  <ToastProvider>
    <UserRole />
  </ToastProvider>
);
export default App;
