import React, { Fragment, useState, useEffect, useRef } from 'react';
import { ToastProvider, useToasts } from 'react-toast-notifications';
import { Container, Row, Col } from 'react-bootstrap';
import language from '../../config/es';
import Card from '../../components/Card/Card';
import LabelTitle from '../../components/Label/labelTitle';
import Select from 'react-select';
import LabelChargeData from '../../components/Label/labelChargeData';
import { es_vacio, isGreaterThan } from '../../config/validate';
import LinearIndeterminate from '../../components/LinearIndeterminate/LinearIndeterminate';
import Button from '../../components/CustomButton/CustomButton';
import DataTable from 'react-data-table-component';
import { FormGroup, ButtonGroup, Alert, Form } from 'react-bootstrap';
//redux
import { useDispatch, useSelector } from 'react-redux';
import {
  findSocietiesByIdByCountryId,
  saveSociety,
  updateSociety,
  deleteSociety,
} from '../../redux/society.duck';
import { findCountries } from '../../redux/country.duck';
//Session user
import SessionConfig from '../../config/session_config';

const Society = () => {
  const ref = useRef();
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const [pendingData, setPendingData] = useState(false);
  let [country, setCountry] = useState({
    value: 0,
    label: '',
    error: '',
    auth: '',
  });
  //propiedades ---------------------------------------------------
  const [data, setData] = useState({
    showloadingCountry: false,
    showLoadingTypesPayment: false,
    enable: true,
    name: { value: '', error: '' },
    code: { value: '', error: '' },
    dataRows: [],
    errors: [],
    columns: [],
    countries: [],
    selectedRows: [],
    id: null,
    showDelete: false,
    auth: JSON.parse(sessionStorage.getItem('user')),
    error: '',
    errorCountry: '',
  });
  data.dataRows = useSelector((store) => store.societyReducer.societies);
  data.countries = useSelector((store) => store.countryReducer.countries);
  data.error = useSelector((store) => store.societyReducer.errorSociety);
  data.errorCountry = useSelector((store) => store.countryReducer.noPermitted);
  //ciclo de vida ---------------------------------------------------
  useEffect(() => {
    loadSocieties();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);
  useEffect(() => {
    messageError();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.error, data.errorCountry]);
  useEffect(() => {
    initColumns();
    initData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    ref.current.onRefreshCurrentSession();
  }, [data]);
  //#region handler
  const onChange = (event) => {
    switch (event.target.name) {
      case 'name':
        data.name.value = event.target.value;
        data.name.error = '';
        break;
      case 'code':
        data.code.value = event.target.value;
        data.code.error = '';
        break;
      default:
        break;
    }
    refreshState();
  };
  const updateState = (value, id) => {
    setData({
      ...data,
      [id]: value,
    });
  };
  const refreshState = () => {
    setData({
      ...data,
    });
  };
  const handleSelectRow = (state) => {
    updateState(state.selectedRows, 'selectedRows');
  };

  const handleButtonEdit = (row) => {
    data.id = row.id;
    data.name.value = row.name;
    data.code.value = row.code;

    refreshState();
  };
  const handleDelete = () => {
    disableData();
  };
  //#endregion

  //functions ---------------------------------------------------
  const messageError = () => {
    if (data.error !== '') {
      viewNotification(data.error.error, 'error');
    }
    if (data.errorCountry !== '') {
      viewNotification(data.errorCountry.error, 'error');
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const contextActions = React.useMemo(() => {
    return (
      <Button
        key="delete"
        onClick={handleDelete}
        className="btn btn-danger-up"
        type="button"
        disabled={!data.enable}
      >
        {' '}
        <i className="fa fa-trash"></i>
        {language.messages.btnDisable}
      </Button>
    );
  });
  const initData = async () => {
    loadCountries();
  };
  const initStateDelete = (value) => {
    data.enable = value;
    data.showDelete = value;
    setPendingData(!value);
    refreshState();
  };
  const disableData = async () => {
    let lsDisable = [];
    if (data.selectedRows.length > 0) {
      data.selectedRows.forEach((element) => {
        lsDisable.push({ id: element.id });
      });
      initStateDelete(false);

      let response = await dispatch(
        deleteSociety(lsDisable, data.auth.id.user.username)
      );

      if (response.error !== undefined) {
        initStateDelete(true);
      } else {
        viewNotification(language.messages.msmDisableAll, 'success');
        data.enable = true;
        data.showDelete = true;
        setPendingData(false);
        reloadData();
      }
    }
  };
  const clearError = async () => {
    data.errors = [];
    data.name.error = '';
    data.code.error = '';
    country.error = '';
  };

  const updateSelect = (value, id) => {
    setCountry(value);
  };

  const loadSocieties = async () => {
    if (country.value !== 0) {
      setPendingData(true);
      try {
        data.dataRows = await dispatch(
          findSocietiesByIdByCountryId(
            country.value,
            data.auth.id.user.username
          )
        );
      } catch (err) {
        data.dataRows = [];
      }
      updateState('dataRows', data.dataRows);
      setPendingData(false);
    }
  };

  const loadCountries = async () => {
    await dispatch(findCountries());
    data.showloadingCountry = true;
    updateState(true, 'showloadingCountry');
  };
  const initColumns = () => {
    data.columns = [
      {
        name: language.messages.lblCode,
        sortable: false,
        wrap: true,
        width: '100px',
        cell: (row) => (
          <span>
            <div className="text-primary text-uppercase small">{row.code}</div>
          </span>
        ),
      },
      {
        name: language.messages.lblName,
        sortable: false,
        wrap: true,
        width: '250px',
        cell: (row) => (
          <span>
            <div className=" text-uppercase small">{row.name}</div>
          </span>
        ),
      },
      {
        name: language.messages.country.title_country,
        sortable: false,
        wrap: true,
        width: '200px',
        cell: (row) => (
          <span>
            <div className=" text-uppercase small">{row.country.name}</div>
          </span>
        ),
      },
      {
        name: language.messages.lblFunctions,
        cell: (row) => (
          <button
            className="btn-fill btn btn-xs  btn-primary"
            type="button"
            onClick={() => {
              handleButtonEdit(row);
            }}
            id={row.id}
          >
            <i className="fa fa-pencil"></i>
          </button>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
        width: '10px',
      },
    ];
    refreshState();
  };
  const reloadDataEnable = async () => {
    await loadSocieties();
    refreshState();
  };
  const paginationOptions = {
    rowsPerPageText: language.messages.rowsPerPageText,
    rangeSeparatorText: language.messages.rangeSeparatorText,
    selectAllRowsItem: true,
    selectAllRowsItemText: language.messages.selectAllRowsItemText,
  };
  const validate = () => {
    clearError();

    if (country.value === 0) {
      data.errors.push('country.error');
      country.error = language.messages.error_no_selected;
    }
    if (es_vacio(data.name.value)) {
      data.errors.push('name.error');
      data.name.error = language.messages.warning_required_field;
    }
    if (es_vacio(data.code.value)) {
      data.errors.push('code.error');
      data.code.error = language.messages.warning_required_field;
    }

    if (!isGreaterThan(5, data.code.value.length)) {
      data.errors.push('code.error');
      data.code.error = language.messages.society.errorCode;
    }

    refreshState();
  };
  const clearForm = () => {
    data.name = { value: '', error: '' };
    data.code = { value: '', error: '' };
    data.id = null;
    refreshState();
  };
  const saveData = async () => {
    validate();

    if (data.errors.length === 0) {
      updateState(false, 'enable');
      let json = {
        id: data.id,
        name: data.name.value,
        code: data.code.value,
        status: 1,
        country: { id: country.value },
        userAdmin: sessionStorage.getItem('username'),
        authUser: data.auth.id.user.userId,
        societyId: '',
      };

      let response = '';
      if (data.id === null) {
        response = await dispatch(
          saveSociety(json, data.auth.id.user.username)
        );
      } else {
        response = await dispatch(
          updateSociety(json, data.auth.id.user.username)
        );
      }

      if (response.error === undefined) {
        setPendingData(true);
        await reloadData();

        viewNotification(
          language.messages.msmRegister.replace('{0}', data.name.value),
          'success'
        );
        clearForm();
        setPendingData(false);
      } else {
        updateState(true, 'enable');
      }
    }
  };
  const viewNotification = (msm, action) => {
    addToast(msm, {
      appearance: action,
      autoDismiss: true,
    });
  };
  const cleaningForm = () => {
    clearError();
    clearForm();

    viewNotification(language.messages.success_clean_data, 'success');
  };
  const reloadData = async () => {
    data.enable = false;
    await loadSocieties();
    viewNotification(language.messages.success_reload_data, 'success');
    data.enable = true;
    refreshState();
  };
  return (
    <Fragment>
    <SessionConfig ref={ref}/>
      <br />
      <Container fluid={true}>
        <Row>
          <Col sm={12} md={5} lg={5} xl={4}>
            <Card
              icono=" fa fa-database"
              title={language.messages.society.title_society}
              content={
                <Form autoComplete="off">
                  <Alert className="alert-primary ">
                    <strong className="text-danger small">
                      {language.messages.lblSimbolo}
                    </strong>
                    <span className="small">
                      {' '}
                      {language.messages.lblfieldsRequired}
                    </span>
                  </Alert>

                  <Row>
                    <Col md={12}>
                      <Row>
                        <Col md={12}>
                          <LabelTitle
                            text={language.messages.country.title_country}
                            requerid={true}
                          />
                        </Col>

                        <LabelChargeData
                          showloading={data.showloadingCountry}
                        />
                      </Row>
                      <FormGroup>
                        <Select
                          isDisabled={data.id !== null}
                          placeholder={language.messages.lblSingleSelect}
                          name="country"
                          options={data.countries}
                          className="text-uppercase small"
                          value={{
                            value: country.value,
                            label: country.label,
                          }}
                          onChange={(value) => {
                            updateSelect(value, 'country');
                          }}
                        />
                        <span className="small text-danger">
                          {country.error}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={8} sm={12}>
                      <FormGroup>
                        <LabelTitle
                          text={language.messages.lblName}
                          requerid={true}
                        />
                        <Form.Control
                          size="sm"
                          type="text"
                          name="name"
                          id="name"
                          value={data.name.value}
                          onChange={onChange}
                        />
                        <span className="small text-danger">
                          {data.name.error}
                        </span>
                      </FormGroup>
                    </Col>
                    <Col md={4} sm={12}>
                      <FormGroup>
                        <LabelTitle
                          text={language.messages.lblCode}
                          requerid={true}
                        />
                        <Form.Control
                          size="sm"
                          type="text"
                          name="code"
                          id="code"
                          value={data.code.value}
                          onChange={onChange}
                        />
                        <span className="small text-danger">
                          {data.code.error}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>

                  <LabelChargeData
                    label={language.messages.msmRegisterDataLoading}
                    showloading={data.enable}
                  />
                  <Row>
                    <Col>
                      <ButtonGroup className="d-flex">
                        <Button
                          disabled={!data.enable}
                          primary
                          font_small
                          fill
                          type="button"
                          onClick={saveData}
                        >
                          <i className="fa fa-save"></i>{' '}
                          {data.id === null
                            ? language.messages.btnSave
                            : language.messages.btnEdit}
                        </Button>
                        <Button
                          disabled={!data.enable}
                          info
                          fill
                          font_small
                          type="button"
                          onClick={cleaningForm}
                        >
                          <i className="fa fa-magnet"></i>{' '}
                          {language.messages.btnClear}
                        </Button>
                        <Button
                          disabled={!data.enable}
                          warning
                          fill
                          font_small
                          type="button"
                          onClick={reloadDataEnable}
                        >
                          <i className="fa fa-refresh"></i>{' '}
                          {language.messages.btnReload}
                        </Button>
                      </ButtonGroup>
                    </Col>
                  </Row>
                </Form>
              }
            />
          </Col>
          <Col sm={12} md={7} lg={7} xl={8}>
            <Card
              icono=" fa fa-list"
              title={language.messages.society.title_society_register}
              content={
                <div className="fresh-datatables">
                  <DataTable
                    title={country.label}
                    noHeader={country.value === 0}
                    data={data.dataRows}
                    columns={data.columns}
                    fixedHeader
                    fixedHeaderScrollHeight="500px"
                    dense
                    selectableRows
                    onSelectedRowsChange={handleSelectRow}
                    highlightOnHover
                    pagination
                    paginationComponentOptions={paginationOptions}
                    progressPending={pendingData}
                    progressComponent={<LinearIndeterminate />}
                    persistTableHead
                    contextActions={contextActions}
                    clearSelectedRows={data.showDelete}
                  />
                </div>
              }
            />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

const App = () => (
  <ToastProvider>
    <Society />
  </ToastProvider>
);
export default App;
