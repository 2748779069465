import config from "../config/application";
import { getService, postBodyService } from "./base.service";
import permission from '../api/permissions.service';
import dictionary from '../config/dictionary';

const getAll = 'find_all';
const create = 'create';
const update = 'update';
const deleteLs = 'delete';

class SystemService {
    getAllSystems() {
        const url = `${config.data.configuration.system}${getAll}`;
        return getService(url);
    }
    async saveSystem(system, username) {
        let url;
        let valid = await permission.validPermission(
            process.env.REACT_APP_TRANSACTION_SYTEM,
            username,
            process.env.REACT_APP_SYSTEM_CODE,
            system.id !== 0 ?
                dictionary.configApp.action.edit :
                dictionary.configApp.action.add);

        if (valid.response === undefined && valid.data.allow === true) {
            if (system.id !== 0) {
                const id = system.id;
                url = `${config.data.configuration.system}${update}?id=${id}`;
            } else {
                system.id = null;
                url = `${config.data.configuration.system}${create}`
            }
            return postBodyService(url, system);
        } else {
            return valid;
        }
    }
    async disableSystem(lsSystems, username) {
        let valid = await permission.validPermission(
            process.env.REACT_APP_TRANSACTION_SYTEM,
            username,
            process.env.REACT_APP_SYSTEM_CODE, dictionary.configApp.action.delete);
        if (valid.response === undefined && valid.data.allow === true) {
            const url = `${config.data.configuration.system}${deleteLs}`;
            return postBodyService(url, lsSystems);
        } else {
            return valid;
        }
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new SystemService();