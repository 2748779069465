import {getService,postBodyService} from "./base.service";
import config from "../config/application";
import permission from "./permissions.service";
import dictionary from "../config/dictionary";

//url
const readMenu = '/readMenu/';
const readRol = '/read/';
const readFolderRol = '/read-folder/';
const deleteMenu = '/deleteMenu';
const deleteUserTransaction = '/delete';
const updateRol = '/update/';
const createRol = '/create/';
const readTreeViewSystem = '/read_treeview_system/';
const readTreeView = '/read_treeview/';
const readAllFindByUser = '/find_all_by_user/';

class RolService {
    async getTreeListBySystem(system,username) {
        let valid = await permission.validPermission(
            process.env.REACT_APP_TRANSACTION_ASIG_TRANSACTION,
            username,
            process.env.REACT_APP_SYSTEM_CODE, dictionary.configApp.action.list);
        if (valid.response === undefined && valid.data.allow === true) {
            const url = `${config.data.security.assignTransaction.url}${readTreeViewSystem}${system}`;
            return getService(url);
        } else {
            return valid;
        }
    }
    async getTreeList(role,username) {
        let valid = await permission.validPermission(
            process.env.REACT_APP_TRANSACTION_ASIG_TRANSACTION,
            username,
            process.env.REACT_APP_SYSTEM_CODE, dictionary.configApp.action.list);
        if (valid.response === undefined && valid.data.allow === true) {
            const url = `${config.data.security.assignTransaction.url}${readTreeView}${role}`;
            return getService(url);
        } else {
            return valid;
        }
    }
    async findAllByUserId(user,system,username) {
        let valid = await permission.validPermission(
            process.env.REACT_APP_TRANSACTION_ASIG_TRANSACTION,
            username,
            process.env.REACT_APP_SYSTEM_CODE, dictionary.configApp.action.list);
        if (valid.response === undefined && valid.data.allow === true) {
            const url = `${config.data.security.assignTransaction.url}${readAllFindByUser}${user}/${system}`;
            return getService(url);
        } else {
            return valid;
        }
    }
    async findTransactionsNotAssigned(rol,level,username) {
        let valid = await permission.validPermission(
            process.env.REACT_APP_TRANSACTION_ASIG_TRANSACTION,
            username,
            process.env.REACT_APP_SYSTEM_CODE, dictionary.configApp.action.list);
        if (valid.response === undefined && valid.data.allow === true) {
            level = level === ''? '0' : level;
            const url = `${config.data.security.assignTransaction.url}${readRol}${rol}/${level}`;
            return getService(url);
        } else {
            return valid;
        }
    }
    async findTransactionsNotAssignedFolder(rol, level, username) {
        let valid = await permission.validPermission(
            process.env.REACT_APP_TRANSACTION_ASIG_TRANSACTION,
            username,
            process.env.REACT_APP_SYSTEM_CODE, dictionary.configApp.action.list);
        if (valid.response === undefined && valid.data.allow === true) {
            level = level === '' ? '0' : level;
            const url = `${config.data.security.assignTransaction.url}${readFolderRol}${rol}/${level}`;
            return getService(url);
        } else {
            return valid;
        }
    }
    async findNotAssignedUserRoleMenu(role_user,username) {
        let valid = await permission.validPermission(
            process.env.REACT_APP_TRANSACTION_ASIG_TRANSACTION,
            username,
            process.env.REACT_APP_SYSTEM_CODE, dictionary.configApp.action.list);
        if (valid.response === undefined && valid.data.allow === true) {
            const url = `${config.data.security.assignTransaction.url}${readMenu}${role_user}`;
            return getService(url);
        } else {
            return valid;
        }
    }
    async deleteTransaction(menu_id,username){
        let valid = await permission.validPermission(
            process.env.REACT_APP_TRANSACTION_ASIG_TRANSACTION,
            username,
            process.env.REACT_APP_SYSTEM_CODE, dictionary.configApp.action.delete);
        if (valid.response === undefined && valid.data.allow === true) {
            const url = `${config.data.security.assignTransaction.url}${deleteMenu}/${menu_id}`;
            return postBodyService(url);
        } else {
            return valid;
        }
    }
    async deleteUserTransaction(id,username){
        let valid = await permission.validPermission(
            process.env.REACT_APP_TRANSACTION_ASIG_TRANSACTION,
            username,
            process.env.REACT_APP_SYSTEM_CODE, dictionary.configApp.action.delete);
        if (valid.response === undefined && valid.data.allow === true) {
            const url = `${config.data.security.assignTransaction.url}${deleteUserTransaction}/${id}`;
            return getService(url);
        } else {
            return valid;
        }
    }
    async getUpdateRol(json,username) {
        let valid = await permission.validPermission(
            process.env.REACT_APP_TRANSACTION_ASIG_TRANSACTION,
            username,
            process.env.REACT_APP_SYSTEM_CODE, dictionary.configApp.action.edit);
        if (valid.response === undefined && valid.data.allow === true) {
            const url = `${config.data.security.assignTransaction.url}${updateRol}`;
            return postBodyService(url,json);
        } else {
            return valid;
        }
    }
    async getCreateRol(rol,username) {
        let valid = await permission.validPermission(
            process.env.REACT_APP_TRANSACTION_ASIG_TRANSACTION,
            username,
            process.env.REACT_APP_SYSTEM_CODE, dictionary.configApp.action.add);
        if (valid.response === undefined && valid.data.allow === true) {
            const url = `${config.data.security.assignTransaction.url}${createRol}${rol}`;
            return getService(url);
        } else {
            return valid;
        }
    }

}
// eslint-disable-next-line import/no-anonymous-default-export
export default new RolService();