/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import language from '../../config/es';
import { I18n, Auth } from 'aws-amplify';
import { useDispatch } from 'react-redux';
import { logout } from '../../redux/login.duck';
import { Fragment } from 'react';
import { ToastProvider, useToasts } from 'react-toast-notifications';
//styles
import { Row, Col } from 'react-bootstrap';
import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close'

I18n.setLanguage('es');

const dict = {
  es: { 'Sign Out': 'Salir' }
};

I18n.putVocabularies(dict);

const AdminNavbarLinks = () => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const viewNotification = (msm, action) => {
    addToast(msm, {
      appearance: action,
      autoDismiss: true,
    });
  };

  const onClickLogoutSessionUser = () => {
    viewNotification(language.messages.await_logout_session, 'warning');
    let json = {
      username: sessionStorage.getItem('username'),
      codeSystem: process.env.REACT_APP_SYSTEM_CODE,
      registerLog: true,
    };
    dispatch(logout(json)).then(() => {
      //console.log('logout service service');
      logoutSessionAD();
    }).catch((error) => {
      console.log(error);
      logoutSessionAD();
    });
  };

  const logoutSessionAD = () => {
    try {
      //console.log('logout service service AD');
      Auth.signOut();
      removeSession();
    } catch(error) {
      console.log(error);
      removeSession();
    }
  }
  const removeSession = () => {
    window.sessionStorage.removeItem('username');
    window.sessionStorage.removeItem('name');
    window.sessionStorage.removeItem('user');
    window.sessionStorage.removeItem('menu');
    window.sessionStorage.removeItem('token');
    window.sessionStorage.removeItem('node');
    window.sessionStorage.removeItem('menuId');
    window.location.href = '/login';
  }

  return (
    <Fragment>
      <Row className="justify-content-center">
        <Col>
          <Button onClick={onClickLogoutSessionUser} variant="contained" color="secondary" startIcon={<CloseIcon />}>
            <span className=" small">{language.messages.title_system_close}</span>
          </Button>
        </Col>
      </Row>
    </Fragment>
  );
};
const App = () => (
  <ToastProvider>
    <AdminNavbarLinks />
  </ToastProvider>
);
export default App;
