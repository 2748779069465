import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Footer.css';
import language from '../../config/es';
import logo from '../../assets/img/logo_auth.png';
import logoPil from '../../assets/img/Pil Andina-09.png';

class FooterDash extends Component {
  render() {
    return (
      <footer className="footer--pin">
        <Container fluid>
          <Row className="footer-custom footer-dash align-items-center text-right">
            <Col xs={12} md={5} lg={6} xl={7}></Col>
            <Col xs={12} md="auto" xl={5}>
              <span>
                {' '}
                {language.messages.footer.develop}
                <img src={logoPil} alt="react-logo" className="header-logo" />
                <img src={logo} alt="react-logo" className="header-logo" />
              </span>
            </Col>
          </Row>
        </Container>
      </footer>
    );
  }
}

export default FooterDash;
