import dictionary from '../config/dictionary';
const data = {
    configuration: {
        society: { url: process.env.REACT_APP_URL_SOCIETY },
        country: { url: process.env.REACT_APP_URL_COUNTRY},
        system: process.env.REACT_APP_URL_SYSTEM,
        type: { url: process.env.REACT_APP_URL_TYPE }

    },
    security: {
        login:{url:process.env.REACT_APP_URL_LOGIN},
        user: { url:  process.env.REACT_APP_URL_USER},
        userRole: process.env.REACT_APP_URL_USER_ROLE,
        menu: { url: process.env.REACT_APP_URL_MENU },
        transaction: { url:  process.env.REACT_APP_URL_TRANSACTION },
        assignTransaction: { url:  process.env.REACT_APP_URL_ASSIGN_TRANSACTION},
        userSociety: { url:  process.env.REACT_APP_URL_USER_SOCIETY},
        permission: { url: process.env.REACT_APP_URL_PERMISSIONS_ALLOW },
    },

    types: dictionary.configApp.concept,
    typeCode: dictionary.configApp.type

};


// eslint-disable-next-line import/no-anonymous-default-export
export default {
    data
};