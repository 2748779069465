import service from '../api/country.service';
//constants
const data = {
    countries: [],
    countryAdd: null,
    responseCountry: null,
    noPermitted: ''
}
//types
const LIST_COUNTRY = 'LIST_COUNTRY';
const LIST_COUNTRY_ERROR = 'LIST_COUNTRY_ERROR';
const ADD_COUNTRY = 'ADD_COUNTRY';
const UPDATE_COUNTRY = 'UPDATE_COUNTRY';
const DELETE_COUNTRY = 'DELETE_COUNTRY';

//reducer
export default function countryReducer(state = data, action) {
    switch (action.type) {
        case LIST_COUNTRY:
            return { ...state, countries: action.payload };
        case ADD_COUNTRY:
            return { ...state, countryAdd: action.payload };
        case LIST_COUNTRY_ERROR:
            return { ...state, noPermitted: action.payload };
        default:
            return { ...state };
    }
}

export const findCountries = () => async (dispatch) => {
    const response = await service.getCountries().then((r) => {
        return r.data !== undefined ? r.data : r.response.data;
    });
    let data = [];

    if (response.code !== undefined) {
        dispatch({
            type: LIST_COUNTRY_ERROR,
            payload: response
        });
        dispatch({
            type: LIST_COUNTRY,
            payload: []
        });
    } else {
        response.forEach((element) => {
            data.push({
                value: element.id,
                label: element.name.toUpperCase()+' | '+element.code,
                code: element.code
            });
        });
        dispatch({
            type: LIST_COUNTRY_ERROR,
            payload: ''
        });
        dispatch({
            type: LIST_COUNTRY,
            payload: data
        });
    }


}
export const findRawCountries = (username) => async (dispatch) => {
    const response = await service.getRawCountries(username).then((r) => {
        return r.data !== undefined ? r.data : r.response.data;
    });

    if (response.code !== undefined) {
        dispatch({
            type: LIST_COUNTRY_ERROR,
            payload: response
        });
        dispatch({
            type: LIST_COUNTRY,
            payload: []
        });
    } else {

        dispatch({
            type: LIST_COUNTRY_ERROR,
            payload: ''
        });
        dispatch({
            type: LIST_COUNTRY,
            payload: response
        });
    }

    return response;
}

export const saveCountry = (countryData, username) => async (dispatch) => {
    const response = await service.saveCountry(countryData, username).then((r) => {
        return r.data !== undefined ? r.data : r.response.data
    });
    if (response.error !== undefined) {
        dispatch({
            type: LIST_COUNTRY_ERROR,
            payload: response
        });
        dispatch({
            type: ADD_COUNTRY,
            payload: ''
        });
    } else {
        dispatch({
            type: LIST_COUNTRY_ERROR,
            payload: ''
        });
        dispatch({
            type: ADD_COUNTRY,
            payload: response
        });
    }

    return response;
}
export const updateCountry = (countryData, username) => async (dispatch) => {
    const response = await service.updateCountry(countryData, username).then((r) => {
        return r.data !== undefined ? r.data : r.response.data
    });

    if (response.error !== undefined) {
        dispatch({
            type: LIST_COUNTRY_ERROR,
            payload: response
        });
        dispatch({
            type: UPDATE_COUNTRY,
            payload: ''
        });
    } else {
        dispatch({
            type: LIST_COUNTRY_ERROR,
            payload: ''
        });
        dispatch({
            type: UPDATE_COUNTRY,
            payload: response
        });
    }


    return response;
}

export const deleteCountry = (countries, username) => async (dispatch) => {
    const response = await service.deleteCountry(countries, username).then((r) => {
        return r.data !== undefined ? r.data : r.response.data
    });

    if (response.error !== undefined) {
        dispatch({
            type: LIST_COUNTRY_ERROR,
            payload: response
        });
        dispatch({
            type: DELETE_COUNTRY,
            payload: ''
        });
    } else {
        dispatch({
            type: LIST_COUNTRY_ERROR,
            payload: ''
        });
        dispatch({
            type: DELETE_COUNTRY,
            payload: response
        });
    }

    return response;
}