/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useEffect, useState } from 'react';
import image from '../../assets/img/sidebar-3.jpg';
import language from '../../config/es';
import { Collapse } from 'react-bootstrap';
import 'perfect-scrollbar/dist/css/perfect-scrollbar.min.css';
import { Auth } from 'aws-amplify';
import { useDispatch } from 'react-redux';
import { logout } from '../../redux/login.duck';

const TopMenu = () => {
  //#region propiedades
  const dispatch = useDispatch();
  // eslint-disable-next-line no-unused-vars
  const [usernameAd, setUsernameAd] = useState('Usuario');
  const [data] = useState({
    user: JSON.parse(sessionStorage.getItem('user')),
    menu:
      sessionStorage.getItem('menu') === 'undefined'
        ? []
        : JSON.parse(sessionStorage.getItem('menu')),
    nodes: [],
  });
  const bgImage = { backgroundImage: 'url(' + image + ')' };
  //#endregion propiedades

  useEffect(() => {
    getCurrentSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //#region funciones
  const getCurrentSession = () => {
    Auth.currentSession()
      .then((data) => {
        setUsernameAd(
          data.idToken.payload.given_name +
            ' ' +
            data.idToken.payload.family_name
        );
      })
      .catch((error) => {
        console.log(error);
        logoutSession();
      });
  };
  const logoutSession = () => {
    let json = {
      username: sessionStorage.getItem('username'),
      codeSystem: process.env.REACT_APP_SYSTEM_CODE,
      registerLog: true,
    };
    dispatch(logout(json))
      .then(() => {
        //console.log('logout session service');
        logoutSessionAD();
      })
      .catch((error) => {
        console.log(error);
        logoutSessionAD();
      });
  };
  const logoutSessionAD = () => {
    try {
      //console.log('logout session service AD');
      Auth.signOut();
      removeSession();
    } catch (error) {
      console.log(error);
      removeSession();
    }
  };
  const removeSession = () => {
    window.sessionStorage.removeItem('username');
    window.sessionStorage.removeItem('name');
    window.sessionStorage.removeItem('user');
    window.sessionStorage.removeItem('menu');
    window.sessionStorage.removeItem('token');
    window.sessionStorage.removeItem('node');
    window.sessionStorage.removeItem('menuId');
    window.location.href = '/login';
  };

  const buildIcon = (key) => {
    if (key.toLowerCase().includes('config')) {
      return 'pe-7s-config';
    }
    if (key.toLowerCase().includes('seguridad')) {
      return 'pe-7s-door-lock';
    }
    if (key.toLowerCase().includes('entidad')) {
      return 'pe-7s-coffee';
    }
  };
  //#endregion funciones

  return (
    <Fragment>
      <div className="sidebar" data-color="black" data-image={image}>
        <div className="sidebar-background" style={bgImage}></div>
        <div className="sidebar-wrapper ps ps--theme_default ps--active-y">
          <div className="user">
            <div className="photo">
              <i className="pe-7s-menu font-30"></i>
            </div>
            <div className="info">
              <a>
                <span className="small">
                  <span className="small text-uppercase">
                    {language.messages.title_system_min}
                  </span>
                </span>
              </a>
            </div>
          </div>

          <ul className="nav">
            <li>
              <a
                className="nav-link active"
                aria-current="page"
                href="home"
              >
                <i className="pe-7s-home"></i>
                <p>{language.messages.title_home}</p>
              </a>
            </li>
            {data.menu.map((node) => {
              // eslint-disable-next-line react-hooks/rules-of-hooks
              const [collapse, setCollapse] = useState(false);
              return (
                <li
                  key={node.menuId}
                  className={
                    node.menuId === parseInt(sessionStorage.getItem('menuId'))
                      ? 'active'
                      : ''
                  }
                >
                  <a
                    onClick={() => {
                      setCollapse(!collapse);
                      sessionStorage.setItem('menuId', node.menuId);
                    }}
                  >
                    <i className={buildIcon(node.name)}></i>
                    <p className=" text-uppercase">
                      {node.name}
                      <b className="caret"></b>
                    </p>
                  </a>
                  <Collapse
                    in={
                      node.menuId === parseInt(sessionStorage.getItem('menuId'))
                    }
                  >
                    <ul className="nav">
                      {node.Nodes.map((endNode) => {
                        return (
                          <li
                            key={endNode.menuId}
                            className={
                              endNode.menuId ===
                              parseInt(sessionStorage.getItem('node'))
                                ? 'active'
                                : ''
                            }
                            onClick={() => {
                              sessionStorage.setItem('node', endNode.menuId);
                            }}
                          >
                            <a
                              className="nav-link active"
                              aria-current="page"
                              href={endNode.transaction.route}
                            >
                              <span className="sidebar-mini submenu">
                                {endNode?.name?.charAt(0).toUpperCase()}
                                {endNode?.name?.charAt(1).toUpperCase()}
                              </span>
                              <span className="sidebar-normal small text-uppercase  font-size-11">
                                {endNode.name}
                              </span>
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </Collapse>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </Fragment>
  );
};
const App = () => <TopMenu />;
export default App;
