import Pages from 'containers/Pages';
import Dash from 'containers/Dash';
import routeDash from './dash';

let appRoutes = [
  { component: Pages, path: '/', exact: true, name: 'Pages' },
  { component: Pages, path: '*', exact: false, name: 'Pages' },
  { component: Pages, path: '/login', exact: false, name: 'Pages' }
];
routeDash.forEach((item) => {
  appRoutes.unshift({
    component: Dash,
    path: item.path,
    exact: item.exact,
    name: Dash,
  });
});
export default appRoutes;
