import { getService, postBodyService } from "./base.service";
import config from "../config/application";
import permission from '../api/permissions.service';
import dictionary from '../config/dictionary';

//url
const createType = 'add';
const deleteType = 'delete';
const updateType = 'update';
const listConcept = 'find_all_concept';
const findByIdConcept = 'find_by_concept';
const saveConceptUrl = 'add_concept';

class TypeService {
    async getConcepts(username) {
        let valid = await permission.validPermission(
            process.env.REACT_APP_TRANSACTION_TYPE,
            username,
            process.env.REACT_APP_SYSTEM_CODE, dictionary.configApp.action.list);

        if (valid.response === undefined && valid.data.allow === true) {
            const url = `${config.data.configuration.type.url}${listConcept}`;
            return getService(url);
        } else {
            return valid;
        }
    }
    getTypeByConcept(concept) {
        const url = `${config.data.configuration.type.url}${findByIdConcept}/${concept}`;
        return getService(url);
    }
    async getCreateType(type, username) {
        let valid = await permission.validPermission(
            process.env.REACT_APP_TRANSACTION_TYPE,
            username,
            process.env.REACT_APP_SYSTEM_CODE, dictionary.configApp.action.add);

        if (valid.response === undefined && valid.data.allow === true) {
            const url = `${config.data.configuration.type.url}${createType}`;
            return postBodyService(url, type);
        } else {
            return valid;
        }
    }

    async getUpdateType(type, username) {
        let valid = await permission.validPermission(
            process.env.REACT_APP_TRANSACTION_TYPE,
            username,
            process.env.REACT_APP_SYSTEM_CODE, dictionary.configApp.action.edit);

        if (valid.response === undefined && valid.data.allow === true) {
            const url = `${config.data.configuration.type.url}${updateType}`;
            return postBodyService(url, type);
        } else {
            return valid;
        }
    }
    async getDeleteType(lsData, username) {
        let valid = await permission.validPermission(
            process.env.REACT_APP_TRANSACTION_TYPE,
            username,
            process.env.REACT_APP_SYSTEM_CODE, dictionary.configApp.action.delete);

        if (valid.response === undefined && valid.data.allow === true) {
            const url = `${config.data.configuration.type.url}${deleteType}`;
            return postBodyService(url, lsData);
        } else {
            return valid;
        }
    }
    async saveConcept(concept, username) {
        let valid = await permission.validPermission(
            process.env.REACT_APP_TRANSACTION_TYPE,
            username,
            process.env.REACT_APP_SYSTEM_CODE, dictionary.configApp.action.add);
        if (valid.response === undefined && valid.data.allow === true) {
            const url = `${config.data.configuration.type.url}${saveConceptUrl}`;
            return postBodyService(url, concept);
        } else {
            return valid;
        }
    }



}
// eslint-disable-next-line import/no-anonymous-default-export
export default new TypeService();