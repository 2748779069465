import { applyMiddleware, createStore, compose } from 'redux';
import reducer from '../reducers';
import thunk from 'redux-thunk';

const rrfConfig = {
    userProfile: 'users',
    useFirestoreForProfile: true,
    enableLogging: false,
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // development

export const store = createStore(
    reducer, {},
    composeEnhancers(
        applyMiddleware(thunk.withExtraArgument())
    ),
);

export const rrfProps = {
    config: rrfConfig,
    dispatch: store.dispatch
};