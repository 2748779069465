import user from '../api/user.service';
//constants
const dataUser = {
    users: [],
    userAdd:null,
    responseUser: null
}
//types
const LIST_USER = 'LIST_USER';
const LIST_USER_USERNAME = 'LIST_USER_USERNAME';
const ADD_USER = 'ADD_USER';
const UPDATE_USER = 'UPDATE_USER';

//reducer
export default function userReducer(state = dataUser, action) {
    switch (action.type) {
        case LIST_USER_USERNAME:
            return { ...state, users: action.payload };
        case ADD_USER:
            return { ...state, userAdd: action.payload };
        case UPDATE_USER:
            return { ...state };
        default:
            return { ...state };
    }
}

export const findAll = () => async (dispatch) => {
    const response = await user.getUsers().then((r) => { return r.data });
    dispatch({
        type: LIST_USER,
        payload:response
    });
}
export const findAllUsers = () => async (dispatch) => {
    const response = await user.getUsers().then((r) => { return r.data });
    let data = [];
    if (response != null) {
        response.forEach((element) => {
            data.push({
                value: element.id,
                label: element.username.toUpperCase()
            });
        });
    }
    dispatch({
        type: LIST_USER_USERNAME,
        payload: data
    });
}
export const findByUsername = (userData) => async (dispatch) => {
    const response = await user.getUserByUsername(userData).then((r) => { return r.data });
    dispatch({
        type: LIST_USER,
        payload:response
    });
}

export const logout = (userData) => async(dispatch) => {
    const response = await user.logout(userData).then((r) => { return r.data });
    dispatch({
        type: ADD_USER,
        payload:response
    });
    return response;
}

export const updateUser = (userData) => async(dispatch)=> {
    const response = await user.updateUser(userData).then((r) => { return r.data });
     
    dispatch({
        type: UPDATE_USER,
        payload:response
    });
    return response;
}