//#region IMPORTS
import service from '../api/userSociety.service';
import language from '../config/es';
//#endregion IMPORTS

//#region CONSTANTS

const initialData = {
    userSocieties: [],
    userSocietyAdd:null,
    noPermitted: '',
    users: [],
}
//#endregion CONSTANTS

//#region TYPES

const LOAD_USER_SOCIETIES = 'LOAD_USER_SOCIETIES';
const LIST_USER_SOCIETY_ERROR = 'LIST_USER_SOCIETY_ERROR';
const ADD_USER_SOCIETY = 'ADD_USER_SOCIETY';
const DELETE_USER_SOCIETY= 'DELETE_USER_SOCIETY';
const LIST_USERS = 'LIST_USERS';
//#endregion TYPES

//#region REDUCER

export default function userSocietyReducer(state = initialData, action) {
    switch (action.type) {
        case LOAD_USER_SOCIETIES:
            return { ...state, userSocieties: action.payload };
        case ADD_USER_SOCIETY:
            return { ...state, userSocietyAdd: action.payload };
        case LIST_USER_SOCIETY_ERROR:
            return { ...state, noPermitted: action.payload };
        case LIST_USERS:
            return { ...state, users: action.payload };
        default:
            return { ...state };
    }
}
//#endregion REDUCER

//#region ACTIONS

export const findAll = (user,auth) => async (dispatch) => {
    const response = await service.getAllUserSocieties(user, auth).then((r) => { return r });
    dispatch({
        type: LOAD_USER_SOCIETIES,
        payload:response
    });
    return response;
}
//control=[disabled is 0][enabled is 1]
export const findAllUserSocieties = (auth,control) => async (dispatch) => {
    const res = await service.getAllUserSocieties(auth,control).then((r) => { return r});
    let data = [];
    if (res != null) {
        if (res.status === 200) {
            res.data.forEach((element) => {
                data.push({
                    value: element.id,
                    label: element.name.toUpperCase()
                });
            });
        } else {
            switch (res.response.status) {
                case 403:
                    dispatch({
                        type: LIST_USER_SOCIETY_ERROR,
                        payload: res.response.data
                    });
                    break;
                default:
                    dispatch({
                        type: LIST_USER_SOCIETY_ERROR,
                        payload: language.messages.msmErrorExist
                    });
                    break;
                }
        }
    }
    dispatch({
        type: LOAD_USER_SOCIETIES,
        payload: data
    });
}
export const saveUserSociety=(userSocietyData, auth)=>async(dispatch)=>{
    const response = await service.saveUserSociety(userSocietyData, auth).then((r) => { return r });
    dispatch({
        type: ADD_USER_SOCIETY,
        payload:response
    });
    return response;
}

export const deleteUserSociety=(lsUserSocieties, auth)=>async(dispatch)=>{
    const response = await service.deleteUserSociety(lsUserSocieties, auth).then((r) => { return r });

    dispatch({
        type: DELETE_USER_SOCIETY,
        payload:response
    });
    return response;
}

    export const findByUsername = (username) => async (dispatch) => {
    const response = await service.getFindByUsername(username).then((r) => { return r.data });
        let data = [];
        if (response != null) {
            response.forEach((element) => {
                data.push({
                    value: element.id,
                    label: element.username.toUpperCase(),
                });
            });
        }
        dispatch({
            type: LIST_USERS,
            payload: data
        });
        return data;
}
//#endregion ACTIONS
