import service from '../api/type.service';

//constants
const dataType = {
    types: [],
    concepts: [],
    createType: [],
    deleteType: [],
    updateType: [],
    errorType: '',
    newConcept: null
}
//types
const LIST_TYPE = 'LIST_TYPE'
const LIST_CONCEPT = 'LIST_CONCEPT'
const CREATE_TYPE = 'CREATE_TYPE'
const CREATE_CONCEPT = 'CREATE_CONCEPT'
const DELETE_TYPE = 'DELETE_TYPE'
const UPDATE_TYPE = 'UPDATE_TYPE'
const ERROR_TYPE = 'ERROR_TYPE'

//reducer
export default function typeReducer(state = dataType, action) {
    switch (action.type) {
        case LIST_TYPE:
            return { ...state, types: action.payload };
        case LIST_CONCEPT:
            return { ...state, concepts: action.payload };
        case CREATE_TYPE:
            return { ...state, createType: action.payload };
        case DELETE_TYPE:
            return { ...state, deleteType: action.payload };
        case UPDATE_TYPE:
            return { ...state, updateType: action.payload };
        case ERROR_TYPE:
            return { ...state, errorType: action.payload };
        case CREATE_CONCEPT:
            return { ...state, newConcept: action.payload };
        default:
            return { ...state };
    }
}
export const findByConcept = (concept) => async (dispatch) => {
    const response = await service.getTypeByConcept(concept).then((r) => {
        return r.data !== undefined ? r.data : r.response.data
    });
    let data = [];

    if (response.code !== undefined) {
        dispatch({
            type: ERROR_TYPE,
            payload: response
        });
        dispatch({
            type: LIST_TYPE,
            payload: []
        });
    } else {

        response.forEach((element) => {
            data.push({
                value: element.Id,
                label: element.name.toUpperCase(),
            });
        });
        dispatch({
            type: ERROR_TYPE,
            payload: ''
        });
        dispatch({
            type: LIST_TYPE,
            payload: data
        });
    }


    return data;
}

export const findTypeByConcept = (concept) => async (dispatch) => {
    const response = await service.getTypeByConcept(concept).then((r) => {
        return r.data !== undefined ? r.data : r.response.data
    });
    if (response.code !== undefined) {
        dispatch({
            type: ERROR_TYPE,
            payload: response
        });
        dispatch({
            type: LIST_TYPE,
            payload: []
        });
    } else {

        dispatch({
            type: ERROR_TYPE,
            payload: ''
        });
        dispatch({
            type: LIST_TYPE,
            payload: response
        });
    }

}

export const findAllConcepts = (username) => async (dispatch) => {
    let data = [];
    let response = await service.getConcepts(username).then((r) => {
        return r.data !== undefined ? r.data : r.response.data
    });
    if (response.code !== undefined) {
        dispatch({
            type: ERROR_TYPE,
            payload: response
        });
        dispatch({
            type: LIST_CONCEPT,
            payload: []
        });
    } else {
        response.forEach((element) => {
            data.push({
                value: element.id,
                label: element.name.toUpperCase(),
            });
        });
        dispatch({
            type: ERROR_TYPE,
            payload: ''
        });
        dispatch({
            type: LIST_CONCEPT,
            payload: data
        });
    }


    dispatch({
        type: LIST_CONCEPT,
        payload: data
    });
    return data;
}

export const createType = (type, username) => async (dispatch) => {
    const response = await service.getCreateType(type, username).then((r) => {
        return r.data !== undefined ? r.data : r.response.data
    });

    if (response.error !== undefined) {
        dispatch({
            type: ERROR_TYPE,
            payload: response
        });
        dispatch({
            type: CREATE_TYPE,
            payload: ''
        });
    } else {
        dispatch({
            type: ERROR_TYPE,
            payload: ''
        });
        dispatch({
            type: CREATE_TYPE,
            payload: response
        });
    }
    return response;
}

export const deleteType = (lsData, username) => async (dispatch) => {
    const response = await service.getDeleteType(lsData, username).then((r) => {
        return r.data !== undefined ? r.data : r.response.data
    });
    if (response.error !== undefined) {
        dispatch({
            type: ERROR_TYPE,
            payload: response
        });
        dispatch({
            type: DELETE_TYPE,
            payload: []
        });
    } else {
        dispatch({
            type: ERROR_TYPE,
            payload: ''
        });
        dispatch({
            type: DELETE_TYPE,
            payload: response
        });
    }

    return response;
}

export const updateType = (type, username) => async (dispatch) => {
    const response = await service.getUpdateType(type, username).then((r) => {
        return r.data !== undefined ? r.data : r.response.data
    });
    if (response.error !== undefined) {
        dispatch({
            type: ERROR_TYPE,
            payload: response
        });
        dispatch({
            type: UPDATE_TYPE,
            payload: ''
        });
    } else {
        dispatch({
            type: ERROR_TYPE,
            payload: ''
        });
        dispatch({
            type: UPDATE_TYPE,
            payload: response
        });
    }

    return response;
}

export const createConcept = (concept, username) => async (dispatch) => {
    const response = await service.saveConcept(concept, username).then((r) => {
        return r.data !== undefined ? r.data : r.response.data
    });

    if (response.error !== undefined) {
        dispatch({
            type: ERROR_TYPE,
            payload: response
        });
        dispatch({
            type: CREATE_CONCEPT,
            payload: ''
        });
    } else {
        dispatch({
            type: ERROR_TYPE,
            payload: ''
        });
        dispatch({
            type: CREATE_CONCEPT,
            payload: response
        });
    }
    return response;
}