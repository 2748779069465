import React, { Fragment, useState, useEffect, useRef } from 'react';
import { ToastProvider, useToasts } from 'react-toast-notifications';
import { Row, Col } from 'react-bootstrap';
import Card from '../../../components/Card/Card';
import { FormGroup, ButtonGroup, Container, Form } from 'react-bootstrap';
import LabelChargeData from '../../../components/Label/labelChargeData';
import LabelTitle from '../../../components/Label/labelTitle';
import Button from '../../../components/CustomButton/CustomButton';
import language from '../../../config/es';
//redux
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteTransaction,
  findTreeListBySystem,
  findTransactionsNotAssigned,
  createTransaction,
  findTransactionsNotAssignedFolder,
} from '../../../redux/rol.duck';
import './LeafTransaction.scss';
import Select from 'react-select';
//
import 'devextreme/dist/css/dx.common.css';
//import 'devextreme/dist/css/dx.dark.css';
import { TreeList, Column } from 'devextreme-react/tree-list';
import DataSource from 'devextreme/data/data_source';
import { findAllSystem } from '../../../redux/system.duck';
//Session user
import SessionConfig from '../../../config/session_config';

const LeafTransaction = () => {
  const ref = useRef();
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const [data, setData] = useState({
    system: { value: 0, label: '', error: '' },
    showloadingSystem: false,
    selectedSystem: false,
    systems: [],
    roles: [],
    addId: 0,
    ds: [],
    deleteDs: [],
    responseCreate: [],
    selectedTransactions: [],
    addMenus: [],
    selectedAddTransactions: [],
    deleteMenus: [],
    enableInsert: false,
    enableDelete: false,
    responseDelete: null,
    transactionsNotAssigned: [],
    transactionsNotAssignedFolder: [],
    tranNotAssigned: { value: 0, label: '' },
    columns: [],
    userAdmin: 'admin',
    selectedRows: [],
    showDelete: false,
    pendingData: true,
    loadingAddMenu: false,
    loadingTransaction: false,
    nameError: '',
    valueError: '',
    onDisabledMenu: true,
    onDisabledAddMenu: true,
    onShowDropDown: 'default',
    auth: JSON.parse(sessionStorage.getItem('user')),
  });

  const [state, setState] = useState({
    count: 0,
    disabled: true,
    hasItems: false,
    menuId: 0,
    parentId: 0,
    fatherId: 0,
    deleteTransactionFolderId: 0,
    deleteTransactionId: 0,
    loading: language.messages.msmLoading,
    visibleDeleteFolder: false,
    visibleDelete: false,
    visibleInsert: false,
    visibleInsertFolder: false,
    menuName: '',
    titleLevel: '',
    titleLevelName: '',
    menuLevel: '',
    focusedRowKey: 0,
  });
  const [type, setType] = useState(true);
  const updateLoading = (value, id) => {
    setState({
      ...state,
      [id]: value,
    });
  };
  data.systems = useSelector((store) => store.systemReducer.systems);

  useEffect(() => {
    loadRolData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    ref.current.onRefreshCurrentSession();
  }, [data]);

  const loadSystems = async () => {
    await dispatch(findAllSystem());
    data.showloadingSystem = true;
    refreshState();
  };

  const initData = async () => {
    await loadTree();
  };
  const loadTree = async () => {
    data.ds = new DataSource({
      key: 'id',
      load: async function () {
        try {
          let response = await dispatch(
            findTreeListBySystem(data.system.value, data.auth.id.user.username)
          );
          if (response === 403) {
            viewNotification(language.messages.msmRestrictedAccessView, 'error');
            updateLoading(language.messages.msmRestrictedAccessView, 'loading');
            return null;
          } else {
            updateLoading(language.messages.msmSelectOptionRole, 'loading');
            return response;
          }
        } catch (e) {
          throw viewNotification(language.messages.msmErrorExist, 'warning');
        }
      },
    });
  };
  const loadRolData = async () => {
    await loadSystems();
    data.pendingData = true;
    data.loadingAddMenu = true;
    data.loadingTransaction = true;
    initData();
    updateState(false, 'pendingData');
  };
  const onFocusedRowChanged = (e) => {
    var rowData = { ...e.row, ...e.row.data, level: e.row.level.toString() };
    let focusedRowKey = e.component.option('focusedRowKey');
    let visibleDeleteFolder = false;
    let visibleDelete = false;
    let visibleInsert = false;
    let visibleInsertFolder = false;
    let menuLevel =
      rowData.level === '0'
        ? '1'
        : rowData.level === '1'
          ? '2'
          : rowData.level === '2'
            ? '3'
            : rowData.level === '3'
              ? '4'
              : '';
    let menuId = 0;
    let hasItems = rowData.hasItems;
    let fatherId = 0;
    let deleteTransactionId = 0;
    let deleteTransactionFolderId = 0;
    const isFolder = e.row.data.level === '1' ? true : false;
    switch (rowData.level) {
      case '1':
        visibleDeleteFolder = true;
        visibleDelete = false;
        visibleInsert = true;
        visibleInsertFolder = true;
        fatherId = rowData.id;
        deleteTransactionFolderId = rowData.id;
        deleteTransactionId = rowData.id;
        loadingTransactionMenu(rowData.parentId, menuLevel);
        break;
      case '2':
        visibleDeleteFolder = isFolder;
        visibleDelete = !isFolder;
        visibleInsert = isFolder ;
        visibleInsertFolder = isFolder;
        fatherId = rowData.id;
        deleteTransactionFolderId = rowData.id;
        deleteTransactionId = rowData.id;
        loadingTransactionMenu(rowData.parentId, menuLevel);
        break;
      case '3':
        visibleDeleteFolder = isFolder;
        visibleDelete = !isFolder;
        visibleInsert = isFolder;
        visibleInsertFolder = false;
        deleteTransactionId = rowData.id;
        deleteTransactionFolderId = rowData.id;
        loadingTransactionMenu(rowData.parentId, menuLevel);
        break;
      case '4':
        visibleDeleteFolder = isFolder;
        visibleDelete = true;
        visibleInsert = false;
        visibleInsertFolder = false;
        deleteTransactionId = rowData.id;
        deleteTransactionFolderId = rowData.id;
        loadingTransactionMenu(rowData.parentId, menuLevel);
        break;
      case '0':
        visibleDeleteFolder = false;
        visibleDelete = false;
        visibleInsert = false;
        visibleInsertFolder = true;
        menuId = rowData.id;
        loadingTransactionMenu(menuId, menuLevel);
        break;
      default:
        break;
    }
    setState({
      menuId: menuId,
      hasItems: hasItems,
      parentId: rowData.parentId,
      fatherId: fatherId,
      deleteTransactionFolderId: deleteTransactionFolderId,
      deleteTransactionId: deleteTransactionId,
      loading: '',
      visibleDeleteFolder: visibleDeleteFolder,
      visibleDelete: visibleDelete,
      visibleInsert: visibleInsert,
      visibleInsertFolder: visibleInsertFolder,
      menuName: rowData.name,
      titleLevel: rowData.systemName,
      titleLevelName: e.row.data.level === '1' ? 'Carpeta' : 'Transacción',
      focusedRowKey: focusedRowKey,
      menuLevel: menuLevel,
    });
  };
  const loadingTransactionMenu = (id, level) => {
    data.pendingData = false;
    data.loadingTransaction = false;
    state.disabled = false;
    loadTranDataNotAssigned(id, level);
    refreshState();
  };
  const loadTranDataNotAssigned = async (id, level) => {
    if (id !== undefined) {
      data.transactionsNotAssigned = await dispatch(
        findTransactionsNotAssigned(id, level, data.auth.id.user.username)
      );
      data.transactionsNotAssignedFolder = await dispatch(
        findTransactionsNotAssignedFolder(id, level, data.auth.id.user.username)
      );
      data.loadingTransaction = true;
      data.tranNotAssigned = { value: 0, label: '' };
      refreshState();
    }
  };
  const saveData = async () => {
    if ((state.fatherId !== 0 || state.focusedRowKey !== 0) && data.tranNotAssigned.value !== 0) {
      viewNotification(language.messages.msmRegisterDataLoading, 'success');
      state.disabled = true;
      // eslint-disable-next-line no-useless-concat
      data.responseCreate = await dispatch(
        createTransaction(
          (state.fatherId || state.focusedRowKey) + '-' + data.tranNotAssigned.value + '-admin',
          data.auth.id.user.username
        )
      );
      if (data.responseCreate > 0) {
        viewNotification(language.messages.msmRegisterDataSuccess, 'success');
      } else {
        viewNotification(language.messages.msmRestrictedAccessCreate, 'error');
      }
      loadingTransactionMenu(state.parentId, state.menuLevel);
      initData();
      //state.fatherId = 0;
    } else {
      viewNotification(language.messages.msmTransactionIdEmpty, 'warning');
      data.pendingData = false;
    }
  };
  const saveFolderData = async () => {
    if ((state.menuId !== 0 || state.fatherId !== 0) && data.tranNotAssigned.value !== 0) {
      viewNotification(language.messages.msmRegisterDataLoading, 'success');
      state.disabled = true;
      // eslint-disable-next-line no-useless-concat
      data.responseCreate = await dispatch(
        createTransaction(
          (state.menuId || state.fatherId) + '-' + data.tranNotAssigned.value + '-admin',
          data.auth.id.user.username
        )
      );
      if (data.responseCreate > 0) {
        viewNotification(language.messages.msmRegisterDataSuccess, 'success');
      } else {
        viewNotification(language.messages.msmRestrictedAccessCreate, 'error');
      }
      loadingTransactionMenu(state.menuId || state.fatherId, state.menuLevel);
      initData();
      //state.menuId = 0;
    } else {
      viewNotification(language.messages.msmTransactionIdEmpty, 'warning');
      data.pendingData = false;
    }
  };
  const deleteDataFolder = async () => {
    // eslint-disable-next-line no-restricted-globals
    var r = confirm(language.messages.msgDelete);
    if (r === true) {
      if (state.deleteTransactionFolderId !== 0) {
        if (state.hasItems) {
          viewNotification(
            language.messages.msmMenuDirectoryNotEmpty,
            'warning'
          );
          data.pendingData = false;
        } else {
          viewNotification(language.messages.msmDeleteDataLoading, 'success');
          data.disabled = false;
          data.responseDelete = await dispatch(
            deleteTransaction(
              state.deleteTransactionFolderId,
              data.auth.id.user.username
            )
          );
          if (data.responseDelete === true) {
            viewNotification(language.messages.msmDeleteDataSuccess, 'success');
            state.titleLevel = '';
            state.titleLevelName = '';
            state.menuLevel = '';
            state.visibleDeleteFolder = false;
            state.visibleDelete = false;
            state.visibleInsert = false;
            state.visibleInsertFolder = false;
          } else {
            viewNotification(
              language.messages.msmRestrictedAccessDelete,
              'error'
            );
          }
          if (state.menuLevel !== '2') {
          }
          data.ds = [];
          initData();
        }
      } else {
        viewNotification(language.messages.msmRoleIdEmpty, 'warning');
        data.pendingData = false;
      }
    }
  };
  const deleteData = async () => {
    // eslint-disable-next-line no-restricted-globals
    var r = confirm(language.messages.msgDelete);
    if (r === true) {
      if (state.deleteTransactionId !== 0) {
        if (state.hasItems) {
          viewNotification(
            language.messages.msmMenuDirectoryNotEmpty,
            'warning'
          );
          data.pendingData = false;
        } else {
          data.disabled = false;
          data.responseDelete = await dispatch(
            deleteTransaction(
              state.deleteTransactionId,
              data.auth.id.user.username
            )
          );
          if (data.responseDelete === true) {
            viewNotification(language.messages.msmDeleteDataLoading, 'success');
            state.titleLevel = '';
            state.titleLevelName = '';
            state.menuLevel = '';
            state.visibleDeleteFolder = false;
            state.visibleDelete = false;
            state.visibleInsert = false;
            state.visibleInsertFolder = false;
          } else {
            viewNotification(
              language.messages.msmRestrictedAccessDelete,
              'error'
            );
          }
          loadingTransactionMenu(state.parentId, state.menuLevel);
          initData();
        }
      } else {
        viewNotification(language.messages.msmRoleIdEmpty, 'warning');
        data.pendingData = false;
      }
    }
  };
  const cleanForm = () => {
    state.titleLevel = '';
    state.titleLevelName = '';
    state.menuLevel = '';
    state.menuName = ''
    state.visibleDeleteFolder = false;
    state.visibleDelete = false;
    state.visibleInsert = false;
    state.visibleInsertFolder = false;
  }
  const updateSelect = async (value, id) => {
    switch (id) {
      case 'system':
        data.system = value;
        data.selectedSystem = true;
        viewNotification(language.messages.msmLoadingData, 'success');
        cleanForm();
        initData();
        break;
      default:
        break;
    }
    refreshState();
  };
  const updateState = (value, id) => {
    setData({
      ...data,
      [id]: value,
    });
  };
  const viewNotification = (msm, action) => {
    addToast(msm, {
      appearance: action,
      autoDismiss: true,
    });
  };
  const refreshState = () => {
    setData({
      ...data,
    });
  };
  const handleSelectChange = (value, type) => {
    data.tranNotAssigned = value;
    updateState(false, 'pendingData');
    setType(type);
  };
  return (
    <Fragment>
      <SessionConfig ref={ref} />
      <br />
      <Container fluid={true}>
        <Card
          icono=" fa fa-database"
          title={language.messages.labelTreeViewMenuSystem}
          content={
            <Row>
              <Col md={12}>
                <Row>
                  <LabelChargeData showloading={data.showloadingSystem} />
                  <Col md={6} className={data.showloadingSystem ? '' : 'noDisplay'}>
                    <Form autoComplete="off">
                      <LabelTitle
                        text={language.messages.labelMenuSystem}
                        requerid={false}
                      />
                      <FormGroup>
                        <Select
                          placeholder={language.messages.lblSingleSelect}
                          name="system"
                          options={data.systems}
                          className="text-uppercase small "
                          value={{
                            value: data.system.value,
                            label: data.system.label,
                          }}
                          onChange={(value) => {
                            updateSelect(value, 'system');
                          }}
                        />
                        <span className="small text-danger">
                          {data.system.error}
                        </span>
                      </FormGroup>
                    </Form>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} sm={12} md={8} lg={6}>
                {data.selectedSystem === true ?
                  <TreeList
                    id="treeList"
                    dataSource={data.ds}
                    focusedRowEnabled={true}
                    focusedRowKey={state.focusedRowKey}
                    showBorders={true}
                    keyExpr="id"
                    parentIdExpr="parentId"
                    hasItemsExpr="hasItems"
                    showRowLines={true}
                    columnAutoWidth={true}
                    wordWrapEnabled={true}
                    onFocusedRowChanged={onFocusedRowChanged}
                  >
                    <Column dataField="name" caption="Nombre" />
                  </TreeList> : ''}
              </Col>
              <Col xs={12} sm={12} md={4} lg={6}>
                {data.selectedSystem === true ? <div className="task-info">
                  <div className="info">
                    <Row>
                      <Col md={12}>
                        <span>{state.loading}</span>
                        <div className="task-subject">{state.titleLevelName} - {state.menuName}</div>
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={12}>
                        {state.visibleDeleteFolder ? (
                          <FormGroup>
                            <LabelTitle
                              text={language.messages.labelDeleteTransactionFolder}
                            />
                            <Row className="align-items-end">
                              <Col lg={10}>
                                <ButtonGroup>
                                  <Button
                                    disabled={state.disabled}
                                    className="delete-btn"
                                    fill
                                    type="button"
                                    variant="outline-success"
                                    onClick={deleteDataFolder}
                                  >
                                    <i className="fa fa-remove"></i>
                                  </Button>
                                </ButtonGroup>
                              </Col>
                            </Row>
                          </FormGroup>
                        ) : null}
                        {state.visibleInsertFolder ? (
                          <FormGroup>
                            <LabelTitle
                              text={language.messages.labelAddTransactionFolder}
                            />
                            <Row className="align-items-end">
                              <LabelChargeData
                                showloading={data.loadingTransaction}
                              />
                              <Col className="cb-add-transaction">
                                <Select
                                  placeholder={language.messages.lblSingleSelect}
                                  name="classType"
                                  options={data.transactionsNotAssignedFolder}
                                  value={{
                                    value: type ? data.tranNotAssigned.value : '',
                                    label: type ? data.tranNotAssigned.label : '',
                                  }}
                                  onChange={(value) => {
                                    handleSelectChange(value, true);
                                  }}
                                />
                              </Col>
                              <Col>
                                <ButtonGroup>
                                  <Button
                                    disabled={state.disabled}
                                    className="save-btn"
                                    fill
                                    type="button"
                                    variant="outline-success"
                                    onClick={saveFolderData}
                                  >
                                    <i className="fa fa-save"></i>
                                  </Button>
                                </ButtonGroup>
                              </Col>
                            </Row>
                          </FormGroup>
                        ) : null}
                        {state.visibleInsert ? (
                          <FormGroup>
                            <LabelTitle
                              text={language.messages.labelAddTransaction}
                            />
                            <Row className="align-items-end">
                              <LabelChargeData
                                showloading={data.loadingTransaction}
                              />
                              <Col className="cb-add-transaction">
                                <Select
                                  placeholder={language.messages.lblSingleSelect}
                                  name="classType"
                                  options={data.transactionsNotAssigned}
                                  value={{
                                    value: !type ? data.tranNotAssigned.value : '',
                                    label: !type ? data.tranNotAssigned.label : '',
                                  }}
                                  onChange={(value) => {
                                    handleSelectChange(value, false);
                                  }}
                                />
                              </Col>
                              <Col>
                                <ButtonGroup>
                                  <Button
                                    disabled={state.disabled}
                                    className="save-btn"
                                    fill
                                    type="button"
                                    variant="outline-success"
                                    onClick={saveData}
                                  >
                                    <i className="fa fa-save"></i>
                                  </Button>
                                </ButtonGroup>
                              </Col>
                            </Row>
                          </FormGroup>
                        ) : null}
                        {state.visibleDelete ? (
                          <FormGroup>
                            <LabelTitle
                              text={language.messages.labelDeleteTransaction}
                            />
                            <Row className="align-items-end">
                              <Col lg={10}>
                                <ButtonGroup>
                                  <Button
                                    disabled={state.disabled}
                                    className="delete-btn"
                                    fill
                                    type="button"
                                    variant="outline-success"
                                    onClick={deleteData}
                                  >
                                    <i className="fa fa-remove"></i>
                                  </Button>
                                </ButtonGroup>
                              </Col>
                            </Row>
                          </FormGroup>
                        ) : null}
                      </Col>
                    </Row>
                  </div>
                </div> : ''}
              </Col>
            </Row>
          } />
        <br />
      </Container>
    </Fragment>
  );
};
const App = () => (
  <ToastProvider>
    <LeafTransaction />
  </ToastProvider>
);
export default App;
