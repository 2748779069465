import { postBodyService, getService } from "./base.service";
import config from "../config/application";
import permission from '../api/permissions.service';
import dictionary from '../config/dictionary';

//url
const list = 'find_all';
const add = 'add';
const update = 'update';
const disable = 'delete';
const findCountry = 'find_country';


class SocietyService {
    getSocieties() {
        const url = `${config.data.configuration.society.url}${list}`;
        return getService(url);
    }

    findByCountry(country) {
        const url = `${config.data.configuration.society.url}${findCountry}/${country}`;
        return getService(url);
    }


    async findSocietiesByCountryId(country, username) {
        let valid = await permission.validPermission(
            process.env.REACT_APP_TRANSACTION_SOCIETY,
            username,
            process.env.REACT_APP_SYSTEM_CODE, dictionary.configApp.action.list);

        if (valid.response === undefined && valid.data.allow === true) {
            const url = `${config.data.configuration.society.url}${findCountry}/${country}`;
            return getService(url);
        } else {
            return valid;
        }
    }

    async saveSociety(society, username) {
        let valid = await permission.validPermission(
            process.env.REACT_APP_TRANSACTION_SOCIETY,
            username,
            process.env.REACT_APP_SYSTEM_CODE, dictionary.configApp.action.add);

        if (valid.response === undefined && valid.data.allow === true) {
            const url = `${config.data.configuration.society.url}${add}`;
            return postBodyService(url, society);
        } else {
            return valid;
        }
    }
    async updateSociety(society, username) {
        let valid = await permission.validPermission(
            process.env.REACT_APP_TRANSACTION_SOCIETY,
            username,
            process.env.REACT_APP_SYSTEM_CODE, dictionary.configApp.action.edit);

        if (valid.response === undefined && valid.data.allow === true) {
            const url = `${config.data.configuration.society.url}${update}`;
            return postBodyService(url, society);
        } else {
            return valid;
        }
    }
    async deleteSociety(lsSociety, username) {
        let valid = await permission.validPermission(
            process.env.REACT_APP_TRANSACTION_SOCIETY,
            username,
            process.env.REACT_APP_SYSTEM_CODE, dictionary.configApp.action.delete);

        if (valid.response === undefined && valid.data.allow === true) {
            const url = `${config.data.configuration.society.url}${disable}`;
            return postBodyService(url, lsSociety);
        } else {
            return valid;
        }
    }
}
// eslint-disable-next-line import/no-anonymous-default-export
export default new SocietyService();