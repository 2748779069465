import axios from 'axios';

const params = new URLSearchParams();
const consumerKey = process.env.REACT_APP_TOKEN_CLIENT_ID;
const consumerSecret = process.env.REACT_APP_TOKEN_CLIENT_SECRET;
const authEndpoint = process.env.REACT_APP_ACCESS_TOKEN_URL;

params.append('client_id', consumerKey);
params.append('client_secret', consumerSecret);
params.append('grant_type', 'client_credentials');

const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  }

function getTokenService() {
    return new Promise(async (resolve) => {
        axios.post(authEndpoint, params, config).then(res => {
            resolve(res);
        }).catch(error => {
            resolve(error);
        });

    });

}
export const getToken = () => {
    return getTokenService().then((r) => {
        return r.data;
        
    });

}