import config from "../config/application";
import { getService, postBodyService} from "./base.service";
import permission from "./permissions.service";
import dictionary from "../config/dictionary";

const getAll = 'find_all';
const getFindByUsername = 'findByUserName';
const create = 'create';
const deleteById = 'delete';

class UserSocietyService {
    async getAllUserSocieties(user,username) {
        let valid = await permission.validPermission(
            process.env.REACT_APP_TRANSACTION_USER_SOCIETY,
            username,
            process.env.REACT_APP_SYSTEM_CODE, dictionary.configApp.action.list);
        if (valid.response === undefined && valid.data.allow === true) {
            const url = `${config.data.security.userSociety.url}${getAll}/${user}`;
            return getService(url);
        } else {
            return valid;
        }
    }
    getFindByUsername(username) {
        const url = `${config.data.security.userSociety.url}${getFindByUsername}/${username}`;
        return getService(url);
    }
    async saveUserSociety(userSociety,username){
        let valid = await permission.validPermission(
            process.env.REACT_APP_TRANSACTION_USER_SOCIETY,
            username,
            process.env.REACT_APP_SYSTEM_CODE, dictionary.configApp.action.add);
        if (valid.response === undefined && valid.data.allow === true) {
            let url = `${config.data.security.userSociety.url}${create}`;
            return postBodyService(url,userSociety);
        } else {
            return valid;
        }
    }
    async deleteUserSociety(lsUserSocieties,username){
        let valid = await permission.validPermission(
            process.env.REACT_APP_TRANSACTION_USER_SOCIETY,
            username,
            process.env.REACT_APP_SYSTEM_CODE, dictionary.configApp.action.delete);
        if (valid.response === undefined && valid.data.allow === true) {
            const url = `${config.data.security.userSociety.url}${deleteById}`;
            return postBodyService(url, lsUserSocieties);
        } else {
            return valid;
        }
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new UserSocietyService(); 