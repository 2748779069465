
export function no_es_vacio(txt) {
    return !(txt === null || String(txt).trim() === '');
}

export function es_vacio(txt) {
    return !no_es_vacio(txt);

}

export function es_hora(txt) {
    var patron = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;
    return patron.test(txt);

}

export function es_entero(txt) {
    var patron = /^[0-9]+$/;
    return patron.test(txt);
}

export function es_decimal(txt) {
    var patron = /^-?\d*(\.\d+)?$/;
    return patron.test(txt);
}

// noinspection JSUnusedGlobalSymbols
export function solo_texto(txt) {
    var patron = /^[A-Za-z\s]+$/;
    return patron.test(txt);
}

// noinspection JSUnusedGlobalSymbols
export function valid_IP(txt) {
    let pattern = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;
    return pattern.test(txt);
}

export function is_email(txt) {
    //eslint-disable-next-line
    let pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/;
    return pattern.test(txt);
}
export function isGreaterThan(a, b) {
    return parseInt(a) > parseInt(b);
}



