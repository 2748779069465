import { postBodyService } from "./oauth.service";
import config from "../config/application";
class ppermissionService {
    validPermission(transactionCode, username, systemCode, action) {
        const jsonData = {
            transactionCode: transactionCode,
            username: username,
            systemCode: systemCode,
            action: action
        }
        
        const url = `${config.data.security.permission.url}`;
        return postBodyService(url, jsonData);
    }
}
// eslint-disable-next-line import/no-anonymous-default-export
export default new ppermissionService();