import { combineReducers } from 'redux';

import societyReducer from "../society.duck";
import countryReducer from "../country.duck";
import typeReducer from "../type.duck";
import userReducers from "../user.duck";
import loginReducer from "../login.duck";
import systemReducer from "../system.duck";
import transactionReducer from "../transaction.duck";
import rolReducer from "../rol.duck";
import userRoleReducer from "../userRole.duck";
import userSocietyReducer from "../userSociety.duck";

export default combineReducers({
    societyReducer: societyReducer,
    rolReducer: rolReducer,
    countryReducer: countryReducer,
    typeReducer: typeReducer,
    userReducers: userReducers,
    systemReducer: systemReducer,
    transactionReducer: transactionReducer,
    userRoleReducer: userRoleReducer,
    loginReducer: loginReducer,
    userSocietyReducer: userSocietyReducer
});