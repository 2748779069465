import React, { Fragment } from 'react';
import language from '../../config/es';
import ReactSpinner from 'react-bootstrap-spinner';
import { Col } from 'react-bootstrap';
const LabelChargeData = (props) => {
  const { showloading = false ,size='1',label=language.messages.lblLoading} = props;
  return (
    <Fragment>
      <Col
        md={12}
        className="text-left small text-primary ml-2"
        style={{
          display: !showloading ? '' : 'none',
        }}
      >
        {label}{' '}
        <ReactSpinner type="border" color="secundary" size={size} />
      </Col>
    </Fragment>
  );
};
export default LabelChargeData;
