//#region IMPORTS
import React, { Component } from 'react';
import language from './es';

import { Auth } from 'aws-amplify';
import jwt_decode from 'jwt-decode';

import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { blue, red } from '@material-ui/core/colors';
//import AuthHelper from './auth_helper'
//#endregion IMPORTS

const useStyles = makeStyles((theme) => ({
  divTitleSession: {
    textAlign: 'center',
    '& h2': {
      margin: theme.spacing(1),
      fontWeight: 'bold',
      color: blue[600]
    },
    '& span': {

    }
  },
  listItemLive: {
    backgroundColor: blue[100],
    '&:hover': {
      backgroundColor: blue[600],
      color: 'white',
    }
  },
  listItemDeath: {
    '&:hover': {
      backgroundColor: red[600],
      color: 'white',
    }
  },
  refreshSession: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  closeSession: {
    backgroundColor: red[100],
    color: red[600]
  }
}));

function SimpleDialog(props) {
  const classes = useStyles();
  const { onClose, timeOut, open } = props;

  const handleClose = () => {
    //onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle>
        <div className={classes.divTitleSession}>
          <span>{language.messages.sessionUser.lbTitle}</span>
          <h2>{timeOut}</h2>
          <span>{language.messages.sessionUser.lbSeconds}</span>
        </div>
      </DialogTitle>
      <List>
        <ListItem button divider onClick={() => handleListItemClick('live')} className={classes.listItemLive}>
          <ListItemAvatar>
            <Avatar className={classes.refreshSession}>
              <CheckCircleIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={language.messages.sessionUser.btnLive} />
        </ListItem>
      <ListItem button onClick={() => handleListItemClick('death')} className={classes.listItemDeath}>
          <ListItemAvatar>
            <Avatar className={classes.closeSession}>
              <CancelIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={language.messages.sessionUser.btnDeath} />
        </ListItem>
      </List>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  timeOut: PropTypes.number.isRequired,
};

class SessionConfig extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedValue: ['live', 'death'],
      open: false,
      count: 0,
      delay: 1000
    };
  }
  componentDidMount() {
    this.interval = setInterval(this.tick, this.state.delay);
  }
  componentDidUpdate(prevProps, prevState) {
    if(prevState.delay !== this.state.delay) {
      clearInterval(this.interval);
      this.interval = setInterval(this.tick, this.state.delay);
    }
    if(prevState.count > 0) {
      switch (prevState.count) {
        case 60:
          //console.log('Refresh yor token message')
          break;
        case 25:
          if(this.state.open === false) {
            this.setState({
              open: true
            });
          }
          break;
        case 1:
          this.logoutSessionAD();
          break;
        default:
          break;
      }
    }
  }
  componentWillMount() {
    clearInterval(this.interval);
    Auth.currentSession()
    .then(data => {
      this.getToken(data);
      if (document.documentElement.className.indexOf("nav-open") !== -1) {
        document.documentElement.classList.toggle("nav-open");
      }
    })
    .catch(error => {
      console.log(error);
      this.logoutSessionAD();
    });
  }
  getToken = (data) => {
    const token = data.idToken.jwtToken;
    if (jwt_decode(token).exp > Date.now() / 1000) {
        //var diff = Math.abs(new Date(parseInt(jwt_decode(token).exp * 1000)) - new Date());
        //var seconds = Math.floor((diff/1000));
        this.setState({
          count: parseInt(process.env.REACT_APP_SESSION_TIME_USER)
        });
    }
  }
  logoutSessionAD = () => {
    try {
      Auth.signOut();
      this.removeSession();
    } catch(error) {
      console.log(error);
      this.removeSession();
    }
  }
  removeSession = () => {
    window.sessionStorage.removeItem('username');
    window.sessionStorage.removeItem('name');
    window.sessionStorage.removeItem('user');
    window.sessionStorage.removeItem('menu');
    window.sessionStorage.removeItem('token');
    window.sessionStorage.removeItem('node');
    window.sessionStorage.removeItem('menuId');
  }
  tick = () =>{
    this.setState({
      count: this.state.count - 1
    });
  }
  handleDelayChange = (e) => {
    this.setState({delay: Number(e.target.value)});
  }
  handleClose = (value) => {
    switch(value) {
      case 'live':
        this.refreshCurrentSession();
        this.setState({
          open: false
        });
        clearInterval(this.interval);
        this.interval = setInterval(this.tick, this.state.delay);
      break;
      case 'death':
        this.logoutSession();
        Auth.signOut({ global: true });
        window.location.href = '/login';
      break;
      default:
      break;
    }
  }

  refreshCurrentSession = () => {
    Auth.currentSession()
    .then(data => {
      this.getToken(data);
    })
    .catch((error) => {
      console.log(error);
      this.logoutSessionAD();
    });
  }

  onRefreshCurrentSession () {
    this.refreshCurrentSession();
    clearInterval(this.interval);
    this.interval = setInterval(this.tick, this.state.delay);
  }

  render(){
    return(
      <div>
        {/* <span>{this.state.count}</span> */}
        <SimpleDialog timeOut={this.state.count} open={this.state.open} onClose={this.handleClose} />
      </div>
    )}
}
export default SessionConfig;
