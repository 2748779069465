const caracter = '(*)';
const messages = {
    caracter: caracter,
    title_close: 'Salir',
    title_system_close: 'cerrar sesión',
    title_home: 'Inicio',
    title_filter: 'Filtros de datos',
    title_system: 'Módulo de Seguridad',
    title_system_min: "Módulo de Seguridad",
    title: 'Seguridad',
    loginUsernameExistError: 'El usuario ingresado no existe, favor verifique sus datos.',
    loginADSuccessAwait: 'Estamos iniciando su sesión, espere por favor.',
    loginADRedirect: 'Redireccionando...',
    logoutUser: 'Sesión Finalizada',
    lblSimbolo: 'El símbolo ' + caracter,
    lblfieldsRequired: 'indica los campos mínimos requeridos para poder procesar el formulario',
    lblSingleSelect: 'Seleccione algun item',
    lblSingleTypeSelect: 'Seleccione algun pago',
    lblLoading: 'Cargando datos',
    lblFunctions: 'Opciones',
    lblName: 'Nombre',
    lblCode: 'Código',
    lbEdit: 'Editar',
    lbDetail: 'Detalle',
    lbBack: 'Regresar',
    lbUsername: 'Usuario: ',
    lbRoleName: 'Rol: ',
    lbAll: 'Todos',
    lbAllowCreate: 'Permitir Crear',
    lbAllowRead: 'Permitir Ver',
    lbAllowUpdate: 'Permitir Actualizar',
    lbAllowDelete: 'Permitir Eliminar',
    lbMultipleSelect: 'Seleccion multiple',
    lbSelectedRoleType: 'Seleccionar Transacciones',
    lbSelectDefault: 'Seleccionar por Defecto',
    lblSessionExpireIn: 'La sesión expirara en: ',
    success_reload_data: 'Los datos han sido actualizados',
    success_clean_data: 'Se ha reiniciado el formulario',
    await_logout_session: 'Estamos cerrando su sesion...',
    btnDisable: 'Dar de Baja',
    btnVerify: 'Verificar',
    //sessionUser
    sessionUser: {
        lbTitle: 'Tu sesión se cerrará en',
        lbSeconds: 'segundos',
        btnLive: 'Mantener Sesión',
        btnDeath: 'Cerrar Sesión'
    },
    //paginacion
    rowsPerPageText: 'Filas por página',
    rangeSeparatorText: 'de',
    selectAllRowsItemText: 'Todos',
    //mensajes
    msmRegister: 'El valor {0}, fue registrado correctamente',
    msmDeleteDataLoading: 'Eliminando datos espere por favor',
    msmDeleteDataSuccess: 'Datos Eliminados correctamente',
    msmRegisterDataLoading: 'Registrando datos espere por favor',
    msmRegisterDataSuccess: 'Datos Registrados correctamente',
    msmDataLoading: 'Cargando datos espere por favor',
    msmDisableAll: 'Los items seleccionados fueron deshabilitados correctamente',

    //errores
    error_required: 'El campo no puede estar vacio',
    error_no_selected: 'Debe elegir un item',
    error_min_length: 'Mínimo {0} carácteres',
    error_max_length: 'Máximo {0} carácteres',
    error_email: 'Correo no valido',
    error_numero: 'El valor debe ser numérico',
    error_greatherThan: 'El valor debe ser mayor que {0}',
    error_module: 'Ocurrio un error al cargar los modulos',
    error_menu: 'Ocurrio un error al cargar el menu del usuario',
    error_node: 'Ocurrio un error al cargar las transacciones asignadas al usuario',
    error_empty_import_file: 'No se importaron los datos, el archivo esta vacio',
    error_invalid_data_import_file: 'Datos no validos en el documento, revise la pestaña Errores',
    error_invalid_data_row: 'Datos no validos en la fila: ',
    error_invalid_SAP: 'Solo debe seleccionar una solicitud para registrar el código SAP',
    error_exist_data_row: 'Fila: ',
    error_empty_data_row: 'Datos vacios en la fila: ',
    //info messages
    msmErrorExistRoleName: 'El Rol ya fue registrado',
    msmErrorExistSystemName: 'El nombre del Sistema ya fue registrado',
    msmErrorExistUserSocietyName: 'El usuario ya tiene la sociedad asignada',
    msmErrorExistCollectingEntity: 'La Entidad Recaudadora ya fue registrada',
    msmErrorExistUserRole: 'El usuario ya tiene asignado un rol',
    msmErrorOnlyTransactions: 'Por favor seleccione solo transacciones',
    msmFatherIdNoValid: 'Por favor solo seleccione los submenus',
    msmRoleIdEmpty: 'No selecciono ningun Rol',
    msmRestrictedAccessView: 'No tiene permisos para visualizar los datos',
    msmRestrictedAccessDelete: 'No tiene permisos para eliminar los datos',
    msmRestrictedAccessCreate: 'No tiene permisos para crear datos',
    msmMenuDirectoryNotEmpty: 'Elimine todos los submenus para proceder a la eliminacion del Directorio',
    msmTransactionIdEmpty: 'No selecciono ninguna Transaccion',
    msmDataExist: 'Valores existentes',
    msmErrorExist: 'Error en la respuesta',
    msmErrorForm: 'Tiene errores en el formulario',
    msmEmptySelectedTransactionsForm: 'Seleccionar Transacciones es requerido, seleccione una opcion',
    msmOneDataDisabled: 'Seleccione solo un registro por favor',
    msmEmptyDataDisabled: 'Seleccione un registro por favor',
    msmCurrentUserDisabled: 'No se puede eliminar las sociedades del usuario con la sesion actual',
    msmCurrentUserModify: 'No se puede modificar al usuario con la sesion actual',
    msmDeleteAll: 'Los items seleccionados fueron eliminados correctamente',
    successCleanData: 'Se limpiaron los datos',
    msmLoading: 'Cargando...',
    msmSelectOptionRole: 'Seleccione un rol de la Lista',
    msgSave: "¿Esta seguro de guardar el registro?",
    msgDelete: "¿Esta seguro de eliminar el registro?",
    msmLoadingData: 'Cargando datos espere por favor...',
    //forms
    btnSave: 'Guardar',
    btnEdit: 'Editar',
    btnClear: 'Limpiar',
    btnReload: 'Recargar',
    btnRestart: 'Reiniciar',
    btnAssign: 'Añadir',
    btnClose: 'Cerrar',
    titleUserRoleForm: 'Usuario Rol',
    titleRoleForm: 'Asignacion de Roles',
    titleSystemForm: 'Sistemas',
    titleUserSocietyForm: 'Asig. de Sociedad',
    titleCEntityForm: 'Entidad Recaudadora',
    titleMoney: 'Moneda',
    titleTypePayment: 'Forma de Pago',
    titleTypeForm: 'Tipos',
    titleAmountEntityForm: 'Montos por Entidad',
    titleChannelEntityPaymentForm: 'Formas de Pago por Entidad',
    titleMessages: 'Mensajes',
    titleTabForm: 'Formulario',
    titleTabDetailForm: 'Registrar Transaccion',
    labelSymbol: 'El símbolo ' + caracter,
    labelSymbolRequired: 'indica los campos mínimos requeridos para poder procesar el formulario',
    labelMenu: 'Menu',
    placeholderMenu: 'menus',
    labelSystem: 'Sistema',
    placeholderSystem: 'sistemas',
    labelCode: 'Codigo',
    labelName: 'Nombre Rol',
    labelSystemName: 'Nombre Sistema',
    labelCEntityName: 'Nombre Entidad',
    labelTypeName: 'Nombre Tipo',
    labelDescription: 'Descripcion',
    labelAddress: 'Proveedor',
    labelCountry: 'Pais',
    labelSocieties: 'Sociedad',
    labelEmail: 'E-mail',
    labelUsers: 'Usuario',
    labelNotAssignedTransactions: 'Asignar Transacciones',
    labelRoles: 'Rol',
    labelAddTransaction: 'Añadir Transacciones',
    labelAddTransactionFolder: 'Añadir DIrectorio',
    labelDeleteTransaction: 'Eliminar Transaccion',
    labelDeleteTransactionFolder: 'Eliminar Directorio',
    labelAddMenu: 'Seleccione Grupo Rol',
    labelMenuSystem: 'Seleccione el Sistema',
    labelTreeViewMenuSystem: 'Lista de Transacciones por Sistema',
    //grid
    gridRoleTitle: 'Lista de Roles',
    gridUserRoleTitle: 'Lista de Usuarios Roles',
    gridUserRoleDescriptionTitle: 'Detalle Usuario Rol',
    gridSystemTitle: 'Lista de Sistemas',
    gridUserSocietyTitle: 'Sociedades asignadas',
    gridCEntitiesTitle: 'Lista Entidades Recaudadoras',
    gridTypeTitle: 'Lista de Tipos',
    gridAmountEntityTitle: 'Lista de Montos por Entidad',
    gridChannelEntityPaymentTitle: 'Lista de Formas de Pago',
    gridMessagesTitle: 'Lista de Mensajes',
    gridColumUserName: 'Nombre Usuario',
    gridColumRoleName: 'Nombre Rol',
    gridColumRoleCode: 'Codigo',
    gridColumSystemName: 'Nombre Sistema',
    gridColumSystemDescription: 'Descripcion',
    gridColumCEntityName: 'Nombre Entidad',
    gridColumCEntityCode: 'Código',
    gridColumCEntityCountry: 'Pais',
    gridColumCEntitySocietyId: 'Sociedad',
    gridColumCEntityReplicationTime: 'Replicacion (min)',
    warning_required_field: 'El campo es requerido',
    warningOnlyNumeric: 'Solo se permite numeros enteros',
    warningOnlyDecimales: 'Solo se permite numeros decimales',
    warningOnlyNumericOrDecimal: 'Solo se permite numeros enteros o decimales',
    warningOnlyEmail: 'No es un email valido',
    warningOnlyIP: 'No es una direccion IP valida',
    warningMinimumLength: 'El nombre debe tener mas de 3 caracteres',
    messageNoAccessRegister: 'No tiene permisos para registrar datos',
    messageNoAccessEdit: 'No tiene permisos para editar los datos',
    messageNoAccessRemove: 'No tiene permisos para eliminar datos',
    messageNoAccessView: 'No tiene permisos para visualizar los datos',
    error: {
        noAccess: 'No tiene acceso a los datos'
    },
    society: {
        title_society: 'Sociedades',
        title_society_register: 'Sociedades Registradas',
        errorCode: 'Max. 4 caracteres',
        errorRegister: 'El código o nombre de la sociedad ya fue registrado',
        lblsociety: 'Sociedad',
        code:'Código',
        filter:'B. código/nombre',
        codeSelectd:'Códigos Seleccionados'
    },
    country: {
        title_country: 'País',
        title_country_form: 'Registro',
        title_country_register: 'Paises Registrados',
        title_type_payment: 'Tipo Pago',
    },
    transaction: {
        title: 'Registro de conceptos',
        route: 'Ruta',
        table: 'Listado de conceptos',
        concept:'Conceptos',
        errorLoad:'Error al cargar datos de transacciones',
        clon:'Clonar'
    },
    type: {
        title: 'Registro',
        createConcept: 'Concepto',
        selectConcept: 'Tipo',
        titlePayment: 'Tipo de Recaudacion',
        titleGrid: 'Tipos registrados',
        concept:'Concepto: ',
        name:'Nombre',
        code:'Código',
        value:'Valor',
        nameConcept:'Nombre de concepto'
    },
    message: {
        typeMessage: 'Tipo de Mensaje',
        nameMessage: 'Nombre del Mensaje',
        descriptionMessage: 'Descripcion del Mensaje',
        codeMessage: 'Codigo de Mensaje'
    },
    login: {
        label_email: 'Correo Electrónico',
        label_pass: 'Contraseña',
        label_confirmation: 'Confirmación',
        title_change_pass: 'Ha olvidado la contraseña?',
        title_create: 'Aun no tienes una cuenta?',
        lbl_change_pass: 'Cambiar contraseña',
        lbl_create_account: 'Crear Cuenta',
        lbl_send: 'Enviar Confirmación',
        lbl_code: 'Código de Confirmación',
        lbl_new_pass: 'Nueva contraseña',
        lbl_new_pass_conf: 'Confirmar Contraseña',
        lbl_sign_in: 'Iniciar sesión',
        message_create: 'Usuario creado',
        error_create: 'Los datos del usuario no han sido registrados',
        message_login: 'Registrando datos de ingreso, espere para ser redireccionado',
        message_login_success: 'Credenciales de acceso validas, cargando datos',
        message_pass_success: 'Su contraseña ha sido cambiada.',
        message_pass_login: 'Click aqui para iniciar con su nueva contraseña.',
        message_send_code: 'El código ha sido enviado a su correo',
        message_check_email: 'Por favor revise su correo electrónico.',
        error_auth: 'Ocurrio un error al autentificarse.'
    },
    paginationOptions: {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos'
    },
    userRol:{
        title:'Asignación de roles',
        titleTable:'Roles Asignados por sistema',
        titleTransacciones:'Asignación de transacciones',
        user:'Usuario',
        rol:'Rol',
        yes:'Si',
        not:'No',
        view:'Ver',
        add:'Crear',
        update:'Actualizar',
        delete:'Eliminar',
        transaction:'Transacción',
        deleteAssign:'Eliminar Asignación',
        system:'Sistema',
        searchUser:'Usuario (Busca por username)',
        remove:'Quitar transacción',
        loadSocieties:'Cargando sociedades',
        msmProvider:'Utilizar proveedor,para creación de usuarios'
    },
    footer:{
        contact:'Contacto:',
        develop:'Desarrollado por:'
    },
    header:{
        message:'Si continua en esta página, es necesario aceptar el uso de cookies.',
        ok:'Aceptar'
    },
    system:{
        titleForm:'Registro',
        name:'Sistema',
        description:'Descripción',
        code:'Cod.',
    }

};
// eslint-disable-next-line import/no-anonymous-default-export
export default {
    messages
};