import service from '../api/transaction.service';
import menuService from '../api/menu.service';


//constants
const dataTransaction = {
    transactions: [],
    transactionAdd: '',
    responseTransaction: null,
    errorTransaction: ''
}
//types
const LIST_TRANSACTION = 'LIST_TRANSACTION';
const ADD_TRANSACTION = 'ADD_TRANSACTION';
const ERROR_TRANSACTION = 'ERROR_TRANSACTION';


//reducer
export default function transactionReducer(state = dataTransaction, action) {
    switch (action.type) {
        case LIST_TRANSACTION:
            return { ...state, transactions: action.payload };
        case ADD_TRANSACTION:
            return { ...state, transactionAdd: action.payload };
        case ERROR_TRANSACTION:
            return { ...state, errorTransaction: action.payload };
        default:
            return { ...state };
    }
}

export const findAllTransaction = (user) => async (dispatch) => {
    const response = await service.getTransactions(user).then((r) => { return r.data });
    dispatch({
        type: LIST_TRANSACTION,
        payload: response
    });
}
export const findTransactionByType = (user, type, system) => async (dispatch) => {
    const response = await service.getTransactionsByType(user, type, system).then((r) => {
        return r.data !== undefined ? r.data : r.response.data;
    });

    if (response.code !== undefined) {
        dispatch({
            type: ERROR_TRANSACTION,
            payload: response
        });
        dispatch({
            type: LIST_TRANSACTION,
            payload: []
        });
    } else {
        dispatch({
            type: ERROR_TRANSACTION,
            payload: ''
        });
        dispatch({
            type: LIST_TRANSACTION,
            payload: response
        });
    }


}

export const saveTransaction = (transactionData,societies) => async (dispatch) => {
    const response = await service.saveTransaction(transactionData).then((r) => {
        return r.data !== undefined ? r.data : r.response.data;
    });
    if (response.error !== undefined) {
        dispatch({
            type: ERROR_TRANSACTION,
            payload: response
        });
        dispatch({
            type: ADD_TRANSACTION,
            payload: ''
        });
    } else {

        if (response.type === 0) {
            let jsonMenu = {
                name: response.name,
                level: 0,
                father: null,
                transaction: { id: response.id },
                status: 1,
                userAdmin: response.userAdmin,
                societyId: response.societyId
            }

            const menu = await menuService.saveMenu(jsonMenu).then((r) => { return r.data });
            let roleJson = {
                roleId: null,
                code: menu.menuId,
                name: menu.name,
                menu: menu.menuId,
                status: 1,
                userAdmin: response.userAdmin,
                societyId: response.societyId,
                system: response.system.id,
                societies:societies
            };
           
            await service.saveRole(roleJson);
        }
        dispatch({
            type: ADD_TRANSACTION,
            payload: response
        });
        dispatch({
            type: ERROR_TRANSACTION,
            payload: ''
        });
    }

}
export const updateTransaction = (transactionData) => async (dispatch) => {
    const response = await service.updateTransaction(transactionData).then((r) => {
        return r.data !== undefined ? r.data : r.response.data;
    });

    if (response.error !== undefined) {
        dispatch({
            type: ERROR_TRANSACTION,
            payload: response
        });
        dispatch({
            type: ADD_TRANSACTION,
            payload: ''
        });
    } else {
        dispatch({
            type: ERROR_TRANSACTION,
            payload: ''
        });
        dispatch({
            type: ADD_TRANSACTION,
            payload: response
        });
        
    }
}

export const deleteTransaction = (transactions,username) => async (dispatch) => {
    const response = await service.deleteTransaction(transactions,username).then((r) => {
        return r.data !== undefined ? r.data : r.response.data;
    });
    if (response.error !== undefined) {
        dispatch({
            type: ERROR_TRANSACTION,
            payload: response
        });
        dispatch({
            type: ADD_TRANSACTION,
            payload: ''
        });
    } else {
        response.delete='OK';
        dispatch({
            type: ERROR_TRANSACTION,
            payload: ''
        });
        dispatch({
            type: ADD_TRANSACTION,
            payload: response
        });
        
    }

    
    return response;
}