//#region IMPORTS
import React, { Fragment, useState, useEffect, useRef } from 'react';
import { ToastProvider, useToasts } from 'react-toast-notifications';
import { Container, Row, Col } from 'react-bootstrap';
import { FormGroup, ButtonGroup, Alert } from 'react-bootstrap';
import Card from 'components/Card/Card.jsx';
import LabelTitle from '../../components/Label/labelTitle';
import Button from '../../components/CustomButton/CustomButton';
import language from '../../config/es';
import Select from 'react-select';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { es_vacio } from '../../config/validate';
import LabelChargeData from '../../components/Label/labelChargeData';
import AsyncSelect from 'react-select/async';
//redux
import {
  findAll,
  saveUserSociety,
  deleteUserSociety,
  findByUsername,
} from '../../redux/userSociety.duck';
import { findAllUsers } from '../../redux/user.duck';
import { findCountries } from '../../redux/country.duck';
import { findByCountry } from '../../redux/society.duck';
//Session user
import SessionConfig from '../../config/session_config';
//#endregion IMPORTS

const UserSociety = () => {
  //#region VARIABLES
  const ref = useRef();
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const [data, setData] = useState({
    id: 0,
    userSocieties: [],
    users: [],
    societies: [],
    countries: [],
    user: { value: 0, label: '', error: '' },
    showLoadingCountry: false,
    showLoadingSociety: false,
    country: { value: 0, label: '', error: '', auth: '' },
    societyId: { value: '', label: '', error: '', auth: '' },
    errors: [],
    columns: [],
    selectedRows: [],
    pendingData: false,
    enable: true,
    showDelete: false,
    auth: JSON.parse(sessionStorage.getItem('user')),
  });
  data.userSocieties = useSelector(
    (store) => store.userSocietyReducer.userSocieties.data
  );
  data.users = useSelector((store) => store.userSocietyReducer.users);
  data.societies = useSelector((store) => store.societyReducer.societies);
  data.societyId.auth = useSelector(
    (store) => store.societyReducer.noPermitted
  );
  data.countries = useSelector((store) => store.countryReducer.countries);
  data.country.auth = useSelector((store) => store.countryReducer.noPermitted);
  //#region LIFECYCLE

  useEffect(() => {
    data.pendingData = true;
    data.showLoadingSociety = true;
    initTable();
    initData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    ref.current.onRefreshCurrentSession();
  }, [data]);
  const initData = async () => {
    await loadUsers();
    await loadCountries();
    updateState(false, 'pendingData');
  };
  const loadUserSocieties = async (user, username) => {
    updateState(true, 'pendingData');
    try {
      let res = await dispatch(findAll(user, username));
      if (res.status === 200) {
        data.userSocieties = res.data;
        data.pendingData = false;
      } else {
        switch (res.response.status) {
          case 403:
            if (
              res.response.data.error === language.messages.messageNoAccessView
            ) {
              viewNotification(
                language.messages.msmRestrictedAccessView,
                'error'
              );
              return null;
            }
            data.pendingData = false;
            data.cEntities = [];
            break;
          default:
            data.pendingData = false;
            data.cEntities = [];
            break;
        }
      }
    } catch (err) {
      data.pendingData = false;
      data.cEntities = [];
    }
    updateState(false, 'pendingData');
  };
  const loadUsers = async () => {
    await dispatch(findAllUsers());
    data.loadingUser = true;
    refreshState();
  };
  const loadCountries = async () => {
    await dispatch(findCountries());
    data.showLoadingCountry = true;
    updateState(true, 'showLoadingCountry');
  };
  const loadSocieties = async (country) => {
    data.showLoadingSociety = false;
    await dispatch(
      findByCountry(country)
    );
    data.showLoadingSociety = true;
    updateState(true, 'showLoadingSociety');
  };
  const initTable = () => {
    data.columns = [
      {
        name: language.messages.gridColumCEntityCode,
        sortable: true,
        wrap: true,
        width: '15%',
        cell: (row) => (
          <div className=" text-uppercase text-primary small">
            {row.society.code}
          </div>
        ),
      },
      {
        name: language.messages.gridColumCEntitySocietyId,
        sortable: true,
        wrap: true,
        width: '45%',
        cell: (row) => (
          <div className=" text-uppercase small">{row.society.name}</div>
        ),
      },
    ];
    updateState(data.columns, 'columns');
  };

  //#endregion LIFECYCLE

  //#region HANDLES

  const refreshState = () => {
    setData({
      ...data,
    });
  };
  const updateState = (value, id) => {
    setData({
      ...data,
      [id]: value,
    });
  };
  const handleDelete = () => {
    disableData();
  };
  const handleSelectRow = (state) => {
    updateState(state.selectedRows, 'selectedRows');
  };
  const updateSelect = async (value, id) => {
    switch (id) {
      case 'user':
        data.user = value;
        await loadUserSocieties(data.user.value, data.auth.id.user.username);
        break;
      case 'country':
        data.country = value;
        data.societyId = { value: '', label: '', error: '' };
        loadSocieties(value.value);
        break;
      case 'societyId':
        data.societyId = value;
        break;
      default:
        break;
    }
    refreshState();
  };
  //#endregion HANDLES

  //#region FUNCTIONS
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const contextActions = React.useMemo(() => {
    return (
      <Button
        key="delete"
        onClick={handleDelete}
        className="btn btn-danger-up"
        type="button"
        disabled={!data.enable}
      >
        {' '}
        <i className="fa fa-trash"></i>
        {language.messages.btnDisable}
      </Button>
    );
  });
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
  }));
  const LinearIndeterminate = () => {
    const classes = useStyles();
    return (
      <div className={classes.root}>
        <LinearProgress />
      </div>
    );
  };
  const paginationOptions = {
    rowsPerPageText: language.messages.rowsPerPageText,
    rangeSeparatorText: language.messages.rangeSeparatorText,
    selectAllRowsItem: true,
    selectAllRowsItemText: language.messages.selectAllRowsItemText,
  };
  const viewNotification = (msm, action) => {
    addToast(msm, {
      appearance: action,
      autoDismiss: true,
    });
  };
  const clearError = () => {
    data.errors = [];
    data.user.error = '';
    data.country.error = '';
    data.societyId.error = '';
  };
  const clearForm = () => {
    data.id = 0;
    //data.user = { value: 0, label: '', error: '' };
    data.country = { value: 0, label: '', error: '' };
    data.societyId = { value: '', label: '', error: '' };
  };
  const validate = () => {
    clearError();

    if (es_vacio(data.user.value)) {
      data.errors.push('user.error');
      data.user.error = language.messages.warning_required_field;
    }
    if (es_vacio(data.country.value)) {
      data.errors.push('country.error');
      data.country.error = language.messages.warning_required_field;
    }
    if (es_vacio(data.societyId.value)) {
      data.errors.push('societyId.error');
      data.societyId.error = language.messages.warning_required_field;
    }
    refreshState();
  };
  const cleaningForm = () => {
    clearError();
    clearForm();
    refreshState();
    viewNotification(language.messages.successCleanData, 'success');
  };
  const reloadData = () => {
    data.loadingUser = false;
    data.showLoadingCountry = false;
    data.pendingData = true;
    updateState(false, 'enable');
    loadUserSocieties(data.user.value, data.auth.id.user.username);
    loadUsers();
    loadCountries();
  };
  const disableData = async () => {
    let deleteData = true;
    if (data.selectedRows.length > 0) {
      data.selectedRows.forEach((element) => {
        if (element.user.id !== data.auth.id.user.userId) {
          element.status = 0;
        } else deleteData = false;
      });
      if (deleteData) {
        data.pendingData = true;
        data.enable = false;
        updateState(false, 'showDelete');
        let res = await dispatch(
          deleteUserSociety(data.selectedRows, data.auth.id.user.username)
        );
        if (res.status === 200) {
          viewNotification(language.messages.msmDeleteAll, 'success');
          data.userSocieties = data.userSocieties.filter(
            (x) => !data.selectedRows.includes(x)
          );
          data.enable = true;
          data.showDelete = true;
          data.pendingData = false;
          clearForm();
          reloadData();
        } else {
          switch (res.response.status) {
            case 403:
              if (
                res.response.data.error ===
                language.messages.messageNoAccessRemove
              ) {
                viewNotification(
                  language.messages.messageNoAccessRemove,
                  'error'
                );
                return null;
              } else {
                viewNotification(res.response.data, 'error');
                data.enable = false;
              }
              break;
            default:
              viewNotification(language.messages.msmErrorExist, 'error');
              data.enable = true;
              break;
          }
        }
        data.showDelete = true;
        data.pendingData = false;
        reloadData();
      } else {
        viewNotification(language.messages.msmCurrentUserDisabled, 'error');
        data.pendingData = false;
      }
    } else viewNotification(language.messages.msmEmptyDataDisabled, 'error');
  };
  const saveData = async () => {
    validate();
    if (data.errors.length === 0) {
      data.pendingData = true;
      updateState(false, 'enable');
      let json = {
        id: data.id,
        user: { id: data.user.value },
        society: { id: data.societyId.value },
        status: 1,
        userAdmin: data.auth.id.user.username,
      };

      let res = await dispatch(saveUserSociety(json, data.auth.id.user.username));
      if (res.status === 200) {
        switch (res.data) {
          case 'OK':
            viewNotification(
              language.messages.msmRegisterDataSuccess,
              'success'
            );
            loadUserSocieties(data.user.value, data.auth.id.user.username);
            clearError();
            clearForm();
            refreshState();
            break;
          case 'EXIST':
            viewNotification(
              language.messages.msmErrorExistUserSocietyName,
              'error'
            );
            data.pendingData = false;
            break;
          default:
            break;
        }
      } else {
        switch (res.response.status) {
          case 500:
            viewNotification(language.messages.msmErrorExist, 'error');
            data.pendingData = false;
            break;
          case 403:
            if (
              res.response.data.error ===
              language.messages.messageNoAccessRegister
            ) {
              viewNotification(
                language.messages.messageNoAccessRegister,
                'error'
              );
              return null;
            } else {
              viewNotification(res.response.data, 'error');
              data.pendingData = false;
            }
            break;
          default:
            viewNotification(language.messages.msmErrorExist, 'error');
            data.pendingData = false;
            break;
        }
      }
      updateState(true, 'enable');
    } else {
      viewNotification(language.messages.msmErrorForm, 'error');
    }
  };

  const changeUser = async (value) => {
    if (value === null) {
      data.user = { value: 0, label: '', error: '' };
    } else {
      data.user = value;
      
    }
    await loadUserSocieties(data.user.value, data.auth.id.user.username);
    refreshState();
  };

  const promiseOptions = (inputValue) =>
    new Promise(async (resolve) => {
      resolve(loadDataUser(inputValue));
    });
  const loadDataUser = async (inputValue) => {
    if (inputValue !== undefined && inputValue.length > 3) {
      await dispatch(findByUsername(inputValue));
      return data.users;
    }
  };

  //#endregion FUNCTIONS

  return (
    <Fragment>
    <SessionConfig ref={ref}/>
      <br />
      <Container fluid={true}>
        <Row>
          <Col sm={12} md={5} lg={5} xl={4}>
            <Card
              icono=" fa fa-save"
              title={language.messages.titleUserSocietyForm}
              content={
                <form autoComplete="off">
                  <Alert className="alert-primary">
                    <strong className="text-danger small">
                      {language.messages.labelSymbol}
                    </strong>
                    <span className="small">
                      {' '}
                      {language.messages.labelSymbolRequired}
                    </span>
                  </Alert>
                  <FormGroup>
                    <LabelTitle
                      text={language.messages.labelUsers}
                      requerid={true}
                    />
                    <AsyncSelect
                      cacheOptions
                      className="small text-uppercase"
                      placeholder={language.messages.lblSingleSelect}
                      name="user"
                      loadOptions={promiseOptions}
                      isClearable={true}
                      value={{
                        value: data.user.value,
                        label: data.user.label,
                      }}
                      onChange={(value) => {
                        changeUser(value);
                      }}
                    />
                    <LabelChargeData showloading={data.loadingUser} />
                    <span className="small text-danger">{data.user.error}</span>
                  </FormGroup>
                  <FormGroup>
                    <LabelTitle
                      text={language.messages.labelCountry}
                      requerid={true}
                    />
                    <LabelChargeData showloading={data.showLoadingCountry} />
                    <Select
                      placeholder={language.messages.lblSingleSelect}
                      name="country"
                      options={data.countries}
                      className="text-uppercase small"
                      value={{
                        value: data.country.value,
                        label: data.country.label,
                      }}
                      onChange={(value) => {
                        updateSelect(value, 'country');
                      }}
                    />
                    <span className="small text-danger">
                      {data.country.error}
                    </span>
                    <div className="small text-danger">{data.country.auth}</div>
                  </FormGroup>
                  <FormGroup>
                    <LabelTitle
                      text={language.messages.labelSocieties}
                      requerid={true}
                    />
                    <LabelChargeData showloading={data.showLoadingSociety} />
                    <Select
                      placeholder={language.messages.lblSingleSelect}
                      name="society"
                      options={data.societies}
                      className="text-uppercase small"
                      value={{
                        value: data.societyId.value,
                        label: data.societyId.label,
                      }}
                      onChange={(value) => {
                        updateSelect(value, 'societyId');
                      }}
                    />
                    <span className="small text-danger">
                      {data.societyId.error}
                    </span>
                    <div className="small text-danger">
                      {data.societyId.auth}
                    </div>
                  </FormGroup>
                  <Row>
                  <Col md={12} xl={8}>
                    <ButtonGroup className="d-flex">
                      <Button
                        disabled={!data.enable}
                        fill
                        font_small
                        type="button"
                        onClick={saveData}
                      >
                        <i className="fa fa-save"></i>{' '}
                        {data.id === 0
                          ? language.messages.btnSave
                          : language.messages.btnEdit}
                      </Button>
                      <Button
                        disabled={!data.enable}
                        info
                        fill
                        font_small
                        type="button"
                        onClick={cleaningForm}
                      >
                        <i className="fa fa-magnet"></i>{' '}
                        {language.messages.btnClear}
                      </Button>
                      <Button
                        disabled={!data.enable}
                        warning
                        fill
                        font_small
                        type="button"
                        onClick={reloadData}
                      >
                        <i className="fa fa-repeat"></i>{' '}
                        {language.messages.btnReload}
                      </Button>
                    </ButtonGroup>
                  </Col>
                  </Row>
                </form>
              }
            />
          </Col>
          <Col sm={12} md={7} lg={7} xl={8}>
            <Card
              icono="fa fa-list"
              title={language.messages.gridUserSocietyTitle}
              content={
                <div className="fresh-datatables">
                  <DataTable
                    title={
                      <span className="small text-primary">
                        {data.user.label}
                      </span>
                    }
                    noHeader={es_vacio(data.user.label)}
                    data={data.userSocieties}
                    columns={data.columns}
                    fixedHeader
                    fixedHeaderScrollHeight="400px"
                    selectableRows
                    dense
                    onSelectedRowsChange={handleSelectRow}
                    pointerOnHover={true}
                    highlightOnHover
                    pagination
                    paginationComponentOptions={paginationOptions}
                    progressPending={data.pendingData}
                    progressComponent={<LinearIndeterminate />}
                    contextActions={contextActions}
                    persistTableHead
                    clearSelectedRows={data.showDelete}
                  />
                </div>
              }
            />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
const App = () => (
  <ToastProvider>
    <UserSociety />
  </ToastProvider>
);
export default App;
