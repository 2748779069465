import service from "../api/userRole.service";
import loginService from "../api/login.service";
import userService from "../api/user.service";
import language from "../config/es";
//constants
const dataUser = {
  userRoles: [],
  updateUserRol: [],
  roles: [],
  userRoleAdd: "",
  responseUserRole: null,
  userRole: null,
  noPermitted: "",
  errorUserRole: "",
  totalUserRol: 0,
  editUser: null
};
//types
const LIST_USER_ROLE = "LIST_USER_ROLE";
const LIST_ROLES = "LIST_ROLES";
const ADD_USER_ROLE = "ADD_USER_ROLE";
const UPDATE_USER_ROLE = "UPDATE_USER_ROLE";
const DELETE_USER_ROLE = "DELETE_USER_ROLE";
const FIND_USER_ROLE = "FIND_USER_ROLE";
const LIST_ERROR = "LIST_ERROR";
const ERROR_USER_ROLE = "ERROR_USER_ROLE";
const COUNT_USER_ROLE = "COUNT_USER_ROLE";
const UPDATE_USER = "UPDATE_USER";
const EDIT_USER = "EDIT_USER";

//reducer
export default function userRoleReducer(state = dataUser, action) {
  switch (action.type) {
    case LIST_USER_ROLE:
      return { ...state, userRoles: action.payload };
    case ADD_USER_ROLE:
      return { ...state, userRoleAdd: action.payload };
    case UPDATE_USER_ROLE:
      return { ...state, updateUserRol: action.payload };
    case FIND_USER_ROLE:
      return { ...state, userRole: action.payload };
    case LIST_ROLES:
      return { ...state, roles: action.payload };
    case LIST_ERROR:
      return { ...state, noPermitted: action.payload };
    case ERROR_USER_ROLE:
      return { ...state, errorUserRole: action.payload };
    case COUNT_USER_ROLE:
      return { ...state, totalUserRol: action.payload };
    case EDIT_USER:
      return { ...state, editUser: action.payload };
    case UPDATE_USER:
      return { ...state };
    default:
      return { ...state };
  }
}
export const findByUser = (user) => async (dispatch) => {
  const response = await service.findByUser(user).then((r) => {
    return r.data;
  });
  dispatch({
    type: FIND_USER_ROLE,
    payload: response,
  });
  return response;
};

export const findAll = (auth) => async (dispatch) => {
  const response = await service.getAllUserRoles(auth).then((r) => {
    return r;
  });
  dispatch({
    type: LIST_USER_ROLE,
    payload: response,
  });
  return response;
};

export const findAllRoles = (auth) => async (dispatch) => {
  let data = [];
  const response = await service.getAllRoles(auth).then((r) => {
    return r.data;
  });
  if (response != null) {
    response.forEach((element) => {
      data.push({
        value: element.roleId,
        label: element.name.toUpperCase(),
        societyId: element.societyId,
      });
    });
  } else {
    dispatch({
      type: LIST_ERROR,
      payload: language.messages.branch.error_list,
    });
  }
  dispatch({
    type: LIST_ROLES,
    payload: data,
  });
};

export const findAllRolesBySystem = (system, auth) => async (dispatch) => {
  let data = [];
  const response = await service.getAllRolesBySystem(system, auth).then((r) => {
    return r.data;
  });
  if (response != null) {
    response.forEach((element) => {
      data.push({
        value: element.roleId,
        label: element.name.toUpperCase(),
        societyId: element.societyId,
      });
    });
  } else {
    dispatch({
      type: LIST_ERROR,
      payload: language.messages.branch.error_list,
    });
  }
  dispatch({
    type: LIST_ROLES,
    payload: data,
  });
};

export const saveUserRole = (user_role, jsonBody, auth) => async (dispatch) => {
  const userValues = user_role === 0 ? "" : user_role.split("-");
  const cognito = user_role === 0 ? jsonBody.id.user.id : userValues[0];
  const jsonUsr = {
    cognitoId: cognito,
    username: cognito,
    email: cognito,
    sessionIp: "",
    name: "",
    codeSystem: process.env.REACT_APP_SYSTEM_CODE,
    registerLog: false,
  };
  await loginService.registerUser(jsonUsr);
  const responseRegUser = await userService.getUserByUsername(jsonUsr);
  if (user_role === 0) jsonBody.id.user.id = responseRegUser?.data?.id;
  else {
    user_role = `${responseRegUser?.data?.id}-${userValues[1]}-${userValues[2]}-${userValues[3]}`

  }

  const response = await service
    .saveUserRole(user_role, jsonBody, auth)
    .then((r) => {
      return r.data !== undefined ? r.data : r.response.data;
    });

  if (response.error !== undefined) {
    dispatch({
      type: ERROR_USER_ROLE,
      payload: response,
    });
    dispatch({
      type: ADD_USER_ROLE,
      payload: "",
    });
  } else {
    dispatch({
      type: ERROR_USER_ROLE,
      payload: "",
    });
    dispatch({
      type: ADD_USER_ROLE,
      payload: response,
    });
  }
};

export const updateUserRole = (json, isNew, auth) => async (dispatch) => {
  const response = await service.updateUserRole(json, isNew, auth).then((r) => {
    return r;
  });
  dispatch({
    type: UPDATE_USER_ROLE,
    payload: response,
  });
  return response;
};

export const deleteUserRole = (lsUserRole, auth) => async (dispatch) => {
  const response = await service.disableUserRole(lsUserRole, auth).then((r) => {
    return r;
  });

  dispatch({
    type: DELETE_USER_ROLE,
    payload: response,
  });
  return response;
};
export const findByRol = (user, role) => async (dispatch) => {
  const response = await service.findDataByRol(user, role).then((r) => {
    return r.data !== undefined ? r.data : r.response.data;
  });

  if (response.code !== undefined) {
    dispatch({
      type: ERROR_USER_ROLE,
      payload: response,
    });
    dispatch({
      type: LIST_USER_ROLE,
      payload: [],
    });
  } else {
    dispatch({
      type: ERROR_USER_ROLE,
      payload: "",
    });
    dispatch({
      type: LIST_USER_ROLE,
      payload: response,
    });
  }
};
export const findRolUserByPaginate = (user, filter) => async (dispatch) => {
  const response = await service
    .getAllUserRolesByFilter(user, filter)
    .then((r) => {
      return r.data !== undefined ? r.data : r.response.data;
    });

  if (response.code !== undefined) {
    dispatch({
      type: ERROR_USER_ROLE,
      payload: response,
    });
    dispatch({
      type: LIST_USER_ROLE,
      payload: [],
    });
  } else {
    dispatch({
      type: ERROR_USER_ROLE,
      payload: "",
    });
    dispatch({
      type: LIST_USER_ROLE,
      payload: response,
    });
  }
};
export const countByPaginate = (filter) => async (dispatch) => {
  const response = await service.getCountByFilter(filter).then((r) => {
    return r.data !== undefined ? r.data : r.response.data;
  });

  if (response.code !== undefined) {
    dispatch({
      type: ERROR_USER_ROLE,
      payload: response,
    });
    dispatch({
      type: COUNT_USER_ROLE,
      payload: 0,
    });
  } else {
    dispatch({
      type: ERROR_USER_ROLE,
      payload: "",
    });
    dispatch({
      type: COUNT_USER_ROLE,
      payload: response,
    });
  }
};

export const updateUser = (obj) => async (dispatch) => {
  const response = await loginService.updateUser(obj).then((r) => {
    return r.data !== undefined ? r.data : r.response.data;
  });
  dispatch({
    type: UPDATE_USER,
    payload: response,
  });
  return response;
}

export const getUsetByUsername = (username) => async (dispatch) => {
  const response = await loginService.getUser(username).then((r) => {
    return r.data !== undefined ? r.data : r.response.data;
  });
  dispatch({
    type: EDIT_USER,
    payload: response,
  });
  return response;
}
