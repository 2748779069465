import React, { Fragment } from 'react';
import language from '../../config/es';

const LabelTitle = (props) => {
   
  const { text = "", requerid=false } = props;

  return (
    <Fragment>
      <label className="text-muted small text-uppercase">
        {text}
        <span className={requerid?"text-danger":"noDisplay"}>{language.messages.caracter}</span>
      </label>
    </Fragment>
  );
};

export default LabelTitle;
