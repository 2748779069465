import { getService,postBodyService, postBodyServiceWithOutVerify } from "./base.service";
import config from "../config/application";

//url
const list = 'user/list';
const add = 'user/add';
const update = 'user/update';
const deleteUrl = 'user/delete';
const listByUsername = 'user/searchUsername';

class UserService {
    getUsers() {
        const url = `${config.data.security.user.url}${list}`;
        return getService(url);
    }
    logout(user){
        const url = `${config.data.security.user.url}${add}`;
        return postBodyServiceWithOutVerify(url,user);
    }
    updateUser(user){
        const url = `${config.data.security.user.url}${update}`;
        return postBodyService(url,user);
    }
    deleteUser(lsUsers){
        const url = `${config.data.security.user.url}${deleteUrl}`;
        return postBodyService(url,lsUsers);
    }
    getUserByUsername(user){
        const url = `${config.data.security.user.url}${listByUsername}`;
        return postBodyService(url,user);
    }
}
// eslint-disable-next-line import/no-anonymous-default-export
export default new UserService();