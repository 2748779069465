import {postBodyService} from "./base.service";
import config from "../config/application";
//url
const findRootUrl = 'menu/findRoot';
const addUrl = 'menu/add';
const deleteUrl = 'menu/delete';
const deleteByTransactionUrl = 'menu/deleteByTransaction';
const findByTransactionUrl = 'menu/findByTransaction';

class MenuService {
    findRoot(rol){
        const url = `${config.data.security.menu.url}${findRootUrl}`;
        return postBodyService(url, rol);
    }
    saveMenu(menu){
        const url = `${config.data.security.menu.url}${addUrl}`;
        return postBodyService(url,menu);
    }
    deleteMenu(lsMenu){
        const url = `${config.data.security.menu.url}${deleteUrl}`;
        return postBodyService(url,lsMenu);
    }
    deleteMenuByTransaction(menu){
        const url = `${config.data.security.menu.url}${deleteByTransactionUrl}`;
        return postBodyService(url,menu);
    }

    findByTransaction(menu){
        const url = `${config.data.security.menu.url}${findByTransactionUrl}`;
        return postBodyService(url,menu);
    }
    
}
// eslint-disable-next-line import/no-anonymous-default-export
export default new MenuService(); 