import React, { Component } from "react";
class PagesHeader extends Component {
    render() {
      return (
        <header className="footer">
          {/* <Container  fluid>
            <nav className="pull-left">
              <ul>
                <li>
                  <span className="navbar-brand text-dark text-uppercase" href="#">{language.messages.title_system}</span>
                </li>
                <li>
                  <span className=" navbar-brand  text-info">{language.messages.title}</span>
                </li>

              </ul>
            </nav>
           
          </Container > */}
        </header>
      );
    }
  }
  
  export default PagesHeader;
