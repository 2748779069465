import React, { Fragment, useState, useEffect } from 'react';
import { ToastProvider, useToasts } from 'react-toast-notifications';
import { useDispatch } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import background from '../../assets/img/header.jpg';
import language from '../../config/es';
import { Amplify, I18n, Auth, Hub } from 'aws-amplify';
import awsConfig from '../../aws-exports';
import './management.scss';
import {logInUserData} from '../../redux/login.duck';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { purple } from '@material-ui/core/colors';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Lottie from "lottie-react";
import loadingAD from "./load-fragmented-square.json";
import securityCenter from "./security-loading.json";

Amplify.configure(awsConfig);
I18n.setLanguage('es');

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  sign: {
    margin: theme.spacing(3, 1, 2),
    padding: 35,
  },
}));

const Login = () => {
  const classes = useStyles();
  const [setUser] = useState('');
  const { addToast, removeAllToasts } = useToasts();
  const dispatch = useDispatch();
  const [data, setData] = useState({
    disable: false,
    user: null,
    menu: null,
    module: null,
    node: null,
    enable: true
  });
  const updateState = (value, id) => {
    setData({
      ...data,
      [id]: value
    });
  };

  const [dataLoad, setDataLoad] = useState({
    showLoading: false
  });
  const updateStateLoad = (value, id) => {
    setDataLoad({
      ...dataLoad,
      [id]: value
    });
  };
  useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
          viewNotification(language.messages.loginADSuccessAwait,'success');
          updateStateLoad(true, "showLoading");
          break;
        case 'cognitoHostedUI':
          handleSubmit();
          break;
        case 'signOut':
          setUser(null);
          break;
        case 'signIn_failure':
          updateStateLoad(false, "showLoading");
          break;
        case 'cognitoHostedUI_failure':
          updateStateLoad(false, "showLoading");
          break;
        default:
          break;
      }
    });

  });
  const viewNotification = (msm, action) => {
    removeAllToasts();
    addToast(msm, {
      appearance: action,
      autoDismiss: true,
    });
  };

  const handleSubmit = async () => {
    try {
      updateState(false,"enable");
      let user = await dispatch(logInUserData());
      
      if (user === undefined) {
        updateState(true,"enable");
        viewNotification(
          language.messages.loginUsernameExistError + '1',
          'error'
        );
        updateStateLoad(false, "showLoading");
      } else {
        const errorSession = JSON.parse(sessionStorage.getItem("errorSession"));
        if(errorSession === null) {
          viewNotification(language.messages.login.message_login_success,'success');
          window.location.href = 'home';
        } else {
          resetLogin();
          viewNotification(errorSession.error,'warning');
        }
      }
    } catch (e) {
      viewNotification(e.message, 'error');
      resetLogin();
    }
  };

  const resetLogin = () => {
    updateState(true,"enable");
    updateStateLoad(false, "showLoading");
    setTimeout(() => {
      Auth.signOut();
    }, 2000);
  }

  const showFormLoading = (option) => {
    switch (option) {
      case "showMeForm":
        viewNotification(language.messages.loginADRedirect,'warning');
        updateStateLoad(true, "showLoading");
        Auth.federatedSignIn({provider: process.env.REACT_APP_AD_PROVIDER})
        break;
      case "hideForm":
        updateStateLoad(false, "showLoading");
        break;
      default:
        updateStateLoad(false, "showLoading");;
    }
  }
 
  const ColorButtonAD = withStyles((theme) => ({
    root: {
      color: theme.palette.getContrastText(purple[500]),
      backgroundColor: purple[500],
      '&:hover': {
        backgroundColor: purple[700],
      },
    },
  }))(Button);

  return (
    <Fragment>
      <Grid container component="main" style={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={6}
          style={{
            backgroundImage: 'url(' + background + ')',
            backgroundRepeat: 'no-repeat',
            backgroundColor: 'white',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={6} component={Paper} elevation={6} square>
          <div className="login-panel">
              {!dataLoad.showLoading?
                <div className="title-header-animation">
                  <Lottie animationData={securityCenter} />
                  <h5>{language.messages.title_system}</h5>
                </div>: ""
              }
              {!dataLoad.showLoading?<div className="row my-3 d-flex justify-content-center">
              <ColorButtonAD variant="contained" color="primary" 
              className={classes.sign} startIcon={<OpenInNewIcon />} 
              size="large" onClick={() => showFormLoading("showMeForm")}>
              <strong>{language.messages.login.lbl_sign_in}</strong>
              </ColorButtonAD>
            </div>: <div className="login-panel">
              <Lottie animationData={loadingAD} />;
            </div>}
          </div>
        </Grid>
      </Grid>
    </Fragment>
  );
};

const App = () => (
  <ToastProvider>
    <Login />
  </ToastProvider>
);
export default App;
