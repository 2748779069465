const configApp = {
    concept: {
        channel: 'CHANNEL',
        currency: 'CURRENCY',
        channelTransaction: 'CHANNEL_TRANSACTION',
        documentType: 'DOCUMENT_TYPE',
        documentTypePayment: 'TIPO_RECAUDACION',
        typePayment: 'TIPO_PAGO',
        typeMessage: 'MESSAGE',
        statusPayment: 'STATUS_PAYMENT'
    },
    type: {
    },
    action:{
        add:'ADD',
        edit:'UPDATE',
        list:'VIEW',
        delete:'DELETE',
        client:'CLIENT',
        seller:'SELLER'
    }
}
// eslint-disable-next-line import/no-anonymous-default-export
export default {
    configApp
};