import React, { Fragment, useState, useEffect, useRef } from 'react';
import { ToastProvider, useToasts } from 'react-toast-notifications';
import { Container, Row, Col } from 'react-bootstrap';
import language from '../../config/es';
import Card from '../../components/Card/Card';
import LabelTitle from '../../components/Label/labelTitle';
import LabelChargeData from '../../components/Label/labelChargeData';
import { es_vacio, isGreaterThan } from '../../config/validate';
import LinearIndeterminate from '../../components/LinearIndeterminate/LinearIndeterminate';
import Button from '../../components/CustomButton/CustomButton';
import DataTable from 'react-data-table-component';
import { FormGroup, ButtonGroup, Alert, Form } from 'react-bootstrap';
//redux
import { useDispatch, useSelector } from 'react-redux';
import {
  findRawCountries,
  saveCountry,
  updateCountry,
  deleteCountry,
} from '../../redux/country.duck';
//Session user
import SessionConfig from '../../config/session_config';

const Country = () => {
  const ref = useRef();
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const [selectedRows, setSelectedRows] = useState([]);
  const [data, setData] = useState({
    pendingData: false,
    enable: true,
    name: { value: '', error: '' },
    code: { value: '', error: '' },
    dataRows: [],
    errors: [],
    columns: [],
    id: null,
    showDelete: false,
    auth: JSON.parse(sessionStorage.getItem('user')),
    error: '',
  });
  data.dataRows = useSelector((store) => store.countryReducer.countries);
  data.error = useSelector((store) => store.countryReducer.noPermitted);
  //ciclo de vida ---------------------------------------------------
  useEffect(() => {
    messageError();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.error]);
  useEffect(() => {
    initColumns();
    loadCountries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    ref.current.onRefreshCurrentSession();
  }, [data]);
  //hadler ---------------------------------------------------
  //#region handler
  const onChange = (event) => {
    switch (event.target.name) {
      case 'name':
        data.name.value = event.target.value;
        data.name.error = '';
        break;
      case 'code':
        data.code.value = event.target.value;
        data.code.error = '';
        break;
      default:
        break;
    }
    refreshState();
  };
  const updateState = (value, id) => {
    setData({
      ...data,
      [id]: value,
    });
  };
  const refreshState = () => {
    setData({
      ...data,
    });
  };
  const handleSelectRow = (state) => {
    setSelectedRows(state.selectedRows);
  };
  const handleButtonEdit = (row) => {
    data.id = row.id;
    data.name.value = row.name;
    data.code.value = row.code;

    refreshState();
  };
  const handleDelete = () => {
    disableData();
  };
  //#endregion
  //functions ---------------------------------------------------
  const messageError = () => {
    if (data.error !== '') {
      viewNotification(data.error.error, 'error');
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const contextActions = React.useMemo(() => {
    return (
      <Button
        key="delete"
        onClick={handleDelete}
        className="btn btn-danger-up"
        type="button"
        disabled={!data.enable}
      >
        {' '}
        <i className="fa fa-trash"></i>
        {language.messages.btnDisable}
      </Button>
    );
  });
  const initStateDelete = (value) => {
    data.enable = value;
    data.showDelete = value;
    data.pendingData = !value;
    refreshState();
  };
  const disableData = async () => {
    let lsDisable = [];
    if (selectedRows.length > 0) {
      selectedRows.forEach((element) => {
        lsDisable.push({ id: element.id });
      });
      initStateDelete(false);

      let response = await dispatch(
        deleteCountry(lsDisable, data.auth.id.user.username)
      );

      if (response.error !== undefined) {
        initStateDelete(true);
      } else {
        viewNotification(language.messages.msmDisableAll, 'success');
        data.enable = true;
        data.showDelete = true;
        data.pendingData = false;
        reloadData();
      }
    }
  };

  const clearError = async () => {
    data.errors = [];
    data.name.error = '';
    data.code.error = '';
  };
  const loadCountries = async () => {
    updateState(true, 'pendingData');
    let lsCountries = await dispatch(
      findRawCountries(data.auth.id.user.username)
    );
    if (lsCountries.code !== undefined) {
      data.dataRows = [];
    } else {
      data.dataRows = lsCountries;
    }
    updateState(false, 'pendingData');
  };

  const initColumns = () => {
    data.columns = [
      {
        name: language.messages.lblCode,
        sortable: false,
        wrap: true,
        width: '100px',
        cell: (row) => (
          <span>
            <div className="text-primary text-uppercase small">{row.code}</div>
          </span>
        ),
      },
      {
        name: language.messages.lblName,
        sortable: false,
        wrap: true,
        width: '300px',
        cell: (row) => (
          <span>
            <div className=" text-uppercase small">{row.name}</div>
          </span>
        ),
      },
      {
        name: language.messages.lblFunctions,
        cell: (row) => (
          <button
            className="btn-fill btn btn-xs  btn-primary"
            type="button"
            onClick={() => {
              handleButtonEdit(row);
            }}
            id={row.id}
          >
            <i className="fa fa-pencil"></i>
          </button>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
        width: '10px',
      },
    ];
    refreshState();
  };
  const paginationOptions = {
    rowsPerPageText: language.messages.rowsPerPageText,
    rangeSeparatorText: language.messages.rangeSeparatorText,
    selectAllRowsItem: true,
    selectAllRowsItemText: language.messages.selectAllRowsItemText,
  };
  const validate = () => {
    clearError();

    if (es_vacio(data.name.value)) {
      data.errors.push('name.error');
      data.name.error = language.messages.warning_required_field;
    }
    if (es_vacio(data.code.value)) {
      data.errors.push('code.error');
      data.code.error = language.messages.warning_required_field;
    }

    if (!isGreaterThan(5, data.code.value.length)) {
      data.errors.push('code.error');
      data.code.error = language.messages.society.errorCode;
    }
    refreshState();
  };

  const clearForm = () => {
    data.name = { value: '', error: '' };
    data.code = { value: '', error: '' };
    data.id = null;
    refreshState();
  };
  const saveData = async () => {
    validate();

    if (data.errors.length === 0) {
      updateState(false, 'enable');
      let json = {
        id: data.id,
        name: data.name.value,
        code: data.code.value,
        status: 1,
        userAdmin: data.auth.id.user.username,
        authUser: data.auth.id.user.userId,
        societyId: '',
      };

      let response = '';
      if (data.id === null) {
        response = await dispatch(
          saveCountry(json, data.auth.id.user.username)
        );
      } else {
        response = await dispatch(
          updateCountry(json, data.auth.id.user.username)
        );
      }

      if (response.error === undefined) {
        updateState(true, 'pendingData');
        await reloadData();

        viewNotification(
          language.messages.msmRegister.replace('{0}', data.name.value),
          'success'
        );
        clearForm();
        updateState(false, 'pendingData');
      } else {
        updateState(true, 'enable');
      }
    }
  };
  const viewNotification = (msm, action) => {
    addToast(msm, {
      appearance: action,
      autoDismiss: true,
    });
  };
  const cleaningForm = () => {
    clearError();
    clearForm();

    viewNotification(language.messages.success_clean_data, 'success');
  };
  const reloadData = async () => {
    data.enable = false;
    await loadCountries();
    viewNotification(language.messages.success_reload_data, 'success');
    data.enable = true;
    refreshState();
  };
  const reloadDataEnable = async () => {
    await loadCountries();
    refreshState();
  };
  //render----------------------------------------------------
  return (
    <Fragment>
    <SessionConfig ref={ref}/>
      <br />
      <Container fluid={true}>
        <Row>
          <Col sm={12} md={5} lg={5} xl={4}>
            <Card
              icono=" fa fa-database"
              title={language.messages.country.title_country_form}
              content={
                <Form autoComplete="off">
                  <Alert className="alert-primary ">
                    <strong className="text-danger small">
                      {language.messages.lblSimbolo}
                    </strong>
                    <span className="small">
                      {' '}
                      {language.messages.lblfieldsRequired}
                    </span>
                  </Alert>

                  <Row>
                    <Col md={8} sm={12}>
                      <FormGroup>
                        <LabelTitle
                          text={language.messages.lblName}
                          requerid={true}
                        />
                        <Form.Control
                          size="sm"
                          type="text"
                          name="name"
                          id="name"
                          value={data.name.value}
                          onChange={onChange}
                        />
                        <span className="small text-danger">
                          {data.name.error}
                        </span>
                      </FormGroup>
                    </Col>
                    <Col md={4} sm={12}>
                      <FormGroup>
                        <LabelTitle
                          text={language.messages.lblCode}
                          requerid={true}
                        />
                        <Form.Control
                          size="sm"
                          type="text"
                          name="code"
                          id="code"
                          value={data.code.value}
                          onChange={onChange}
                        />
                        <span className="small text-danger">
                          {data.code.error}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <LabelChargeData
                    label={language.messages.msmRegisterDataLoading}
                    showloading={data.enable}
                  />
                  <Row>
                    <Col>
                      <ButtonGroup className="d-flex">
                        <Button
                          disabled={!data.enable}
                          primary
                          fill
                          font_small
                          type="button"
                          onClick={saveData}
                        >
                          <i className="fa fa-save"></i>{' '}
                          {data.id === null
                            ? language.messages.btnSave
                            : language.messages.btnEdit}
                        </Button>
                        <Button
                          disabled={!data.enable}
                          info
                          fill
                          font_small
                          type="button"
                          onClick={cleaningForm}
                        >
                          <i className="fa fa-magnet"></i>{' '}
                          {language.messages.btnClear}
                        </Button>
                        <Button
                          disabled={!data.enable}
                          warning
                          fill
                          font_small
                          type="button"
                          onClick={reloadDataEnable}
                        >
                          <i className="fa fa-refresh"></i>{' '}
                          {language.messages.btnReload}
                        </Button>
                      </ButtonGroup>
                    </Col>
                  </Row>
                </Form>
              }
            />
          </Col>
          <Col sm={12} md={7} lg={7} xl={8}>
            <Card
              icono=" fa fa-list"
              title={language.messages.country.title_country_register}
              content={
                <div className="fresh-datatables">
                  <DataTable
                    noHeader={selectedRows.length === 0}
                    data={data.dataRows}
                    columns={data.columns}
                    fixedHeader
                    fixedHeaderScrollHeight="500px"
                    dense
                    selectableRows
                    onSelectedRowsChange={handleSelectRow}
                    highlightOnHover
                    pagination
                    paginationComponentOptions={paginationOptions}
                    progressPending={data.pendingData}
                    progressComponent={<LinearIndeterminate />}
                    persistTableHead
                    contextActions={contextActions}
                    clearSelectedRows={data.showDelete}
                  />
                </div>
              }
            />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
const App = () => (
  <ToastProvider>
    <Country />
  </ToastProvider>
);
export default App;
