/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    Auth: {
        // REQUIRED - Amazon Cognito Region
        region: process.env.REACT_APP_REGION,

        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: process.env.REACT_APP_USER_POOL,

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: process.env.REACT_APP_CLIENT_ID,

        // OPTIONAL - Hosted UI configuration
        oauth: {
            domain: process.env.REACT_APP_DOMAIN,
            scope: ['phone', 'email', 'profile', 'openid'],
            redirectSignIn: process.env.REACT_APP_REDIRECT_SIGN_IN,
            redirectSignOut: process.env.REACT_APP_REDIRECT_SIGN_OUT,
            responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
        }
    }

};


export default awsmobile;
