/* eslint-disable no-unused-vars */
import React, { useState, Fragment, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import language from '../../config/es';
import LinearIndeterminate from '../LinearIndeterminate/LinearIndeterminate';
import LabelTitle from '../Label/labelTitle';
import { Form, Row, Col } from 'react-bootstrap';
import Button from '../../components/CustomButton/CustomButton';

//redux
import { useDispatch, useSelector } from 'react-redux';
import { findAll, searchSociety } from '../../redux/society.duck';
import { es_vacio } from 'config/validate';
const SocietyList = (props) => {
  const dispatch = useDispatch();
  const {
    visible = true,
    onSelectSociety,
    clear = false,
    societies = [],
  } = props;
  const [pendingData, setPendingData] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  let [dataRows, setDataRows] = useState([]);
  let [backup] = useState([]);
  const [columns, setColumns] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [search, setSearch] = useState('');

  dataRows = useSelector((store) => store.societyReducer.societies);
  backup = useSelector((store) => store.societyReducer.backup);
  useEffect(() => {
    setShowDelete(!clear);
    if (clear) {
      setSelectedRows([]);
    }
  }, [clear]);
  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [societies]);
  const refresh=async()=>{
    setShowDelete(false);
   await setSelectedRows(societies===null?[]:societies);
  
  }
  useEffect(() => {
    initColumns();
    loadSocieties();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const loadSocieties = async () => {
    setPendingData(true);
    await dispatch(findAll());
    setPendingData(false);
  };
  const initColumns = () => {
    let columns = [
      {
        name: language.messages.lblName,
        sortable: false,
        wrap: true,
        width: '90%',
        cell: (row) => (
          <span>
            <div className=" text-uppercase small">{row.name}</div>
            <div className="badge badge-primary">
              {language.messages.society.code}: {row.code}
            </div>
          </span>
        ),
      },
    ];
    setColumns(columns);
  };
  const handleSelectRow = (state) => {
    setSelectedRows(state.selectedRows);
    onSelectSociety(state.selectedRows);
  };
  const onChange = async (event) => {
    setSearch(event.target.value);
    if (es_vacio(event.target.value)) {
      await dispatch(searchSociety('', backup));
    }
  };
  const searchData = async () => {
    setPendingData(true);
    await dispatch(searchSociety(search.trim(), backup));
    setPendingData(false);
  };
  const rowSelectCritera = (row) => {
    return selectedRows.filter((e) => e.id === row.id).length > 0;
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const contextActions = React.useMemo(() => {
    return (
      <Row>
        <Col md={8}>
          <Form.Control
            size="sm"
            type="text"
            name="search"
            value={search}
            placeholder={language.messages.society.filter}
            onChange={onChange}
          />
        </Col>
        <Col>
          <Button info fill type="button" font_small onClick={searchData}>
            <i className="fa fa-search"></i>
          </Button>
        </Col>
      </Row>
    );
  });
  return (
    <Fragment>
      {visible ? (
        <div className="fresh-datatables">
          <LabelTitle
            text={language.messages.society.title_society}
            requerid={false}
          />
          {selectedRows.length > 0 ? (
            <Row>
              <Col className="text-danger small">
                {language.messages.society.codeSelectd}:
              </Col>
            </Row>
          ) : (
            <span></span>
          )}
          {selectedRows.map(function (object, i) {
            return (
              <div className="badge badge-secundary" key={i}>
                {object.code}&nbsp;
              </div>
            );
          })}
          <DataTable
            subHeader={true}
            noHeader={true}
            data={dataRows}
            columns={columns}
            fixedHeader
            fixedHeaderScrollHeight="500px"
            dense
            selectableRows
            onSelectedRowsChange={handleSelectRow}
            highlightOnHover
            pagination
            paginationComponentOptions={{
              noRowsPerPage: true,
              selectAllRowsItem: true,
              rangeSeparatorText: 'de',
            }}
            progressPending={pendingData}
            progressComponent={<LinearIndeterminate />}
            subHeaderComponent={contextActions}
            clearSelectedRows={showDelete}
            selectableRowSelected={rowSelectCritera}
          />
        </div>
      ) : (
        <span></span>
      )}
    </Fragment>
  );
};

export default SocietyList;
