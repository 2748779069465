import view from "../views";

var pagesRoutes = [
  { component: view.Dashboard, path: "/home", exact: true, name: "Home" },
  { component: view.Society, path: "/society", exact: true, name: "society" },
  { component: view.Country, path: "/country", exact: true, name: "country" },
  { component: view.TypeConcept, path: "/type", exact: true, name: "TypeConcept" },
  { component: view.System, path: "/system", exact: true, name: "System" },
  { component: view.UserRole, path: "/user_role", exact: true, name: "UserRole" },
  { component: view.Transaction, path: "/transaction", exact: true, name: "transaction" },
  { component: view.LeafTransaction, path: "/leaf", exact: true, name: "leaf" },
  { component: view.UserSociety, path: "/user_society", exact: true, name: "UserSociety" }

];
export default pagesRoutes;
